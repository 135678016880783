import { useFormik } from "formik";
import { useEffect, useState } from "react";
import {
  Button,
  CardList,
  Dropzone,
  StepNextButton,
} from "../../../components";
import {
  CardVariantType,
  DisplayVariant,
} from "../../../components/CardList/CardList";
import { useLoanApplication } from "../../../contexts";
import { useDeleteMedia, useMedia } from "../../../hooks";
import { MediaPurpose, MediaType } from "../../../hooks/api/types";
import {
  CustomFile,
  DOCUMENTS_UPLOAD_FORM_INITIAL_VALUES,
  DOCUMENTS_UPLOAD_FORM_VALIDATION_SCHEMA,
  DocumentsUploadFormFields,
} from "./service";
import { PdfIcon } from "../../../assets/icons";
import { formatBytes } from "../../../utils";
import { getMediaFromStatementIds } from "../../LoanCalculator/service";

interface VATStatementsUploadProps {
  loanApplicationId?: string;
  closeModal: () => void;
}

const VATStatementsUpload: React.FC<VATStatementsUploadProps> = ({
  closeModal,
}) => {
  const [statements, setStatements] = useState<CustomFile[]>([]);
  const [vatStatementIds, setVatStatementIds] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean[]>([]);
  const [isFailedUploadExist, setIsFailedUploadExist] =
    useState<boolean>(false);

  const {
    state,
    actions: { update },
  } = useLoanApplication();

  const { upload, get } = useMedia();
  const { mutateAsync: deleteMediaById } = useDeleteMedia();
  useEffect(() => {
    if (state.docVatStatements.length && !state.docVatStatementFiles.length) {
      getMediaFromStatementIds(vatStatementIds, get, (retrievedMedia) => {
        setStatements([...retrievedMedia]);
        update({
          docVatStatementFiles: [...retrievedMedia],
        });
      });
    } else if (state.docVatStatementFiles.length)
      setStatements([...state.docVatStatementFiles]);
  }, [vatStatementIds]);

  useEffect(() => {
    let isUploadError = false;
    statements.forEach((statement) => {
      if (statement.error) isUploadError = true;
    });
    if (!isUploadError) setIsFailedUploadExist(false);
  }, [isFailedUploadExist, statements]);

  const { errors, touched, values, handleSubmit, validateForm, setErrors } =
    useFormik<DocumentsUploadFormFields>({
      initialValues: {
        ...DOCUMENTS_UPLOAD_FORM_INITIAL_VALUES,
        vatStatements: [],
      },
      validationSchema: DOCUMENTS_UPLOAD_FORM_VALIDATION_SCHEMA,
      onSubmit: async () => {
        if (!values.vatStatements.length) return;

        setUploading((prev) => {
          let next = [...prev];
          values.vatStatements.forEach((item, index) => {
            next[index] = true;
          });
          return next;
        });
        setLoading(true);

        const statementPromises = values.vatStatements.map((file, index) =>
          upload({
            file,
            type: MediaType.DOCUMENT,
            purpose: MediaPurpose.VAT_STATEMENT,
            loanApplicationId: state.id,
          }).catch((err) => {
            file.error = "Upload failed";
            setIsFailedUploadExist(true);
            setLoading(false);
          })
        );

        const newVatStatementIds = (
          await Promise.all(statementPromises)
        ).filter(Boolean) as string[];
        setStatements((prev) => {
          const next = prev.map((statement: any, index: number) => {
            if (!statement.id) {
              statement.id = newVatStatementIds[index];
            }
            return statement as CustomFile;
          });
          return next;
        });
        setUploading((prev) => {
          let next = [...prev];
          next = next.map(() => false);
          return next;
        });

        update({
          docVatStatementFiles: getFilesToUpdate(),
          docVatStatements: [...state.docVatStatements, ...newVatStatementIds],
        })
          .then(() => {
            setLoading(false);
            if (!isFailedUploadExist) closeModal();
          })
          .catch((err) => {
            console.log(err);
          });
      },
    });

  useEffect(() => {
    if (state.docVatStatements) {
      setVatStatementIds(state.docVatStatements);
    }
  }, [state.docVatStatements]);

  const getFilesToUpdate = () =>
    state.docVatStatements.length
      ? [...state.docVatStatementFiles, ...values.vatStatements]
      : [...statements];

  const handleFileUpload = async (files: File[]) => {
    setErrors({});
    if (!files) return;

    const updatedStatements = [...values.vatStatements];

    files.forEach((file: File) => {
      let allFilesUploaded = values.vatStatements.concat(statements);
      let isFileAlreadyAdded = allFilesUploaded.some(
        (existingFile) => existingFile.name === file.name
      );
      if (!isFileAlreadyAdded) {
        setUploading((prev) => {
          const next = [...prev];
          next.push(false);
          return next;
        });
        updatedStatements.push(new CustomFile(file));
      }
    });
    values.vatStatements = [...updatedStatements];
    state.docVatStatementFiles.length
      ? setStatements((prev) => [...updatedStatements, ...prev])
      : setStatements([...updatedStatements]);
  };

  const handleFileClose = async (fileName: string, id?: string) => {
    if (id) {
      deleteMediaById(id)
        .then((res) => {
          setStatements((prev) =>
            prev.filter((statement: CustomFile) => statement.id !== id)
          );
          update({
            docVatStatementFiles: state.docVatStatementFiles.filter(
              (vatStatementFile) => vatStatementFile.id !== id
            ),
            docVatStatements: state.docVatStatements.filter(
              (docVatStatement) => docVatStatement !== id
            ),
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (fileName) {
      values.vatStatements = values.vatStatements.filter(
        (statement) => fileName !== statement.name
      );
      setStatements((prev) => {
        const next = prev.filter((statement) => fileName !== statement.name);
        return next;
      });
      values.vatStatements = values.vatStatements.filter(
        (statement) => fileName !== statement.name
      );
      validateForm();
    }
  };

  const handleRetryUpload = (fileName: string) => {
    const file = values.vatStatements.find(
      (statement: File) => statement.name === fileName
    );
    if (file) {
      let statementIndex: number;
      statements.forEach((statement, index) => {
        if (statement.name === fileName) statementIndex = index;
      });
      setUploading((prev) => {
        let next = [...prev];
        next = next.map((item, index) => {
          if (statementIndex === index) return true;
          else return false;
        });
        return next;
      });
      upload({
        file,
        type: MediaType.DOCUMENT,
        purpose: MediaPurpose.VAT_STATEMENT,
        loanApplicationId: state.id,
      })
        .then((res: string) => {
          setUploading((prev) => prev.map(() => false));
          values.vatStatements = values.vatStatements.filter(
            (statement) => statement.name !== fileName
          );
          setStatements((prev) => {
            let next = [...prev];
            next = next.map((statement, index) => {
              if (statementIndex === index) {
                statement.error = "";
                statement.id = res;
                return statement;
              } else {
                return statement;
              }
            });
            return next;
          });
          update({
            docVatStatementFiles: [...state.docVatStatementFiles, file],
          });
        })
        .catch((err) => {
          setUploading((prev) => prev.map(() => false));
        });
    }
  };

  return (
    <div className="cx-flex cx-flex-col cx-items-center">
      <div className="cx-text-text-secondary cx-text-center cx-mb-10">
        To enhance your chances of approval, feel free to upload multiple
        statements.
      </div>
      <div className="cx-w-full cx-max-w-[400px]">
        <div className="cx-mb-10">
          <Dropzone
            variant="simple"
            extensions={["PDF"]}
            accept={{ "application/pdf": [".pdf"] }}
            onDrop={handleFileUpload}
          />
        </div>

        <form onSubmit={handleSubmit}>
          {statements.map((statement: CustomFile, index) => {
            return (
              <div className="cx-mb-2" key={index}>
                <CardList
                  variant={
                    statement.error
                      ? CardVariantType.UPLOAD_FAILED
                      : CardVariantType.UPLOADED
                  }
                  title={statement.name}
                  description={
                    statement.error
                      ? statement.error
                      : touched.vatStatements &&
                        values.vatStatements.length &&
                        !!errors.vatStatements?.[index]
                      ? (errors.vatStatements[index] as string)
                      : formatBytes(statement.size, 2)
                  }
                  fullWidth
                  icon={
                    <PdfIcon
                      width="100%"
                      height="100%"
                      className="cx-text-brand-primary-regular"
                    />
                  }
                  inputProps={{ accept: ".pdf,application/pdf" }}
                  error={
                    statement.error
                      ? true
                      : touched.vatStatements &&
                        !!errors.vatStatements?.[index] &&
                        !!values.vatStatements.length
                  }
                  handleFileClose={handleFileClose}
                  handleRetryUpload={handleRetryUpload}
                  uploading={uploading[index]}
                  documentId={statement.id}
                  truncateTitleLength={35}
                  displayVariant={DisplayVariant.NEW}
                />
              </div>
            );
          })}
          <div className="cx-w-full cx-flex cx-flex-col cx-gap-x-4 cx-mt-10">
            <StepNextButton
              label={"Submit"}
              type={"submit"}
              disabled={
                isFailedUploadExist || !values.vatStatements.length
                  ? true
                  : values.vatStatements.length === 0 && statements.length === 0
              }
              loading={loading}
            />
            <Button label={"Cancel"} fullWidth secondary onClick={closeModal} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default VATStatementsUpload;
