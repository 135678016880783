import { FC, useEffect, useState } from "react";
import { Button, Divider, InputField, Slider } from "../../components";
import { useLoanApplication, useLoanCalculator } from "../../contexts";
import { useFormik } from "formik";
import {
  getRevenueBasedLoanValidationSchema,
  LOAN_FIXED_TERM_INITIAL_VALUES,
  RevenueBasedLoanCalculatorFields,
} from "./service";
import {
  LoanApplicationStage,
  LoanDurationType,
  LoanProductType,
} from "../../hooks/api/types";
import { roundTo } from "../../utils";
import { useStepper } from "../LoanApplicationSteps/Stepper";
import { ArrowLeftIcon } from "../../assets/icons";
import { useLoanMetrics } from "../../hooks";
import { useDpConfig } from "../../contexts/dpConfig";
import TermsAndConditions from "./TermsAndConditions";
import { useLoanProductConfigFlags } from "../../hooks/useLoanProductConfigFlags";

type Props = {
  editMode?: boolean;
  handleSubmit?: () => void;
  handleCancel?: () => void;
};

const RevenueBasedLoanCalculator: FC<Props> = ({
  editMode = false,
  handleSubmit: handleSubmitFromParent,
  handleCancel: handleCancelFromParent,
}) => {
  const [isPartnerTermsAccepted, setIsPartnerTermsAccepted] =
    useState<boolean>(false);
  const [submissionError, setSubmissionError] = useState<boolean>(false);
  const {
    state: { revenueBasedLoan, constraints },
    actions: { setRevenueBasedLoan, setRevenueBasedLoanConstraints },
  } = useLoanCalculator();

  const {
    state: { id, sourceId, stage },
    actions: { update },
  } = useLoanApplication();

  const { enableVendorScreen } = useLoanProductConfigFlags(
    LoanProductType.REVENUE_BASED_FINANCING
  );

  const { nextStep } = useStepper();
  const { revenueBasedLoanPaymentPerMonth } = useLoanMetrics();

  const {
    state: {
      name,
      products,
      dpTermsUrl,
      settings: { moveLoanCalculatorAtStart, showRepaymentDisclaimer },
      uiLocalizationData,
    },
  } = useDpConfig();

  const userConstraintsConfig = products.find(
    (product) => product.type === LoanProductType.REVENUE_BASED_FINANCING
  )?.userConstraintsConfig;

  // only show duration slider when duration in constraint is in range format
  const showDurationSliderInput =
    constraints?.revenueBasedLoan?.duration?.isRange ?? true;

  const { values, errors, setFieldValue, handleSubmit } =
    useFormik<RevenueBasedLoanCalculatorFields>({
      enableReinitialize: true,
      initialValues: {
        ...LOAN_FIXED_TERM_INITIAL_VALUES,
        loanAmount:
          revenueBasedLoan?.amount ||
          constraints.revenueBasedLoan?.amount?.initial,
        duration:
          revenueBasedLoan?.duration ||
          constraints.revenueBasedLoan?.duration?.initial,
      },
      validationSchema: getRevenueBasedLoanValidationSchema({
        loanAmount: {
          min: constraints.revenueBasedLoan?.amount?.min,
          max: constraints.revenueBasedLoan?.amount?.max,
          unit: constraints.revenueBasedLoan?.amount?.unit ?? "AED",
        },
        duration: {
          min: constraints.revenueBasedLoan?.duration?.min,
          max: constraints.revenueBasedLoan?.duration?.max,
        },
        validateDuration: showDurationSliderInput,
      }),
      onSubmit: async () => {
        if (!isPartnerTermsAccepted && dpTermsUrl) {
          setSubmissionError(true);
          return;
        }
        // only update loan calculator state on submission when we don't have userConstraintsConfig
        // in case of userConstraintsConfig we already have right values on submission
        if (!userConstraintsConfig) {
          setRevenueBasedLoan({
            amount: values.loanAmount,
            duration: values.duration,
            interestRate: constraints.revenueBasedLoan.interestRate,
            durationType: constraints.revenueBasedLoan.duration
              .unit as LoanDurationType,
            salesStatements: [],
          });
        }

        //if legacy flow is enabled, we go to the next step
        if (moveLoanCalculatorAtStart) {
          await update(
            {
              additionalDPMetadata: {
                userConstraintsConfig: JSON.stringify(
                  constraints.userConstraintsConfig
                ),
              },
              //send update to server only in loc flow mode
            },
            { local: true }
          );
          nextStep();
          return;
        }

        await handleRevenueBasedLoanUpdate(id);
        let nextStage = LoanApplicationStage.OWNER_INFORMATION;
        if (sourceId) {
          nextStage = enableVendorScreen
            ? LoanApplicationStage.VENDOR_INFORMATION
            : LoanApplicationStage.DOCUMENTS_UPLOAD;
        }

        await update({
          stage: nextStage,
        });
      },
    });

  async function handleRevenueBasedLoanUpdate(id: string) {
    await update({
      id,
      loanType: LoanProductType.REVENUE_BASED_FINANCING,
      revenueBasedLoan: {
        amount: values.loanAmount,
        duration: values.duration,
        interestRate: constraints.revenueBasedLoan.interestRate,
        durationType: constraints.revenueBasedLoan.duration
          .unit as LoanDurationType,
        salesStatements: [],
      },
      additionalDPMetadata: {
        userConstraintsConfig: JSON.stringify(
          constraints.userConstraintsConfig
        ),
      },
    });
  }

  const handleInputChange = (name: string, value: string) => {
    const num = Number(value.replaceAll(",", ""));
    if (isNaN(num)) {
      return null;
    }

    setFieldValue(name, num, true);

    const validation: any = {
      loanAmount: constraints.revenueBasedLoan.amount,
      duration: constraints.revenueBasedLoan.duration,
    };

    if (num > validation[name].max || num < validation[name].min) {
      return null;
    }

    return num;
  };

  const updateLoanConstraints = (val: number) => {
    if (!userConstraintsConfig) return;

    const selectedBucket = userConstraintsConfig.buckets.find(
      (bucket) =>
        val >= bucket.range.amount.from && val <= bucket.range.amount.to
    );

    if (!selectedBucket) return;

    const tenure = selectedBucket.constraints.tenure;
    const interestRate = selectedBucket.constraints.interestRate;

    // this state is from loan calculator
    const updatedRevenueBasedLoan = {
      ...revenueBasedLoan,
      amount: val,
      interestRate: interestRate,
    };

    if (typeof tenure === "object") {
      // Handle tenure as a range (object)
      const { from: minDuration, to: maxDuration } = tenure;
      setRevenueBasedLoanConstraints({
        ...constraints.revenueBasedLoan,
        duration: {
          ...constraints.revenueBasedLoan.duration,
          max: maxDuration,
          min: minDuration,
          initial: minDuration,
        },
        interestRate: interestRate,
      });
      setRevenueBasedLoan(updatedRevenueBasedLoan);
    } else {
      // Handle tenure as a single number
      setRevenueBasedLoanConstraints({
        ...constraints.revenueBasedLoan,
        duration: {
          ...constraints.revenueBasedLoan.duration,
          max: tenure,
          min: tenure,
          initial: tenure,
        },
        interestRate: interestRate,
      });

      setRevenueBasedLoan({
        ...updatedRevenueBasedLoan,
        duration: tenure,
      });
    }
  };

  const handleLoanAmountInputChange = (value: string) => {
    const val = handleInputChange("loanAmount", value);
    if (val !== null) {
      if (userConstraintsConfig) {
        updateLoanConstraints(val);
        return;
      }
      setRevenueBasedLoan({
        ...revenueBasedLoan,
        amount: val,
        interestRate: constraints.revenueBasedLoan.interestRate,
      });
    }
  };

  const handleLoanAmountSliderChange = (value: number) => {
    setFieldValue("loanAmount", value);

    if (userConstraintsConfig) {
      updateLoanConstraints(value);
      return;
    }
    setRevenueBasedLoan({
      ...revenueBasedLoan,
      amount: value,
      interestRate: constraints.revenueBasedLoan.interestRate,
    });
  };

  const handleDurationInputChange = (value: string) => {
    const val = handleInputChange("duration", value);
    if (val !== null) {
      setRevenueBasedLoan({
        ...revenueBasedLoan,
        duration: val,
        interestRate: constraints.revenueBasedLoan.interestRate,
      });
    }
  };

  const handleDurationSliderChange = (value: number) => {
    setFieldValue("duration", value);
    setRevenueBasedLoan({
      ...revenueBasedLoan,
      duration: value,
      interestRate: constraints.revenueBasedLoan.interestRate,
    });
  };

  const handleTermsChange = (checked: boolean) => {
    setIsPartnerTermsAccepted(checked);
    if (checked) {
      setSubmissionError(false);
    }
  };

  useEffect(() => {
    let initialDurationValue = values.duration;
    if (userConstraintsConfig) {
      const lastBucketIndex = userConstraintsConfig.buckets.length - 1;
      const lastBucketConstraints =
        userConstraintsConfig.buckets[lastBucketIndex]?.constraints;
      const tenure = lastBucketConstraints?.tenure;

      initialDurationValue =
        typeof tenure === "object" ? tenure.from : values.duration;
    }

    setRevenueBasedLoan({
      amount: constraints.revenueBasedLoan.amount?.max,
      duration: initialDurationValue,
      interestRate: constraints.revenueBasedLoan.interestRate,
      durationType: constraints.revenueBasedLoan.duration
        .unit as LoanDurationType,
      salesStatements: [],
    });
  }, []);

  return (
    <div className="cx-w-full cx-flex cx-flex-col cx-p-10 cx-gap-10 cx-items-center cx-rounded-modal cx-border-0.5 cx-border-[#DDD] cx-shadow-modal cx-background-calculator-gradient">
      <div className="cx-flex cx-flex-col cx-items-center cx-w-full">
        <div className="cx-font-medium">Your estimated monthly installment</div>
        <div className="cx-text-3xl cx-font-bold">
          {constraints.revenueBasedLoan?.amount?.unit}{" "}
          {roundTo(revenueBasedLoanPaymentPerMonth, 2).toLocaleString()}
        </div>
        {showDurationSliderInput ? (
          <div className="cx-text-sm cx-text-text-secondary">
            Pay as low as{" "}
            <span className="cx-font-medium">
              {roundTo((revenueBasedLoan?.interestRate / 12) * 100, 2)}
            </span>
            % monthly interest*.
          </div>
        ) : (
          <div className="cx-text-sm cx-text-text-secondary">
            Repay your loan in{" "}
            <span className="cx-font-medium">{revenueBasedLoan.duration}</span>{" "}
            {constraints.revenueBasedLoan.duration.unit} at{" "}
            <span className="cx-font-medium">
              {roundTo((revenueBasedLoan?.interestRate / 12) * 100, 2)}
            </span>
            % per month
          </div>
        )}
      </div>
      <Divider />
      <div className="cx-flex cx-flex-row cx-w-full cx-gap-6">
        <div className="cx-flex cx-flex-col cx-gap-4 cx-w-full">
          <div className="cx-font-semibold">Loan amount</div>
          <div>
            <InputField
              inputProps={{
                name: "loanAmount",
                value: values.loanAmount?.toLocaleString(),
                onChange: (e) => handleLoanAmountInputChange(e.target.value),
              }}
              inputClassName="cx-text-right !cx-p-2 cx-font-bold"
              prepend={constraints.revenueBasedLoan?.amount?.unit}
              error={errors.loanAmount}
              hideErrorMessage
            />
          </div>
          <div className="">
            <Slider
              initial={values.loanAmount}
              min={constraints.revenueBasedLoan?.amount?.min}
              max={constraints.revenueBasedLoan?.amount?.max}
              unit={constraints.revenueBasedLoan?.amount?.unit}
              value={values.loanAmount}
              label=""
              onChange={(value) => handleLoanAmountSliderChange(value)}
              renderThumb={false}
              interval={1000}
            />
          </div>
          {errors.loanAmount && (
            <div className="cx-text-xs cx-text-text-error">
              {errors.loanAmount}
            </div>
          )}
        </div>
        {showDurationSliderInput && (
          <div className="cx-flex cx-flex-col cx-gap-4 cx-w-full">
            <div className="cx-font-semibold">Loan duration</div>
            <div>
              <InputField
                inputProps={{
                  name: "duration",
                  value: values.duration,
                  onChange: (e) => handleDurationInputChange(e.target.value),
                }}
                inputClassName="cx-text-right !cx-p-2 cx-font-bold"
                prepend="Months"
              />
            </div>
            <div className="">
              <Slider
                initial={values.duration}
                min={constraints.revenueBasedLoan?.duration?.min}
                max={constraints.revenueBasedLoan?.duration?.max}
                unit={constraints.revenueBasedLoan?.duration?.unit}
                value={values.duration}
                label=""
                onChange={(value) => handleDurationSliderChange(value)}
                renderThumb={false}
              />
            </div>
            {errors.duration && (
              <div className="cx-text-xs cx-text-text-error">
                {errors.duration}
              </div>
            )}
          </div>
        )}
      </div>
      <Divider />
      {/* DO NOT show terms and condition on calculator when opened on review screen */}
      {stage !== LoanApplicationStage.REVIEW && (
        <div className="cx-w-full">
          <TermsAndConditions
            onTermsChange={handleTermsChange}
            loanType={LoanProductType.REVENUE_BASED_FINANCING}
          />
          {submissionError && (
            <div className="cx-w-full cx-bg-error-lighter cx-rounded-md cx-mt-4 cx-p-4">
              <div className="cx-text-sm cx-text-text-error">
                Please accept the Terms and Conditions to begin the application
              </div>
            </div>
          )}
        </div>
      )}
      <div className="cx-w-[400px]">
        {editMode ? (
          <div className="cx-w-full cx-flex cx-flex-row cx-gap-x-4">
            <div className="cx-w-1/4">
              <Button
                label={<ArrowLeftIcon />}
                type="button"
                onClick={handleCancelFromParent}
                fullWidth
                outlined
              />
            </div>
            <div className="cx-w-3/4">
              <Button
                type="button"
                label="Confirm"
                fullWidth
                onClick={handleSubmitFromParent}
                disabled={Object.keys(errors).length > 0}
              />
            </div>
          </div>
        ) : (
          <Button
            label="Continue"
            arrow="right"
            fullWidth
            onClick={handleSubmit}
            disabled={Object.keys(errors).length > 0}
          />
        )}
      </div>
      <div className="cx-block">
        {showRepaymentDisclaimer && (
          <p className="cx-font-normal cx-text-sm cx-text-text-secondary">
            {`Your repayments will be automatically processed through ${name} sales`}
          </p>
        )}
        {uiLocalizationData?.loanCalculatorScreen?.disclaimer && (
          <p className="cx-font-normal cx-text-sm cx-text-text-secondary cx-text-center cx-mt-1">
            {uiLocalizationData?.loanCalculatorScreen?.disclaimer}
          </p>
        )}
      </div>
    </div>
  );
};

export default RevenueBasedLoanCalculator;
