import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useLoanApplication, useLoanCalculator } from "../../contexts";
import { Button, CardList, Dropzone, StepNextButton } from "../../components";
import {
  getMediaFromStatementIds,
  LOAN_UPLOAD_SALES_STATEMENT_VALIDATION_SCHEMA,
  LOAN_UPLOAD_STATEMENTS_INITIAL_VALUES,
  SALES_STATEMENT_CONFIG,
  UploadStatementsFields,
} from "./service";
import { PdfIcon } from "../../assets/icons";
import {
  CardVariantType,
  DisplayVariant,
} from "../../components/CardList/CardList";
import { formatBytes } from "../../utils";
import {
  LoanProductType,
  MediaPurpose,
  MediaType,
} from "../../hooks/api/types";
import { useDeleteMedia, useMedia } from "../../hooks";
import { useDpConfig } from "../../contexts/dpConfig";
import { CustomFile } from "../LoanApplicationSteps/FinancialInformation/service";

const UploadSalesStatement = ({ closeModal }: { closeModal: () => void }) => {
  const [salesStatements, setSalesStatements] = useState<CustomFile[]>([]);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    state: { revenueBasedLoan: revenueBasedLoanCalculatorState, constraints },
  } = useLoanCalculator();
  const {
    state: { revenueBasedLoan, id },
    actions: { update },
  } = useLoanApplication();

  const { upload, get } = useMedia();
  const { mutateAsync: deleteMediaById } = useDeleteMedia();
  const {
    state: {
      settings: { moveLoanCalculatorAtStart },
    },
  } = useDpConfig();

  useEffect(() => {
    if (revenueBasedLoan.salesStatements.length) {
      const salesStatementIds = revenueBasedLoan.salesStatements.map(
        (salesStatement) => salesStatement.documentId
      );
      getMediaFromStatementIds(salesStatementIds, get, (retrievedMedia) => {
        setSalesStatements([...retrievedMedia]);
        update({
          docBankStatementFiles: [...retrievedMedia],
        });
      });
    }
  }, []);

  async function handleRevenueBasedLoanUpdate(
    id: string,
    salesStatementFiles: File[]
  ) {
    const salesStatementPromises = salesStatementFiles.map((file) =>
      upload({
        file,
        type: MediaType.DOCUMENT,
        purpose: MediaPurpose.SALES_STATEMENT,
        loanApplicationId: id,
      })
    );
    const salesStatementIds = await Promise.all(salesStatementPromises);
    const salesStatements = salesStatementIds.map((_statement, index) => ({
      documentId: salesStatementIds[index],
    }));
    await update({
      id,
      loanType: LoanProductType.REVENUE_BASED_FINANCING,
      revenueBasedLoan: {
        ...revenueBasedLoanCalculatorState,
        salesStatements: salesStatements,
      },
      additionalDPMetadata: {
        userConstraintsConfig: JSON.stringify(
          constraints.userConstraintsConfig
        ),
      },
    });
  }

  const { errors, touched, values, handleSubmit, setFieldValue, validateForm } =
    useFormik<UploadStatementsFields>({
      initialValues: {
        ...LOAN_UPLOAD_STATEMENTS_INITIAL_VALUES,
        salesStatements: revenueBasedLoan.salesStatements.map(
          (salesStatement) => new File([], salesStatement.documentId + ".pdf")
        ),
      },
      validationSchema: LOAN_UPLOAD_SALES_STATEMENT_VALIDATION_SCHEMA,
      onSubmit: async (values) => {
        try {
          setLoading(true);
          await handleRevenueBasedLoanUpdate(id, values.salesStatements);
          setLoading(false);

          closeModal();
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handleUpload = (files: File[]) => {
    if (!files) return;

    const updatedStatements = [...values.salesStatements];

    files.forEach((file: File) => {
      let isFileAlreadyAdded = updatedStatements.some(
        (existingFile) => existingFile.name === file.name
      );
      if (!isFileAlreadyAdded) {
        updatedStatements.push(file);
      }
    });
    setUploading(true);
    setFieldValue("salesStatements", [...updatedStatements]);
    setSalesStatements([...updatedStatements]);
    setUploading(false);
  };

  const handleFileClose = async (fileName: string, id?: string) => {
    if (id) {
      await deleteMediaById(id);
      await update(
        {
          revenueBasedLoan: {
            ...revenueBasedLoan,
            salesStatements: revenueBasedLoan.salesStatements.filter(
              (salesStatement) => salesStatement.documentId !== id
            ),
          },
        },
        {
          local: moveLoanCalculatorAtStart,
        }
      );
    }
    setSalesStatements((prev) => {
      return prev.filter((salesStatement) => fileName !== salesStatement.name);
    });
    values.salesStatements = values.salesStatements.filter(
      (saleStatement) => fileName !== saleStatement.name
    );
    await validateForm();
  };

  return (
    <div className="cx-flex cx-flex-col cx-items-center cx-justify-center">
      <div className="cx-text-text-secondary cx-text-center cx-mb-10">
        Please upload the sales statement for the last 12 months from your
        e-commerce platform
      </div>
      <div className="cx-w-full cx-max-w-[400px]">
        <div className="cx-mt-4">
          <Dropzone
            variant={uploading ? "uploading" : "simple"}
            extensions={["PDF only", "Maximum file size is 5 MB"]}
            options={{
              accept: SALES_STATEMENT_CONFIG.accept,
              maxFiles: SALES_STATEMENT_CONFIG.maxFiles,
              maxSize: SALES_STATEMENT_CONFIG.maxSize, // 100MB to byte
            }}
            onDrop={async (files) => {
              if (!files) return;
              handleUpload(files);
            }}
          />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="cx-w-full cx-bg-background-default cx-my-10 cx-rounded-lg">
            {salesStatements.map(
              (salesStatement: CustomFile, index: number) => (
                <div className="cx-mb-2">
                  <CardList
                    variant={CardVariantType.UPLOADED}
                    title={salesStatement.name}
                    description={
                      touched.salesStatements &&
                      values.salesStatements.length &&
                      !!errors.salesStatements?.[index]
                        ? (errors.salesStatements[index] as string)
                        : formatBytes(salesStatement.size, 2)
                    }
                    fullWidth
                    icon={
                      <PdfIcon
                        width="100%"
                        height="100%"
                        className="cx-text-brand-primary-regular"
                      />
                    }
                    inputProps={{ accept: ".pdf,application/pdf" }}
                    error={
                      touched.salesStatements &&
                      !!errors.salesStatements?.[index] &&
                      !!values.salesStatements.length
                    }
                    handleFileClose={handleFileClose}
                    truncateTitleLength={35}
                    displayVariant={DisplayVariant.NEW}
                    documentId={salesStatement.id}
                  />
                </div>
              )
            )}
          </div>

          <div className="cx-w-full cx-flex cx-flex-col cx-gap-x-4 cx-mt-10">
            <StepNextButton
              label={"Submit"}
              type={"submit"}
              disabled={uploading || loading || !values.salesStatements?.length}
              loading={loading}
              onClick={handleSubmit}
            />
            <Button label={"Cancel"} fullWidth secondary onClick={closeModal} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default UploadSalesStatement;
