import { useEffect, useMemo } from "react";
import clsx from "clsx";
import { usePostHog } from "posthog-js/react";
import { useLoanApplication } from "../../contexts";
import { ButtonLoaderIcon } from "../../assets/icons";
import { getStepperStage, useStepper } from "../LoanApplicationSteps/Stepper";
import { useDpConfig } from "../../contexts/dpConfig";
import { datadogRum } from "@datadog/browser-rum";

const LoanApplicationFlowForm = () => {
  const stepper = useStepper();
  const { state: loanApplicationContextState } = useLoanApplication();

  const { loading, id, stage, applicant, distributionPartnerId, businessName } =
    loanApplicationContextState;

  const {
    state: { settings, loading: dpConfigLoading },
  } = useDpConfig();
  const posthog = usePostHog();

  const readyForDisplay = useMemo(
    () => !loading && !dpConfigLoading && !stepper.loading,
    [loading, dpConfigLoading, stepper.loading]
  );

  useEffect(() => {
    if (readyForDisplay) {
      if (!id) {
        stepper.reset();
      } else {
        const [stepName, subStepIndex] = getStepperStage(
          loanApplicationContextState
        );
        const stepIndex =
          stepper.steps.findIndex((step) => step.name === stepName) || 1;

        stepper.gotoStep([stepIndex, subStepIndex]);
      }
    }
  }, [id, stage, readyForDisplay, stepper.steps]);

  useEffect(() => {
    if (!settings.illustrationScreens) {
      stepper.setSkipIllustrationSteps(true);
    }
    stepper.setLoading(false);
  }, [settings.illustrationScreens]);

  const activeStep =
    stepper.steps[stepper.currentStep[0]].subSteps[stepper.currentStep[1]];

  useEffect(() => {
    if (!loading) {
      posthog.capture(`Moved to Step: ${activeStep.name}`, {
        stage: stage,
        loanApplicationId: id,
        step: activeStep.name,
        // add the current step and total steps to the event for percentage completion checks
        currentStep: stepper.currentStep[0],
        totalSteps: stepper.steps.length,
        $set: {
          ...(applicant?.firstName &&
            applicant?.lastName && {
              name: `${applicant.firstName} ${applicant.lastName}`,
            }),
          ...(applicant?.email && { email: applicant.email }),
          ...(distributionPartnerId && {
            distributionPartnerId: distributionPartnerId,
          }),
          ...(businessName && { companyName: businessName }),
        },
      });
      datadogRum.addAction(`Moved to Step: ${activeStep.name}`, {
        stage: stage,
        loanApplicationId: id,
        step: activeStep.name,
        // add the current step and total steps to the event for percentage completion checks
        currentStep: stepper.currentStep[0],
        totalSteps: stepper.steps.length,
        ...(applicant?.firstName &&
          applicant?.lastName && {
            name: `${applicant.firstName} ${applicant.lastName}`,
          }),
        ...(applicant?.email && { email: applicant.email }),
        ...(distributionPartnerId && {
          distributionPartnerId: distributionPartnerId,
        }),
        ...(businessName && { companyName: businessName }),
      });
    }
  }, [activeStep.name, loading]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [stepper.currentStep]);

  return (
    <div
      className={clsx(
        "cx-grow cx-flex cx-flex-col cx-items-center cx-justify-center cx-w-full cx-p-8",
        {
          "cx-background-radial-gradient":
            !activeStep.metadata?.disableBackroundGradient,
        }
      )}
    >
      {loading || stepper.loading ? (
        <div className="cx-animate-spin cx-text-brand-primary-regular cx-w-10 cx-h-10">
          <ButtonLoaderIcon width="100%" height="100%" />
        </div>
      ) : (
        activeStep.component
      )}
    </div>
  );
};

export default LoanApplicationFlowForm;
