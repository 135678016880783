import * as yup from "yup";
import { validateFileSize } from "../../utils";
import {
  LoanProductType,
  MediaPurpose,
  MediaType,
} from "../../hooks/api/types";
import { LoanCalculatorState } from "../../contexts/LoanCalculator/types";
import { UploadMediaArgs } from "../../hooks/useMedia";
import { LoanApplicationContextValues } from "../../contexts";
import { CustomFile } from "../LoanApplicationSteps/FinancialInformation/service";

export type FixedTermCalculatorFields = {
  loanAmount: number;
  duration: number;
};

export type BuyNowPayLaterCalculatorFields = {
  loanAmount: number;
  duration: number;
};

export type RevenueBasedLoanCalculatorFields = {
  loanAmount: number;
  duration: number;
};

export type PosRevenueBasedLoanCalculatorFields = {
  loanAmount: number;
  duration: number;
};

export type InvoiceDiscountingCalculatorFields = {
  loanAmount: number;
  duration: number;
  discountRate: number;
};

export type PayableFinancingCalculatorFields = {
  loanAmount: number;
  duration: number;
};

export type LineOfCreditCalculatorFields = {
  loanAmount: number;
};

export type UploadInvoiceFields = {
  invoices: File[];
  invoiceNo?: string;
  invoiceAmount?: number;
  invoiceIssuedDate?: Date;
  invoiceDueDate?: Date;
  vendorName: string;
};

export type Invoice = {
  invoiceNumber?: string;
  invoiceAmount?: string;
  requiredAmount?: string;
  issuedDate?: Date;
  dueDate?: Date;
  selected: boolean;
};

export type DrawdownFields = {
  invoiceFiles: File[];
  vendorName: string;
  invoices: Invoice[];
  currency: string;
  totalAmount: number;
  numberOfInvoices: number;
};

export type UploadStatementsFields = {
  salesStatements: File[];
};

export type UploadPosStatementsFields = {
  posSalesStatements: File[];
};

export type UploadSalesInvoiceFields = {
  salesInvoices: File[];
};

type FixedTermLoanValidationSchemaArgs = {
  loanAmount: {
    min: number;
    max: number;
    unit: string;
  };
  duration: {
    min: number;
    max: number;
  };
};

type BuyNowPayLaterLoanValidationSchemaArgs = {
  loanAmount: {
    min: number;
    max: number;
    unit: string;
  };
  duration: {
    min: number;
    max: number;
  };
};

type RevenueBasedLoanValidationSchemaArgs = {
  loanAmount: {
    min: number;
    max: number;
    unit: string;
  };
  duration: {
    min: number;
    max: number;
  };
  validateDuration: boolean;
};

type PosRevenueBasedLoanValidationSchemaArgs = {
  loanAmount: {
    min: number;
    max: number;
    unit: string;
  };
  duration: {
    min: number;
    max: number;
  };
};

type InvoiceDiscountingValidationSchemaArgs =
  FixedTermLoanValidationSchemaArgs & {
    discount: {
      min: number;
      max: number;
    };
  };

type PayableFinancingValidationSchemaArgs = {
  loanAmount: {
    min: number;
    max: number;
    unit: string;
  };
  duration: {
    min: number;
    max: number;
  };
};

export const LINE_OF_CREDIT_INITIAL_VALUES: LineOfCreditCalculatorFields = {
  loanAmount: 100_000,
};

export const LOAN_FIXED_TERM_INITIAL_VALUES: FixedTermCalculatorFields = {
  loanAmount: 50_000,
  duration: 6,
};

export const LOAN_INVOICE_DISCOUNTING_INITIAL_VALUES: InvoiceDiscountingCalculatorFields =
  {
    loanAmount: 50_000,
    duration: 6,
    discountRate: 50,
  };

export const LOAN_UPLOAD_INVOICE_INITIAL_VALUES: UploadInvoiceFields = {
  invoices: [],
  vendorName: "",
  invoiceNo: "",
  invoiceAmount: 0,
  invoiceIssuedDate: undefined,
  invoiceDueDate: undefined,
};

export const LOAN_UPLOAD_STATEMENTS_INITIAL_VALUES: UploadStatementsFields = {
  salesStatements: [],
};

export const LOAN_UPLOAD_POS_STATEMENTS_INITIAL_VALUES: UploadPosStatementsFields =
  {
    posSalesStatements: [],
  };

export const LOAN_UPLOAD_SALES_INVOICES_INITIAL_VALUES: UploadSalesInvoiceFields =
  {
    salesInvoices: [],
  };

export const getFixedTermLoanValidationSchema = ({
  loanAmount,
  duration,
}: FixedTermLoanValidationSchemaArgs) => {
  return yup.object().shape({
    loanAmount: yup
      .number()
      .min(
        loanAmount.min,
        `Amount cannot be less than ${
          loanAmount.unit
        } ${loanAmount.min.toLocaleString()}`
      )
      .max(
        loanAmount.max,
        `Amount cannot exceed than ${
          loanAmount.unit
        } ${loanAmount.max.toLocaleString()}`
      )
      .required("Loan amount is required"),
    duration: yup.number().min(duration.min).max(duration.max),
  });
};

export const getBuyNowPayLaterLoanValidationSchema = ({
  loanAmount,
  duration,
}: BuyNowPayLaterLoanValidationSchemaArgs) => {
  return yup.object().shape({
    loanAmount: yup
      .number()
      .min(
        loanAmount.min,
        `Amount cannot be less than ${
          loanAmount.unit
        } ${loanAmount.min.toLocaleString()}`
      )
      .max(
        loanAmount.max,
        `Amount cannot exceed than ${
          loanAmount.unit
        } ${loanAmount.max.toLocaleString()}`
      )
      .required("Loan amount is required"),
    duration: yup.number().min(duration.min).max(duration.max),
  });
};

export const getRevenueBasedLoanValidationSchema = ({
  loanAmount,
  duration,
  validateDuration = true,
}: RevenueBasedLoanValidationSchemaArgs) => {
  return yup.object().shape({
    loanAmount: yup
      .number()
      .min(
        loanAmount.min,
        `Amount cannot be less than ${
          loanAmount.unit
        } ${loanAmount.min?.toLocaleString()}`
      )
      .max(
        loanAmount.max,
        `Amount cannot exceed than ${
          loanAmount.unit
        } ${loanAmount.max?.toLocaleString()}`
      )
      .required("Loan amount is required"),
    ...(validateDuration && {
      duration: yup.number().min(duration.min).max(duration.max),
    }),
  });
};

export const getLineOfCreditValidationSchema = ({
  loanAmount,
}: {
  loanAmount: {
    min: number;
    max: number;
    unit: string;
  };
}) => {
  return yup.object().shape({
    loanAmount: yup
      .number()
      .min(
        loanAmount.min,
        `Amount cannot be less than ${
          loanAmount.unit
        } ${loanAmount.min?.toLocaleString()}`
      )
      .max(
        loanAmount.max,
        `Amount cannot exceed than ${
          loanAmount.unit
        } ${loanAmount.max?.toLocaleString()}`
      )
      .required("Loan amount is required"),
  });
};

// Aliases for backward compatibility
export const getLocInvoiceDiscountingValidationSchema =
  getLineOfCreditValidationSchema;
export const getLocPayableFinancingValidationSchema =
  getLineOfCreditValidationSchema;

export const getPosRevenueBasedLoanValidationSchema = ({
  loanAmount,
  duration,
}: PosRevenueBasedLoanValidationSchemaArgs) => {
  return yup.object().shape({
    loanAmount: yup
      .number()
      .min(
        loanAmount.min,
        `Amount cannot be less than ${
          loanAmount.unit
        } ${loanAmount.min?.toLocaleString()}`
      )
      .max(
        loanAmount.max,
        `Amount cannot exceed than ${
          loanAmount.unit
        } ${loanAmount.max?.toLocaleString()}`
      )
      .required("Loan amount is required"),
    duration: yup.number().min(duration.min).max(duration.max),
  });
};

export const getInvoiceDiscountingValidationSchema = ({
  loanAmount,
  duration,
  discount,
}: InvoiceDiscountingValidationSchemaArgs) => {
  return yup.object().shape({
    loanAmount: yup
      .number()
      .min(
        loanAmount.min,
        `Loan amount must be greater than ${
          loanAmount.unit
        } ${loanAmount.min.toLocaleString()}`
      )
      .max(
        loanAmount.max,
        `Loan amount cannot be higher than ${
          loanAmount.unit
        } ${loanAmount.max.toLocaleString()}`
      )
      .required("Loan amount is required"),
    duration: yup.number().min(duration.min).max(duration.max),
    //inclusive min and max
    discountRate: yup
      .number()
      .min(discount.min, (x) => `Borrow % should be greater than ${x.min}`)
      .max(discount.max, (x) => `Borrow % should be less than ${x.max}`),
  });
};

export const getPayableInvoicingValidationSchema = ({
  loanAmount,
  duration,
}: PayableFinancingValidationSchemaArgs) => {
  return yup.object().shape({
    loanAmount: yup
      .number()
      .min(
        loanAmount.min,
        `Loan amount must be greater than ${
          loanAmount.unit
        } ${loanAmount.min.toLocaleString()}`
      )
      .max(
        loanAmount.max,
        `Loan amount cannot be higher than ${
          loanAmount.unit
        } ${loanAmount.max.toLocaleString()}`
      )
      .required("Loan amount is required"),
    duration: yup.number().min(duration.min).max(duration.max),
  });
};

export const SALES_STATEMENT_CONFIG = {
  accept: { "application/pdf": [".pdf"] },
  maxFiles: 10,
  minFiles: 1,
  maxSize: 100 * 1024 * 1024,
};

export const POS_SALES_STATEMENT_CONFIG = {
  accept: { "application/pdf": [".pdf"] },
  maxFiles: 10,
  minFiles: 1,
  maxSize: 100 * 1024 * 1024,
};

export const SALES_INVOICE_CONFIG = {
  accept: { "application/pdf": [".pdf"] },
  maxFiles: 1,
  minFiles: 1,
  maxSize: 100 * 1024 * 1024,
};

export const LOAN_UPLOAD_SALES_STATEMENT_VALIDATION_SCHEMA = yup
  .object()
  .shape({
    salesStatements: yup
      .array()
      .of(
        yup
          .mixed()
          .test("fileType", "Please provide a valid PDF file", (value: any) => {
            return value?.type === "application/pdf";
          })
          .test("fileSize", "File is larger than 5 MB", validateFileSize(5))
      )
      .min(SALES_STATEMENT_CONFIG.minFiles)
      .max(SALES_STATEMENT_CONFIG.maxFiles)
      .required("Statement is required"),
  });

export const LOAN_UPLOAD_POS_SALES_STATEMENT_VALIDATION_SCHEMA = yup
  .object()
  .shape({
    posSalesStatements: yup
      .array()
      .of(
        yup
          .mixed()
          .test("fileType", "Please provide a valid PDF file", (value: any) => {
            return value?.type === "application/pdf";
          })
          .test("fileSize", "File is larger than 5 MB", validateFileSize(5))
      )
      .min(POS_SALES_STATEMENT_CONFIG.minFiles)
      .max(POS_SALES_STATEMENT_CONFIG.maxFiles)
      .required("Statement is required"),
  });

export const LOAN_UPLOAD_SALES_INVOICE_VALIDATION_SCHEMA = yup.object().shape({
  salesInvoices: yup
    .array()
    .of(
      yup
        .mixed()
        .test("fileType", "Please provide a valid PDF file", (value: any) => {
          return value?.type === "application/pdf";
        })
        .test("fileSize", "File is larger than 5 MB", validateFileSize(5))
    )
    .min(SALES_INVOICE_CONFIG.minFiles)
    .max(SALES_INVOICE_CONFIG.maxFiles)
    .required("Invoice is required"),
});

export const LOAN_UPLOAD_INVOICE_VALIDATION_SCHEMA = yup.object().shape({
  invoices: yup
    .array()
    .min(1)
    .max(1)
    .of(yup.mixed().required())
    .required("Please upload an invoice to continue"),
  vendorName: yup
    .string()
    .required(
      "Please enter the name of the company which the invoice attached."
    ),
  invoiceNo: yup.string().required("Please enter invoice number."),
  invoiceAmount: yup.number().required("Please enter the invoice amount."),
  invoiceIssuedDate: yup
    .date()
    .required("Please enter the invoice issue date.")
    .max(new Date(), "Invoice issue date cannot be in the future.")
    .test("is-valid-date", "Please enter a valid date.", (value) => {
      const day = String(value.getDate()).padStart(2, "0");
      const month = String(value.getMonth() + 1).padStart(2, "0");
      const year = value.getFullYear();
      const dateStr = `${day}/${month}/${year}`;
      return /^\d{2}\/\d{2}\/\d{4}$/.test(dateStr || "");
    }),
  invoiceDueDate: yup
    .date()
    .required("Please enter the invoice due date.")
    .min(
      yup.ref("invoiceIssuedDate"),
      "Invoice due date must be later than the invoice issue date."
    )
    .test("is-valid-date", "Please enter a valid date.", (value) => {
      const day = String(value.getDate()).padStart(2, "0");
      const month = String(value.getMonth() + 1).padStart(2, "0");
      const year = value.getFullYear();
      const dateStr = `${day}/${month}/${year}`;
      return /^\d{2}\/\d{2}\/\d{4}$/.test(dateStr || "");
    }),
});

export const LOAN_UPDATE_INVOICE_DETAILS_VALIDATION_SCHEMA = yup
  .object()
  .shape({
    invoiceNo: yup.string().required("Please enter invoice number."),
    invoiceIssuedDate: yup
      .date()
      .required("Please enter the invoice issue date.")
      .max(new Date(), "Invoice issue date cannot be in the future.")
      .test("is-valid-date", "Please enter a valid date.", (value) => {
        const day = String(value.getDate()).padStart(2, "0");
        const month = String(value.getMonth() + 1).padStart(2, "0");
        const year = value.getFullYear();
        const dateStr = `${day}/${month}/${year}`;
        return /^\d{2}\/\d{2}\/\d{4}$/.test(dateStr || "");
      }),
    invoiceDueDate: yup
      .date()
      .required("Please enter the invoice due date.")
      .min(
        yup.ref("invoiceIssuedDate"),
        "Invoice due date must be later than the invoice issue date."
      )
      .test("is-valid-date", "Please enter a valid date.", (value) => {
        const day = String(value.getDate()).padStart(2, "0");
        const month = String(value.getMonth() + 1).padStart(2, "0");
        const year = value.getFullYear();
        const dateStr = `${day}/${month}/${year}`;
        return /^\d{2}\/\d{2}\/\d{4}$/.test(dateStr || "");
      }),
  });

export const getDrawdownValidationSchema = (availableLimit: number) =>
  yup.object({
    vendorName: yup.string().required("Vendor Name is required"),
    currency: yup.string().required("Currency is required"),
    invoiceFiles: yup
      .array()
      .min(1, "At least one invoice file is required")
      .max(10, "At most 10 files are allowed")
      .required("Invoice files are required"),
    invoices: yup
      .array()
      .min(1, "At least one invoice is required")
      .max(10, "At most 10 invoices are allowed")
      .of(
        yup.object().shape({
          invoiceNumber: yup
            .string()
            .required("Invoice number is required")
            .test(
              "unique-invoice-number",
              "Invoice number already exists",
              function (value, context) {
                if (!value) return true;

                const invoices = this.options.context?.invoices;
                if (!invoices || invoices.length <= 1) return true;

                const currentIndex = context.path.split("[")[1].split("]")[0];
                const isUnique = !invoices.some((invoice: any, index: any) => {
                  return (
                    index !== parseInt(currentIndex) &&
                    invoice.invoiceNumber === value
                  );
                });

                return isUnique;
              }
            ),
          invoiceAmount: yup
            .string()
            .matches(
              /^\d+(\.\d{1,2})?$/,
              "Invoice amount must be a valid number"
            )
            .required("Invoice amount is required"),
          requiredAmount: yup
            .string()
            .matches(
              /^\d+(\.\d{1,2})?$/,
              "Required amount must be a valid number"
            )
            .required("Required amount is required")
            .test(
              "requiredAmount-lte",
              "Required amount exceeds available limit",
              (value) => {
                const requiredAmount = parseFloat(value);
                return requiredAmount <= availableLimit;
              }
            )
            .test(
              "requiredAmount-lte-invoice",
              "Required amount cannot exceed invoice amount",
              function (value) {
                const requiredAmount = parseFloat(value);
                const invoiceAmount = parseFloat(this.parent.invoiceAmount);
                return requiredAmount <= invoiceAmount;
              }
            ),
          issuedDate: yup
            .date()
            .required("Invoice issue date is required.")
            .max(new Date(), "Invoice issue date cannot be in the future.")
            .test("is-valid-date", "Please enter a valid date.", (value) => {
              const day = String(value.getDate()).padStart(2, "0");
              const month = String(value.getMonth() + 1).padStart(2, "0");
              const year = value.getFullYear();
              const dateStr = `${day}/${month}/${year}`;
              return /^\d{2}\/\d{2}\/\d{4}$/.test(dateStr || "");
            }),
          dueDate: yup
            .date()
            .required("Invoice due date is required.")

            .min(
              yup.ref("issuedDate"),
              "Invoice due date must be later than the invoice issue date."
            )
            .test("is-valid-date", "Please enter a valid date.", (value) => {
              const day = String(value.getDate()).padStart(2, "0");
              const month = String(value.getMonth() + 1).padStart(2, "0");
              const year = value.getFullYear();
              const dateStr = `${day}/${month}/${year}`;
              return /^\d{2}\/\d{2}\/\d{4}$/.test(dateStr || "");
            })
            .test(
              "is-future-date",
              "Invoice due date cannot be in the past.",
              (value) => {
                return value ? value >= new Date() : true;
              }
            ),
        })
      ),
    totalAmount: yup
      .number()
      .test(
        "total-amount-limit",
        `Total drawdown amount cannot exceed available limit of ${availableLimit}`,
        function (_value) {
          const invoices = this.options.context?.invoices;
          if (!invoices || invoices.length <= 1) return true;
          const total = invoices.reduce(
            (sum: number, invoice: any) =>
              sum + Number(invoice.requiredAmount || 0),
            0
          );
          return total <= availableLimit;
        }
      ),

    numberOfInvoices: yup
      .number()
      .test("complete-invoices-count", function (_value) {
        const invoices = this.options.context?.invoices;
        if (!invoices || !Array.isArray(invoices)) {
          return this.createError({ message: "No invoices provided" });
        }
        const completeCount = invoices.filter((invoice: any) =>
          Object.values(invoice).every(
            (v) => v !== "" && v !== null && v !== undefined
          )
        ).length;

        if (completeCount < 1) {
          return this.createError({
            message: "At least one complete invoice is required",
          });
        }
        if (completeCount > 10) {
          return this.createError({
            message: "Maximum 10 invoices are allowed",
          });
        }
        return true;
      }),
  });

export async function handleInvoiceDiscountingLoanUpdate(
  loanApplicationId: string,
  invoiceFiles: File[],
  calculatorState: LoanCalculatorState,
  update: (
    payload: Partial<LoanApplicationContextValues["state"]>,
    options?: { local?: boolean }
  ) => Promise<any>,
  upload: (payload: UploadMediaArgs) => Promise<string>,
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>
) {
  setLoading && setLoading(true);
  const invoicePromises = invoiceFiles.map((file) =>
    upload({
      file,
      type: MediaType.DOCUMENT,
      purpose: MediaPurpose.INVOICE,
      loanApplicationId: loanApplicationId,
    })
  );
  const invoiceIds = await Promise.all(invoicePromises);
  if (invoiceIds.length) {
    const invoices = calculatorState.invoiceDiscountingLoan.invoices.map(
      (invoice, index) => ({
        ...invoice,
        invoiceId: invoiceIds[index],
        discountRate: invoice.discountRate,
        ...(invoice.invoiceDueDate && {
          invoiceDueDate: invoice.invoiceDueDate,
        }),
      })
    );
    await update({
      id: loanApplicationId,
      loanType: LoanProductType.INVOICE_DISCOUNTING,
      invoices,
    });
  }
  setLoading && setLoading(false);
}

export async function handlePayableFinancingLoanUpdate(
  loanApplicationId: string,
  invoiceFiles: File[],
  calculatorState: LoanCalculatorState,
  update: (
    payload: Partial<LoanApplicationContextValues["state"]>,
    options?: { local?: boolean }
  ) => Promise<any>,
  upload: (payload: UploadMediaArgs) => Promise<string>,
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>
) {
  setLoading && setLoading(true);
  const invoicePromises = invoiceFiles.map((file) =>
    upload({
      file,
      type: MediaType.DOCUMENT,
      purpose: MediaPurpose.INVOICE,
      loanApplicationId: loanApplicationId,
    })
  );
  const invoiceIds = await Promise.all(invoicePromises);
  if (invoiceIds.length) {
    const invoices = calculatorState.payableFinancingLoan.invoices.map(
      (invoice, index) => ({
        ...invoice,
        invoiceId: invoiceIds[index],
        discountRate: invoice.discountRate,
        ...(invoice.invoiceDueDate && {
          invoiceDueDate: invoice.invoiceDueDate,
        }),
      })
    );
    await update({
      id: loanApplicationId,
      loanType: LoanProductType.PAYABLE_FINANCING,
      invoices,
    });
  }
  setLoading && setLoading(false);
}

export async function handleRevenueBasedLoanUpdate(
  loanApplicationId: string,
  salesStatementFiles: File[],
  calculatorState: LoanCalculatorState,
  update: (
    payload: Partial<LoanApplicationContextValues["state"]>,
    options?: { local?: boolean }
  ) => Promise<any>,
  upload: (payload: UploadMediaArgs) => Promise<string>
) {
  const salesStatementPromises = salesStatementFiles.map((file) =>
    upload({
      file,
      type: MediaType.DOCUMENT,
      purpose: MediaPurpose.SALES_STATEMENT,
      loanApplicationId: loanApplicationId,
    })
  );
  const salesStatementIds = await Promise.all(salesStatementPromises);
  const salesStatements = salesStatementIds.map((_statement, index) => ({
    documentId: salesStatementIds[index],
  }));
  await update({
    id: loanApplicationId,
    loanType: LoanProductType.REVENUE_BASED_FINANCING,
    revenueBasedLoan: {
      ...calculatorState.revenueBasedLoan,
      salesStatements: salesStatements,
    },
  });
}

export async function handleBuyNowPayLaterLoanUpdate(
  loanApplicationId: string,
  salesInvoiceFiles: File[],
  calculatorState: LoanCalculatorState,
  update: (
    payload: Partial<LoanApplicationContextValues["state"]>,
    options?: { local?: boolean }
  ) => Promise<any>,
  upload: (payload: UploadMediaArgs) => Promise<string>
) {
  const salesInvoicePromises = salesInvoiceFiles.map((file) =>
    upload({
      file,
      type: MediaType.DOCUMENT,
      purpose: MediaPurpose.INVOICE,
      loanApplicationId: loanApplicationId,
    })
  );
  const salesInvoiceIds = await Promise.all(salesInvoicePromises);
  const salesInvoices = salesInvoiceIds.map((_invoice, index) => ({
    invoiceId: salesInvoiceIds[index],
  }));
  await update({
    id: loanApplicationId,
    loanType: LoanProductType.BNPL,
    buyNowPayLaterLoan: {
      ...calculatorState.buyNowPayLaterLoan,
      salesInvoices: [...salesInvoices],
    },
  });
}

export async function handleFixedTermLoanUpdate(
  loanApplicationId: string,
  calculatorState: LoanCalculatorState,
  update: (
    payload: Partial<LoanApplicationContextValues["state"]>,
    options?: { local?: boolean }
  ) => Promise<any>
) {
  await update({
    id: loanApplicationId,
    loanType: LoanProductType.FIXED_TERM,
    fixedTermLoan: calculatorState.fixedTermLoan,
  });
}

export const updateLoansStateForLegacyFlow = async (
  loanApplicationId: string,
  calculatorState: LoanCalculatorState,
  update: (
    payload: Partial<LoanApplicationContextValues["state"]>,
    options?: { local?: boolean }
  ) => Promise<any>,
  upload: (payload: UploadMediaArgs) => Promise<string>,
  invoiceFiles: File[],
  salesStatementFiles: File[],
  salesInvoiceFiles: File[]
) => {
  switch (calculatorState.type) {
    case LoanProductType.FIXED_TERM:
      await handleFixedTermLoanUpdate(
        loanApplicationId,
        calculatorState,
        update
      );
      break;
    case LoanProductType.INVOICE_DISCOUNTING:
      await handleInvoiceDiscountingLoanUpdate(
        loanApplicationId,
        invoiceFiles,
        calculatorState,
        update,
        upload,
        undefined
      );
      break;
    case LoanProductType.PAYABLE_FINANCING:
      await handlePayableFinancingLoanUpdate(
        loanApplicationId,
        invoiceFiles,
        calculatorState,
        update,
        upload,
        undefined
      );
      break;
    case LoanProductType.REVENUE_BASED_FINANCING:
      await handleRevenueBasedLoanUpdate(
        loanApplicationId,
        salesStatementFiles,
        calculatorState,
        update,
        upload
      );
      break;
    case LoanProductType.BNPL:
      await handleBuyNowPayLaterLoanUpdate(
        loanApplicationId,
        salesInvoiceFiles,
        calculatorState,
        update,
        upload
      );
      break;
    default:
      throw new Error("Invalid loan type");
  }
};

export const getMediaFromStatementIds = async (
  statementIds: string[],
  get: (args: { id: string }) => Promise<any>,
  onComplete: (retrievedMedia: CustomFile[]) => void
) => {
  const retrievedMedia: CustomFile[] = [];
  const promises = statementIds.map((statementId) =>
    get({ id: statementId })
      .then((res) => {
        const file = new CustomFile(
          new File([], res.media.fileName),
          res.media.id
        );
        Object.defineProperty(file, "size", {
          value: res.media.size,
        });
        retrievedMedia.push(file);
      })
      .catch((err) => {
        console.log(err);
      })
  );

  await Promise.all(promises);
  onComplete(retrievedMedia);
};
