import { useMutation, useQuery } from "react-query";
import { downloadCsv, fetchUtil } from "../../utils";
import {
  ApproveLoanOfferRequest,
  CreateLoanApplicationRequest,
  CreateVendorRequest,
  DeclineLoanOfferRequest,
  DrawdownFieldsRequestDTO,
  FinalizeMediaRequest,
  GetAllTransactionsExportProps,
  GetAllTransactionsProps,
  GetApplicationVendorsRequest,
  GetExistingShareholderSignatureSignelUrlRequest,
  GetMediaResponse,
  GetSignatureStrokeRequest,
  GetUserResponse,
  InitiateCopyRequest,
  InitiateOpenBankingRequest,
  LatestTransactionResponseDTO,
  LoanApplicationUniquenessResponseDTO,
  LoanContractScheduleTransactionListDTO,
  LoanVendorManagementAction,
  LogoDevBrand,
  ManualAccountsResponseDTO,
  OpenbankingApplicationStage,
  OpenBankingStatus,
  PaginatedResponse,
  PaymentHistoryResponseDTO,
  PaymentSummaryResponseDTO,
  PaymentTransactionsSortColumns,
  SaveDirectDebitBankAccountRequest,
  SaveMastercardMerchantIdsRequest,
  SendEmailOTPRequest,
  SendOTPByLoanApplicationIdRequest,
  SendOTPRequest,
  SortOrder,
  TransactionDTO,
  TransactionsResponseDTO,
  UpdateAdditionalInfo,
  UpdateCompanyShareholderRequest,
  UpdateIndividualShareholderRequest,
  UpdateLoanApplicationRequest,
  UpdateLoanOfferStageRequest,
  UpdateLoanVendorDocumentRequestDTO,
  UpdateMastercardMerchantIdsRequest,
  UpdateOpenBankingStageRequest,
  UpdateVendorRequest,
  UploadMedia,
  UploadMediaRequest,
  UsePaymentDetailsProps,
  ValidateLoanApplicationUniquenessRequest,
  Vendor,
  VerifyOriginRequest,
} from "./types";
import { LoanPayment } from "../../contexts";
import { LOCALSTORAGE_KEYS } from "../../constants";

export const useGetActiveLoanApplication = () => {
  return useMutation(async () => {
    const response = await fetchUtil({
      url: "/loan_application/active",
      method: "GET",
      token: true,
    });
    if (response?.externalId) {
      const externalId = response.externalId;
      localStorage.setItem(
        LOCALSTORAGE_KEYS.LOAN_APPLICATION_EXTERNAL_ID,
        externalId
      );
    }

    return response;
  });
};

export const useCancelActiveLoanApplication = () => {
  return useMutation(() => {
    return fetchUtil({
      url: "/loan_application/active/cancel",
      method: "POST",
      token: true,
    });
  });
};

export const useCreateLoanApplication = () => {
  return useMutation((payload: CreateLoanApplicationRequest) => {
    return fetchUtil({
      url: "/loan_application",
      method: "POST",
      token: true,
      body: payload,
    });
  });
};

export const useUpdateLoanApplication = () => {
  return useMutation((payload: UpdateLoanApplicationRequest) => {
    return fetchUtil({
      url: `/loan_application/${payload.id}`,
      method: "PATCH",
      token: true,
      body: payload,
    });
  });
};

/**
 * Generates a hook for sending an OTP to the provided phone number.
 *
 */
export const useSendOTP = () => {
  return useMutation((payload: SendOTPRequest) => {
    return fetchUtil({
      url: `/user/phone_number`,
      method: "PUT",
      token: true,
      body: payload,
    });
  });
};

/**
 * Generates a hook for sending an OTP to the provided email.
 *
 */
export const useSendEmailOTP = () => {
  return useMutation((payload: SendEmailOTPRequest) => {
    return fetchUtil({
      url: `/user/email`,
      method: "PUT",
      token: true,
      body: payload,
    });
  });
};

// export const useVerifyOTP = () => {
//   return useMutation((payload: VerifyOTPRequest) => {
//     return fetchUtil({
//       url: `/user/phone_number/verify`,
//       method: "POST",
//       token: true,
//       body: { otpCode: payload.otpCode },
//     });
//   });
// };

export const useMediaUploadRequest = () => {
  return useMutation((payload: UploadMediaRequest) => {
    return fetchUtil({
      url: "/loan_application/media/upload/request",
      method: "POST",
      token: true,
      body: payload,
    });
  });
};

export const useMediaFinalizeRequest = () => {
  return useMutation((payload: FinalizeMediaRequest) => {
    return fetchUtil({
      url: "/loan_application/media/upload/finalize",
      method: "POST",
      token: true,
      body: payload,
    });
  });
};

export const useMediaUpload = () => {
  return useMutation<GetMediaResponse, unknown, UploadMedia, unknown>(
    (payload: UploadMedia) => {
      return fetchUtil({
        url: payload.url,
        method: "PUT",
        token: true,
        body: payload.file,
        media: true,
      });
    }
  );
};

export const useGetMedia = () => {
  return useMutation((mediaId: string) => {
    return fetchUtil({
      url: `/loan_application/media/${mediaId}`,
      method: "GET",
      token: true,
    });
  });
};

export const useGetKycAccessToken = (enabled = true) => {
  return useQuery({
    queryKey: ["loan_application", "kyc", "token"],
    queryFn: () => {
      return fetchUtil({
        url: `/loan_application/kyc/token`,
        method: "GET",
        token: true,
      });
    },
    enabled,
  });
};

export const useGetKycShareholderAccessToken = (shareholderId: string) => {
  return useQuery({
    queryKey: ["loan_application", "kyc", "token", shareholderId],
    queryFn: () => {
      return fetchUtil({
        url: `/loan_application/kyc/shareholders/token/${shareholderId}`,
        method: "GET",
        token: true,
      });
    },
    enabled: false,
  });
};

export const useResetKycShareholderAccessToken = () => {
  return useMutation((payload: { shareholderId?: string }) => {
    return fetchUtil({
      url: `/loan_application/kyc/shareholders/token/${payload.shareholderId}/reset`,
      method: "POST",
      token: true,
    });
  });
};

export const useGetLAForShareholderKYC = () => {
  return useQuery(
    ["loan_application", "shareholder_kyc"],
    () => {
      return fetchUtil({
        url: `/loan_application/kyc/shareholder/loan`,
        method: "GET",
        token: true,
      });
    },
    { enabled: false }
  );
};

export const useInitiateOpenBanking = () => {
  return useMutation((payload: InitiateOpenBankingRequest) => {
    return fetchUtil({
      url: `/loan_application/${payload.loanApplicationId}/openbanking`,
      method: "POST",
      token: true,
    });
  });
};

export const useUpdateOpenBankingStage = () => {
  return useMutation((payload: UpdateOpenBankingStageRequest) => {
    return fetchUtil({
      url: `/loan_application/${payload.loanApplicationId}/openbanking/stage`,
      method: "PUT",
      token: true,
      body: { stage: payload.stage },
    });
  });
};

const LEANTECH_POLLING_STATUSES = [
  OpenBankingStatus.IN_PROGRESS,
  OpenBankingStatus.INITIATED,
  OpenBankingStatus.RESULTS_PENDING,
];
const LEANTECH_POLLING_STAGES = [
  OpenbankingApplicationStage.INITIATED,
  OpenbankingApplicationStage.WAITING_FOR_WEBHOOK,
  OpenbankingApplicationStage.FETCH_TRANSACTIONS,
];

export const useGetOpenBankingStatus = (
  applicationId: string,
  onSuccess: (data: any) => void,
  enabled?: boolean
) => {
  return useQuery(
    ["loan_application", "open_banking", applicationId, "status"],
    () => {
      return fetchUtil({
        url: `/loan_application/${applicationId}/openbanking/status`,
        token: true,
      });
    },
    {
      refetchInterval: (data) =>
        LEANTECH_POLLING_STATUSES.includes(data?.status) &&
        LEANTECH_POLLING_STAGES.includes(data?.stage)
          ? Number(import.meta.env.VITE_OPENBANKING_STATUS_POLLING_INTERVAL) ||
            5000
          : false,
      onSuccess: (data) => {
        const newData = { ...data };
        if (data.banks?.length) {
          newData.banks = Array.from(
            new Set(data.banks.map((b: any) => b.name))
          ).map((name) => {
            return data.banks.find((b: any) => b.name === name);
          });
        }
        onSuccess(newData);
      },
      enabled: !!enabled,
      retry: false,
    }
  );
};

export const useGetDashboardLoans = () => {
  return useMutation(() => {
    return fetchUtil({
      url: `/loan_application/dashboard/loans`,
      method: "GET",
      token: true,
    });
  });
};

export const useGetLicensingAuthorities = () => {
  return useMutation(() => {
    return fetchUtil({
      url: `/loan_application/business/licensing/authorities`,
      method: "GET",
      token: true,
    });
  });
};

export const useGetIndustryTypes = () => {
  return useMutation(() => {
    return fetchUtil({
      url: `/loan_application/business/industry/types`,
      method: "GET",
      token: true,
    });
  });
};

export const useGetBusinessTypes = () => {
  return useMutation(() => {
    return fetchUtil({
      url: `/loan_application/business/types`,
      method: "GET",
      token: true,
    });
  });
};

export const useGetLoanOffer = () => {
  return useMutation(() => {
    return fetchUtil({
      url: `/loan_application/offer`,
      method: "GET",
      token: true,
    });
  });
};

export const useDeclineLoanOffer = () => {
  return useMutation((payload: DeclineLoanOfferRequest) => {
    return fetchUtil({
      url: `/loan_application/offer/${payload.id}/decline`,
      method: "POST",
      body: payload,
      token: true,
    });
  });
};

export const useApproveLoanOffer = () => {
  return useMutation((payload: ApproveLoanOfferRequest) => {
    return fetchUtil({
      url: `/loan_application/offer/${payload.id}/approve`,
      method: "POST",
      body: payload,
      token: true,
    });
  });
};

export const useDirectDebitBankAccounts = () => {
  return useMutation((id: string) => {
    return fetchUtil({
      url: `/direct_debit/bank/accounts?loanOfferId=${id}`,
      method: "GET",
      token: true,
    });
  });
};

export const useUpdateLoanOfferStage = () => {
  return useMutation((payload: UpdateLoanOfferStageRequest) => {
    return fetchUtil({
      url: `/loan_application/offer/${payload.id}/stage`,
      method: "PUT",
      token: true,
      body: { stage: payload.stage },
    });
  });
};

export const useSaveDirectDebitBankAccount = () => {
  return useMutation((payload: SaveDirectDebitBankAccountRequest) => {
    return fetchUtil({
      url: `/direct_debit/bank/account`,
      method: "POST",
      token: true,
      body: payload,
    });
  });
};

export const useGetExistingShareholderSignature = () => {
  return useMutation(
    (payload: GetExistingShareholderSignatureSignelUrlRequest) => {
      return fetchUtil({
        url: `/direct_debit/${payload.loanApplicationId}/shareholder/${payload.shareholderId}/signature`,
        method: "GET",
        token: true,
      });
    }
  );
};

export const useExtractSignatureStroke = () => {
  return useMutation((payload: GetSignatureStrokeRequest) => {
    return fetchUtil({
      url: `/direct_debit/signature`,
      method: "POST",
      token: true,
      body: payload,
    });
  });
};

export const useDownloadDirectDebitFilledForm = () => {
  return useMutation(() => {
    return fetchUtil({
      url: `/direct_debit/form/download`,
      method: "GET",
      token: true,
      raw: true,
    }).then((res) => res.blob());
  });
};

export const useLoanProducts = () => {
  return useQuery(
    ["loan_products"],
    () => {
      return fetchUtil({
        url: "/partner/products/config",
        method: "GET",
        token: true,
      });
    },
    {
      staleTime: Infinity,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetSubmittedLoans = () => {
  return useMutation(() => {
    return fetchUtil({
      url: "/loan_application/submitted",
      method: "GET",
      token: true,
    });
  });
};

export const usePostVerifyOrigin = () => {
  return useMutation((payload: VerifyOriginRequest) => {
    return fetchUtil({
      url: "/partner/origin/verify",
      method: "POST",
      token: true,
      body: payload,
    });
  });
};

export const useDeleteIndividualShareholder = () => {
  return useMutation((id: string) => {
    return fetchUtil({
      url: `/loan_application/shareholder/individual/${id}`,
      method: "DELETE",
      token: true,
    });
  });
};

export const useDeleteCompanyShareholder = () => {
  return useMutation((id: string) => {
    return fetchUtil({
      url: `/loan_application/shareholder/company/${id}`,
      method: "DELETE",
      token: true,
    });
  });
};

export const useUpdateIndividualShareholder = () => {
  return useMutation((payload: UpdateIndividualShareholderRequest) => {
    return fetchUtil({
      url: `/loan_application/shareholder/individual/${payload.id}`,
      method: "PUT",
      token: true,
      body: payload,
    });
  });
};

export const useUpdateCompanyShareholder = () => {
  return useMutation((payload: UpdateCompanyShareholderRequest) => {
    return fetchUtil({
      url: `/loan_application/shareholder/company/${payload.id}`,
      method: "PUT",
      token: true,
      body: payload,
    });
  });
};

export const useLoanApplicationPayments = (
  loanApplicationId: string,
  enabled: boolean = true
) => {
  return useQuery<LoanPayment[]>(
    ["loan_application", loanApplicationId, "contract", "payments"],
    () => {
      return fetchUtil({
        url: `/loan_application/${loanApplicationId}/contract/payments`,
        method: "GET",
        token: true,
      });
    },
    {
      refetchOnWindowFocus: false,
      enabled,
    }
  );
};

export const useUpdateLoanAdditionalInfo = () => {
  return useMutation((payload: UpdateAdditionalInfo) => {
    return fetchUtil({
      url: `/loan_application/${payload.id}/info`,
      method: "PATCH",
      token: true,
      body: payload,
    });
  });
};

export const useLoansSummary = () => {
  return useMutation(() => {
    return fetchUtil({
      url: "/loan_application/summary",
      method: "GET",
      token: true,
    });
  });
};

export const useUser = () => {
  return useQuery<GetUserResponse>(["user"], async () => {
    return await fetchUtil({
      url: "/user",
      method: "GET",
      token: true,
    });
  });
};

export const useDeleteMedia = () => {
  return useMutation((id: string) => {
    return fetchUtil({
      url: `/loan_application/media/${id}`,
      method: "DELETE",
      token: true,
    });
  });
};

export const usePaymentHistory = (loanApplicationId: string) => {
  return useQuery<PaymentHistoryResponseDTO>(
    ["paymentHistory", loanApplicationId],
    async () => {
      return fetchUtil({
        url: `/loan_application/${loanApplicationId}/repayment_history`,
        method: "GET",
        token: true,
      });
    }
  );
};

export const usePaymentSummary = (loanApplicationId: string) => {
  return useQuery<PaymentSummaryResponseDTO>(
    ["paymentSummary", loanApplicationId],
    async () => {
      return fetchUtil({
        url: `/loan_application/${loanApplicationId}/repayment_summary`,
        method: "GET",
        token: true,
      });
    }
  );
};

export const usePaymentDetails = (props: UsePaymentDetailsProps) => {
  const url = `/loan_application/${props.loanApplicationId}/payment_history/${props.paymentId}/transactions`;
  return useQuery<LoanContractScheduleTransactionListDTO>(
    ["payment_details", props.loanApplicationId, props.paymentId, props.search],
    async () => {
      return fetchUtil({
        url: url,
        method: "GET",
        token: true,
        queryParams: {
          sortColumn: props.sortColumn,
          sortOrder: props.sortOrder,
          search: props.search,
          limit: props.limit,
        },
      });
    }
  );
};

export const useGetAllTransactions = ({
  page = 1,
  pageSize = 50,
  disablePagination = true,
  loanApplicationId = "",
  filters = {
    searchText: "",
    sortOrder: SortOrder.DESC,
    sortColumn: PaymentTransactionsSortColumns.DATE,
  },
}: GetAllTransactionsProps) => {
  const url = `/loan_application/${loanApplicationId}/payment_history/transactions`;
  return useQuery<TransactionsResponseDTO>(
    ["transactions", loanApplicationId, filters.searchText],
    async () => {
      return fetchUtil({
        url: url,
        method: "GET",
        token: true,
        queryParams: {
          page,
          take: pageSize,
          disablePagination,
          sortColumn: filters.sortColumn,
          order: filters.sortOrder,
          search: filters.searchText,
        },
      });
    }
  );
};

export const useAllTransactionsExport = ({
  disablePagination = true,
  loanApplicationId = "",
  filters = {
    searchText: "",
    sortOrder: SortOrder.DESC,
    sortColumn: PaymentTransactionsSortColumns.DATE,
  },
}: GetAllTransactionsExportProps) => {
  return useQuery({
    queryKey: [
      "all_transactions_export",
      loanApplicationId,
      filters.searchText,
    ],
    queryFn: async (): Promise<TransactionDTO[]> => {
      const params = new URLSearchParams({
        disablePagination: disablePagination.toString(),
      });

      if (filters.sortOrder && filters.sortColumn) {
        params.append("order", filters.sortOrder.toString());
        params.append("sortColumn", filters.sortColumn.toString());
      }

      return fetchUtil({
        url: `/loan_application/${loanApplicationId}/payment_history/transactions/export?${params}`,
        method: "GET",
        token: true,
        raw: true,
      }).then(async (res) => {
        downloadCsv(res);
        return res;
      });
    },
    enabled: false,
  });
};

export const usePaymentDetailsExport = (
  loanApplicationId: string,
  paymentId: string,
  search?: string
) => {
  const url = `/loan_application/${loanApplicationId}/payment_history/${paymentId}/transactions/export`;
  return useQuery<any>(
    ["payment_details_export", loanApplicationId, paymentId, search],
    async () => {
      return fetchUtil({
        url: url,
        method: "GET",
        token: true,
        csv: true,
        queryParams: { search: search },
      }).then(async (res) => {
        if (res.ok) {
          const blob = await res.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          // Extract the filename from the Content-Disposition header, if available
          const contentDisposition = res.headers.get("Content-Disposition");
          let fileName = "file.csv";
          if (contentDisposition && contentDisposition.includes("filename=")) {
            fileName = contentDisposition
              .split("filename=")?.[1]
              ?.split(";")?.[0]
              ?.replace(/"/g, "");
          }
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        }
        return res;
      });
    },
    {
      enabled: false, // Query will not run automatically
    }
  );
};

export const useGetLatestTranasction = (refId: string) => {
  return useQuery<LatestTransactionResponseDTO>(
    ["loan_application", refId, "transactions", "latest"],
    async () => {
      return fetchUtil({
        url: `/loan_application/active/transactions/latest`,
        method: "GET",
        token: true,
        queryParams: {
          refId: refId,
        },
      });
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: false,
    }
  );
};

export const useGetLoanApplicationManualAccounts = (loanId: string) => {
  return useQuery<ManualAccountsResponseDTO>(
    ["loan_application", loanId],
    async () => {
      return fetchUtil({
        url: `/loan_application/${loanId}/manual-accounts`,
        method: "GET",
        token: true,
      });
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: true,
    }
  );
};

export const useValidateLoanApplicationUniqueness = () => {
  return useMutation<
    LoanApplicationUniquenessResponseDTO,
    Error,
    ValidateLoanApplicationUniquenessRequest
  >((payload: ValidateLoanApplicationUniquenessRequest) => {
    return fetchUtil({
      url: `/loan_application/${payload.loanId}/validate-uniqueness`,
      method: "GET",
      token: true,
      queryParams: {
        tradeLicenseNumber: payload.tradeLicenseNumber,
        licensingAuthority: payload.licensingAuthority,
      },
    });
  });
};

export const useInitiateCopy = () => {
  return useMutation((payload: InitiateCopyRequest) => {
    return fetchUtil({
      url: `/loan_application/${payload.loanApplicationId}/initiate-copy`,
      method: "PUT",
      token: true,
      body: { refId: payload.refId },
    });
  });
};

export const useSendOTPByLoanApplicationId = () => {
  return useMutation((payload: SendOTPByLoanApplicationIdRequest) => {
    return fetchUtil({
      url: `/loan_application/${payload.loanApplicationId}/send-otp`,
      method: "PUT",
      token: true,
    });
  });
};

export const useSaveMastercardMerchantIds = () => {
  return useMutation((payload: SaveMastercardMerchantIdsRequest) => {
    return fetchUtil({
      url: `/mastercard/${payload.loanApplicationId}/merchants`,
      method: "POST",
      body: payload,
      token: true,
    });
  });
};

export const useGetMastercardMerchantIds = () => {
  return useMutation((loanApplicationId: string) => {
    return fetchUtil({
      url: `/mastercard/${loanApplicationId}/merchants`,
      method: "GET",
      token: true,
    });
  });
};

export const useDeleteMastercardMerchantIds = () => {
  return useMutation((payload: SaveMastercardMerchantIdsRequest) => {
    return fetchUtil({
      url: `/mastercard/${payload.loanApplicationId}/merchants`,
      method: "DELETE",
      token: true,
      body: payload,
    });
  });
};

export const useUpdateMastercardMerchantIds = () => {
  return useMutation((payload: UpdateMastercardMerchantIdsRequest) => {
    return fetchUtil({
      url: `/mastercard/${payload.loanApplicationId}/merchants/${payload.merchantDataId}`,
      method: "PUT",
      token: true,
      body: payload,
    });
  });
};

export const useSearchBrand = (query: string) => {
  return useQuery<Array<LogoDevBrand>>({
    queryKey: ["brand", query],
    queryFn: () => {
      return fetchUtil({
        url: `/utils/brand/${query}`,
        method: "GET",
        token: true,
      });
    },
    enabled: false,
  });
};

export const useCreateVendor = () => {
  return useMutation((payload: CreateVendorRequest) => {
    return fetchUtil({
      url: `/loan_application/${payload.loanApplicationId}/vendors`,
      method: "POST",
      token: true,
      body: payload,
    });
  });
};

export const useUpdateLoanVendor = () => {
  return useMutation((payload: Partial<UpdateVendorRequest>) => {
    return fetchUtil({
      url: `/loan_application/${payload.loanApplicationId}/vendors/${payload.loanVendorId}`,
      method: "PUT",
      token: true,
      body: payload,
    });
  });
};

export const useGetApplicationVendors = (
  searchQuery: GetApplicationVendorsRequest
) => {
  return useQuery<PaginatedResponse<Vendor>>({
    queryKey: ["vendor", searchQuery.loanApplicationId],
    queryFn: () => {
      return fetchUtil({
        url: `/loan_application/${searchQuery.loanApplicationId}/vendors`,
        method: "GET",
        token: true,
        queryParams: searchQuery,
      });
    },
    enabled: !!searchQuery.loanApplicationId,
  });
};

export const useDeleteVendor = () => {
  return useMutation(
    (payload: { loanApplicationId: string; loanVendorId: string }) => {
      return fetchUtil({
        url: `/loan_application/${payload.loanApplicationId}/vendors/${payload.loanVendorId}`,
        method: "DELETE",
        token: true,
      });
    }
  );
};

export const useDeleteLoanVendorDocument = () => {
  return useMutation(
    (payload: {
      loanApplicationId: string;
      loanVendorId: string;
      documentId: string;
    }) => {
      return fetchUtil({
        url: `/loan_application/${payload.loanApplicationId}/vendors/${payload.loanVendorId}/document/${payload.documentId}`,
        method: "DELETE",
        token: true,
      });
    }
  );
};

export const useUpdateLoanVendorDocumentRequest = () => {
  return useMutation(
    (payload: {
      loanApplicationId: string;
      loanVendorId: string;
      documentRequestId: string;
      update: UpdateLoanVendorDocumentRequestDTO;
    }) => {
      return fetchUtil({
        url: `/loan_application/${payload.loanApplicationId}/vendors/${payload.loanVendorId}/document_requests/${payload.documentRequestId}`,
        method: "PATCH",
        token: true,
        body: payload.update,
      });
    }
  );
};

export const useVendorManagementActions = () => {
  return useMutation(
    (payload: {
      loanApplicationId: string;
      type: LoanVendorManagementAction;
    }) => {
      return fetchUtil({
        url: `/loan_application/${payload.loanApplicationId}/vendors/actions/${payload.type}`,
        method: "POST",
        token: true,
      });
    }
  );
};

export const useCreateDrawdown = () => {
  return useMutation(
    (payload: {
      loanApplicationId: string;
      drawdownFields: DrawdownFieldsRequestDTO;
    }) => {
      return fetchUtil({
        url: `/loan_application/${payload.loanApplicationId}/drawdown`,
        method: "POST",
        token: true,
        body: payload.drawdownFields,
      });
    }
  );
};

export const useValidateDrawdownEligibility = () => {
  return useMutation((loanApplicationId: string) => {
    return fetchUtil({
      url: `/loan_application/${loanApplicationId}/drawdown/validate`,
      method: "GET",
      token: true,
    });
  });
};
