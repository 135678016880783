import { LoanApplication, useLoanDashboard } from "../../../contexts";
import { FC, useCallback, useMemo } from "react";
import {
  AdditionalInfoType,
  LoanApplicationStatus,
  LoanApplicationStatusLabels,
  LoanProductType,
} from "../../../hooks/api/types";
import { Card } from "../../../components";
import LoanCardActions from "./LoanCardActions";
import LoanDetails from "./LoanDetails";
import { clsx } from "clsx";
import { DateTime } from "luxon";
import { useDpConfig } from "../../../contexts/dpConfig";
import { useValidateDrawdownEligibility } from "../../../hooks/api";

type LoanCardProps = {
  loan: LoanApplication;
  index: number;
};

export const LoanCard: FC<LoanCardProps> = ({ loan, index }) => {
  const {
    state: { products },
  } = useDpConfig();

  const {
    actions: { update },
  } = useLoanDashboard();

  const validateDrawdown = useValidateDrawdownEligibility();

  const readableStatus = useMemo(() => {
    if (
      loan.additionalInfo?.every(
        (info: any) => info.type === AdditionalInfoType.SHAREHOLDERS_AECB
      )
    ) {
      return LoanApplicationStatusLabels[LoanApplicationStatus.PREAPPROVED];
    } else {
      return LoanApplicationStatusLabels[loan.status];
    }
  }, [loan]);
  const productConfig = products?.find(
    (product) => product.id === loan.loanProductId
  );

  const handleApplyDrawDown = useCallback(async () => {
    if (
      loan.loanType === LoanProductType.LOC_INVOICE_DISCOUNTING ||
      loan.loanType === LoanProductType.LOC_PAYABLE_FINANCING
    ) {
      try {
        const response = await validateDrawdown.mutateAsync(loan.id);
        if (response.isValid) {
          update({
            currentLoanApplicationId: loan.id,
            currentLoanApplication: loan,
            showDrawdownForm: true,
          });
        }
      } catch (error) {
        console.error("Drawdown validation failed:", error);
      }
    }
  }, [update, validateDrawdown, loan]);

  return (
    <Card key={`loan-${index}`} title={index ? undefined : "Your loans"}>
      <div>
        <div className="cx-w-full sm:cx-flex cx-flex-row cx-items-center cx-px-6 cx-pb-4 cx-border-b-0.5 cx-border-neutral-light">
          <div className="cx-grow cx-flex cx-flex-row cx-items-center">
            <div className="cx-text-text-primary cx-font-semibold cx-text-lg cx-mr-4">
              {loan?.loanProduct?.displayName || "-"}
            </div>
            <div
              className={clsx(
                "cx-py-1 cx-px-3 cx-rounded-btn",
                readableStatus?.displayClasses || ""
              )}
            >
              {readableStatus?.label || "-"}
            </div>
          </div>
          <div className="sm:cx-flex cx-flex-row cx-items-center cx-gap-x-2">
            <div
              className={`cx-text-text-secondary cx-font-medium cx-text-sm ${
                productConfig?.config?.enableVendorScreen
                  ? "cx-cursor-pointer"
                  : ""
              }`}
              onClick={async () => {
                productConfig?.config?.enableVendorScreen &&
                  (await update({
                    currentLoanApplicationId: loan.id,
                    showLoanDetails: true,
                  }));
              }}
            >
              {`Loan #${loan.externalId}`}
            </div>
            {/* <div className="cx-hidden sm:cx-block cx-w-1 cx-h-1 cx-rounded-btn cx-bg-neutral-light" />
                    <div className="cx-text-text-secondary cx-font-medium cx-text-sm">
                      {`${roundTo(
                        (loanInput?.interestRate / 12) * 100,
                        2
                      )}% Interest`}
                    </div> */}
            <div className="cx-hidden sm:cx-block cx-w-1 cx-h-1 cx-rounded-btn cx-bg-neutral-light" />

            {loan.interestRate && (
              <div className="sm:cx-flex cx-flex-row cx-items-center cx-gap-x-2 ">
                <div className="cx-text-text-secondary cx-font-medium cx-text-sm">
                  {`${loan.interestRate} % Interest`}
                </div>
                <div className="cx-hidden sm:cx-block cx-w-1 cx-h-1 cx-rounded-btn cx-bg-neutral-light" />
              </div>
            )}

            {!!loan?.submittedAt && (
              <div className="cx-text-text-secondary cx-font-medium cx-text-sm">
                Applied on{" "}
                {DateTime.fromISO(loan.submittedAt).toFormat("dd LLL yyyy")}
              </div>
            )}
          </div>
        </div>
        <div
          onClick={handleApplyDrawDown}
          className={`cx-transition-colors hover:cx-bg-neutral-50 ${
            loan.loanType === LoanProductType.LOC_INVOICE_DISCOUNTING ||
            loan.loanType === LoanProductType.LOC_PAYABLE_FINANCING
              ? "cx-cursor-pointer"
              : "cx-cursor-default"
          }`}
        >
          <LoanDetails loan={loan} />
        </div>
        <LoanCardActions loan={loan} />
      </div>
    </Card>
  );
};

export default LoanCard;
