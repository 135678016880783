import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import {
  LOAN_CALCULATOR_INITIAL_VALUES,
  LoanCalculatorActions,
  UserLoanCalculatorConstraintsConfig,
} from "./types";
import { LoanCalculatorReducer } from "./reducer";
import { useLoanProducts } from "../../hooks";
import { LoanDurationType, LoanProductType } from "../../hooks/api/types";
import { ButtonLoaderIcon } from "../../assets/icons";
import { useLoanApplication } from "../loanApplication";
import { LoanProductConfig } from "../dpConfig/types";

const LoanCalculatorContext = createContext(LOAN_CALCULATOR_INITIAL_VALUES);

type Props = {
  children: React.ReactNode;
};

const Loader = () => {
  return (
    <div className="cx-w-full cx-h-screen cx-flex cx-justify-center cx-items-center cx-text-brand-primary-regular">
      <ButtonLoaderIcon className="cx-animate-spin cx-w-6 cx-h-6" />
    </div>
  );
};

const replaceConfigWithUserConstraintsConfig = (
  loanConfig: LoanProductConfig,
  userConstraintsConfig: UserLoanCalculatorConstraintsConfig
) => {
  if (!userConstraintsConfig) return loanConfig;
  const buckets = userConstraintsConfig.buckets;

  const maxAmount = Math.max(
    ...buckets.map((bucket) => bucket.range.amount.to)
  );
  const minAmount = Math.min(
    ...buckets.map((bucket) => bucket.range.amount.from)
  );

  const initialAmount = buckets[0].range.amount.from;

  // Handle both tenure range (object) and single tenure value (number)
  const firstTenure = buckets[0].constraints.tenure;
  const lastTenure = buckets[buckets.length - 1].constraints.tenure as {
    from: number;
    to: number;
  };
  const isRange = typeof firstTenure === "object";
  const maxDuration = isRange ? lastTenure.to : firstTenure;
  const minDuration = isRange ? lastTenure.from : firstTenure;
  const initialDuration = isRange ? firstTenure.from : firstTenure;

  const interestRate = buckets[buckets.length - 1].constraints.interestRate;

  return {
    ...loanConfig,
    amount: {
      ...loanConfig.amount,
      max: maxAmount,
      min: minAmount,
      initial: initialAmount,
    },
    duration: {
      ...loanConfig.duration,
      max: maxDuration,
      min: minDuration,
      initial: initialDuration,
      isRange: isRange,
    },
    interestRate: interestRate,
  };
};

export const LoanCalculatorProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(
    LoanCalculatorReducer,
    LOAN_CALCULATOR_INITIAL_VALUES["state"]
  );

  const { data: loanProducts } = useLoanProducts();
  const {
    state: {
      fixedTermLoan,
      revenueBasedLoan,
      posRevenueBasedLoan,
      buyNowPayLaterLoan,
      loanType,
      invoices,
    },
    actions: { update },
  } = useLoanApplication();

  useEffect(() => {
    update({ loading: true }, { local: true }).then();
    if (loanProducts?.loanConfig.length) {
      const fixedTermProduct = loanProducts?.loanConfig.find(
        (product: any) => product.type === LoanProductType.FIXED_TERM
      );
      const invoiceDiscountingProduct = loanProducts?.loanConfig.find(
        (product: any) => product.type === LoanProductType.INVOICE_DISCOUNTING
      );
      const payableFinancingProduct = loanProducts?.loanConfig.find(
        (product: any) => product.type === LoanProductType.PAYABLE_FINANCING
      );
      const revenueBasedProduct = loanProducts?.loanConfig.find(
        (product: any) =>
          product.type === LoanProductType.REVENUE_BASED_FINANCING
      );
      const posRevenueBasedProduct = loanProducts?.loanConfig.find(
        (product: any) =>
          product.type === LoanProductType.POS_REVENUE_BASED_FINANCING
      );
      const bnplProduct = loanProducts?.loanConfig.find(
        (product: any) => product.type === LoanProductType.BNPL
      );

      const locInvoiceDiscountingProduct = loanProducts?.loanConfig.find(
        (product: any) =>
          product.type === LoanProductType.LOC_INVOICE_DISCOUNTING
      );

      const locPayableFinancingProduct = loanProducts?.loanConfig.find(
        (product: any) => product.type === LoanProductType.LOC_PAYABLE_FINANCING
      );

      const configOverrideForRbf = replaceConfigWithUserConstraintsConfig(
        revenueBasedProduct?.config,
        revenueBasedProduct?.userConstraintsConfig
      );

      dispatch({
        type: "SET_CONSTRAINTS",
        payload: {
          fixedTermLoan: fixedTermProduct?.config,
          invoiceDiscountingLoan: invoiceDiscountingProduct?.config,
          payableFinancingLoan: payableFinancingProduct?.config,
          revenueBasedLoan: configOverrideForRbf,
          posRevenueBasedLoan: posRevenueBasedProduct?.config,
          buyNowPayLaterLoan: bnplProduct?.config,
          locInvoiceDiscountingLoan: locInvoiceDiscountingProduct?.config,
          locPayableFinancingLoan: locPayableFinancingProduct?.config,
          userConstraintsConfig: revenueBasedProduct?.userConstraintsConfig, // currently we only support user constraint for RBF
        },
      });

      if (revenueBasedProduct?.userConstraintsConfig) {
        dispatch({
          type: "SET_REVENUE_BASED_LOAN",
          payload: {
            ...revenueBasedLoan,
            amount: configOverrideForRbf.amount.initial,
            duration: configOverrideForRbf.duration.initial,
            interestRate: configOverrideForRbf.interestRate,
          },
        });
      }

      update({ loading: false }, { local: true }).then();
    }
  }, [loanProducts?.loanConfig]);

  useEffect(() => {
    switch (loanType) {
      case LoanProductType.FIXED_TERM: {
        dispatch({
          type: "SET_FIXED_TERM_LOAN",
          payload: fixedTermLoan,
        });
        break;
      }
      //at this moment we are not always getting the effective state from the api
      case LoanProductType.REVENUE_BASED_FINANCING: {
        dispatch({
          type: "SET_REVENUE_BASED_LOAN",
          payload: revenueBasedLoan,
        });
        break;
      }
      case LoanProductType.POS_REVENUE_BASED_FINANCING: {
        dispatch({
          type: "SET_POS_REVENUE_BASED_LOAN",
          payload: posRevenueBasedLoan,
        });
        break;
      }
      case LoanProductType.INVOICE_DISCOUNTING: {
        if (invoices) {
          dispatch({
            type: "SET_INVOICE_DISCOUNTING_LOAN",
            payload: {
              invoices: invoices.map((invoice) => ({
                ...invoice,
                discountRate: invoice.discountRate,
                ...(invoice.invoiceDueDate && {
                  invoiceDueDate: invoice.invoiceDueDate,
                }),
              })),
            },
          });
        } else {
          const invoiceDiscountingProduct = loanProducts?.loanConfig?.find(
            (product: any) => {
              return product.type === LoanProductType.INVOICE_DISCOUNTING;
            }
          );
          dispatch({
            type: "SET_INVOICE_DISCOUNTING_LOAN",
            payload: {
              invoices: [
                {
                  amount:
                    invoiceDiscountingProduct?.config?.amount?.initial ||
                    invoiceDiscountingProduct?.config?.amount?.min,
                  duration:
                    invoiceDiscountingProduct?.config?.duration?.initial ||
                    invoiceDiscountingProduct?.config?.duration?.min,
                  discountRate:
                    invoiceDiscountingProduct?.config?.discount?.initial ||
                    invoiceDiscountingProduct?.config?.discount?.max,
                  interestRate: invoiceDiscountingProduct?.config?.interestRate,
                  invoiceId: "",
                  durationType: invoiceDiscountingProduct?.config?.duration
                    ?.unit as LoanDurationType,
                  invoiceDueDate: new Date(),
                  invoiceIssuedDate: new Date(),
                  invoiceNo: "",
                  vendorName: "",
                },
              ],
            },
          });
        }
        break;
      }
      case LoanProductType.PAYABLE_FINANCING: {
        const payableProduct = loanProducts?.loanConfig?.find(
          (product: any) => {
            return product.type === LoanProductType.PAYABLE_FINANCING;
          }
        );
        if (invoices) {
          dispatch({
            type: "SET_PAYABLE_FINANCING_LOAN",
            payload: {
              invoices: invoices.map((invoice) => ({
                ...invoice,
                discountRate: invoice.discountRate,
                ...(invoice.invoiceDueDate && {
                  invoiceDueDate: invoice.invoiceDueDate,
                }),
              })),
            },
          });
        } else {
          dispatch({
            type: "SET_PAYABLE_FINANCING_LOAN",
            payload: {
              invoices: [
                {
                  amount:
                    payableProduct?.config?.amount?.initial ||
                    payableProduct?.config?.amount?.min,
                  duration:
                    payableProduct?.config?.duration?.initial ||
                    payableProduct?.config?.duration?.min,
                  discountRate:
                    payableProduct?.config?.discount?.initial ||
                    payableProduct?.config?.discount?.max,
                  interestRate: payableProduct?.config?.interestRate,
                  invoiceId: "",
                  durationType: payableProduct?.config?.duration
                    ?.unit as LoanDurationType,
                  invoiceDueDate: new Date(),
                  invoiceIssuedDate: new Date(),
                  invoiceNo: "",
                  vendorName: "",
                },
              ],
            },
          });
        }
        break;
      }
      case LoanProductType.BNPL: {
        const bnplProduct = loanProducts?.loanConfig?.find((product: any) => {
          return product.type === LoanProductType.BNPL;
        });
        dispatch({
          type: "SET_BNPL_LOAN",
          payload: {
            amount:
              buyNowPayLaterLoan?.amount ||
              bnplProduct?.config?.amount?.initial,
            duration:
              buyNowPayLaterLoan?.duration ||
              bnplProduct?.config?.duration?.initial,
            interestRate:
              buyNowPayLaterLoan?.interestRate ||
              bnplProduct?.config?.interestRate,
            salesInvoices: buyNowPayLaterLoan?.salesInvoices,
            durationType:
              buyNowPayLaterLoan?.durationType ||
              bnplProduct?.config?.duration?.unit,
          },
        });
        break;
      }
    }
  }, [
    loanType,
    fixedTermLoan,
    invoices,
    revenueBasedLoan,
    buyNowPayLaterLoan,
    posRevenueBasedLoan,
  ]);

  const actions = useMemo<LoanCalculatorActions>(
    () => ({
      setFixedTermLoan: (loan) => {
        dispatch({
          type: "SET_FIXED_TERM_LOAN",
          payload: loan,
        });
      },
      setRevenueBasedLoan: (loan) => {
        dispatch({
          type: "SET_REVENUE_BASED_LOAN",
          payload: loan,
        });
      },
      setPosRevenueBasedLoan: (loan) => {
        dispatch({
          type: "SET_POS_REVENUE_BASED_LOAN",
          payload: loan,
        });
      },
      setInvoiceDiscountingLoan: (loan) => {
        dispatch({
          type: "SET_INVOICE_DISCOUNTING_LOAN",
          payload: loan,
        });
      },
      setLocInvoiceDiscountingLoan: (loan) => {
        dispatch({
          type: "SET_LOC_INVOICE_DISCOUNTING_LOAN",
          payload: loan,
        });
      },
      setPayableFinancingLoan: (loan) => {
        dispatch({
          type: "SET_PAYABLE_FINANCING_LOAN",
          payload: loan,
        });
      },
      setLocPayableFinancingLoan: (loan) => {
        dispatch({
          type: "SET_LOC_PAYABLE_FINANCING_LOAN",
          payload: loan,
        });
      },
      setBuyNowPayLaterLoan: (loan) => {
        dispatch({
          type: "SET_BNPL_LOAN",
          payload: loan,
        });
      },
      setLoading: (loading) => {
        dispatch({ type: "SET_LOADING", payload: loading });
      },
      setRevenueBasedLoanConstraints: (constraints: LoanProductConfig) => {
        dispatch({
          type: "SET_REVENUE_BASED_LOAN_CONSTRAINTS",
          payload: constraints,
        });
      },
    }),
    [dispatch]
  );

  const contextValue = useMemo(() => ({ state, actions }), [state, actions]);

  if (state.loading) {
    return <Loader />;
  }

  return (
    <LoanCalculatorContext.Provider value={contextValue}>
      {children}
    </LoanCalculatorContext.Provider>
  );
};

export const useLoanCalculator = () => useContext(LoanCalculatorContext);
