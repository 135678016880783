import React, { FC, useEffect, useState } from "react";
import { ArrowLeftIcon, ShareholderUserIcon } from "../../../assets/icons";
import {
  Button,
  Divider,
  StepNextButton,
  StepPrevButton,
} from "../../../components";
import { useLoanApplication } from "../../../contexts";
import {
  LoanApplicationStage,
  LoanProductType,
  Vendor,
} from "../../../hooks/api/types";
import { getVendorNature } from "./utils";
import VendorInformationModal from "./VendorInformationModal";
import { useDeleteVendor, useGetApplicationVendors } from "../../../hooks";
import { useStepper } from "../Stepper";
import VendorCard from "./VendorCard";

type Props = {};
const VendorInformation: FC<Props> = () => {
  const {
    state: { loanType, id },
    actions: { update },
  } = useLoanApplication();
  const { previousStep } = useStepper();

  const { data: vendors, refetch } = useGetApplicationVendors({
    loanApplicationId: id,
    pageSize: Number.MAX_SAFE_INTEGER,
  });
  const { mutateAsync: deleteVendor } = useDeleteVendor();

  const [focusedVendor, setFocusedVendor] = useState<Vendor | undefined>(
    undefined
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    refetch();
  }, [id]);

  return (
    <div className="cx-flex cx-flex-col cx-items-center">
      <div className="cx-flex cx-flex-col cx-w-3/4 cx-pb-8">
        <div className="cx-text-3xl cx-font-bold cx-text-center cx-mb-1">
          Add your {`${getVendorNature(loanType as LoanProductType)}s`}
        </div>
        <div className="cx-text-base cx-text-text-secondary cx-text-center">
          Please add your {`${getVendorNature(loanType as LoanProductType)}s`}{" "}
          to proceed with application. This will help us assess and streamline
          our financing process.
        </div>
      </div>
      <div className="cx-w-96 cx-mb-8">
        <Divider />
      </div>
      {!vendors?.data?.length && (
        <div className="cx-border cx-border-stroke-primary cx-rounded-base cx-p-4 cx-flex cx-flex-row cx-items-center cx-justify-between cx-w-96 cx-mb-8">
          <div className="cx-flex cx-flex-row">
            <ShareholderUserIcon className="cx-text-brand-primary-regular cx-w-6 cx-h-6" />
            <div className="cx-text-base cx-font-medium cx-ml-2">
              First {`${getVendorNature(loanType as LoanProductType)}`}
            </div>
          </div>
          <Button
            label={"Add"}
            slim
            onClick={() => {
              setIsModalOpen(true);
            }}
          />
        </div>
      )}
      {vendors?.data && vendors.data.length > 0 && (
        <div>
          {vendors?.data?.map((vendor) => (
            <VendorCard
              key={vendor.loanVendorId} // Add a unique key to avoid React warnings
              vendor={vendor}
              onDelete={async () => {
                await deleteVendor({
                  loanApplicationId: id,
                  loanVendorId: vendor.loanVendorId,
                });
                await refetch();
              }}
              onEdit={() => {
                setFocusedVendor(vendor);
                setIsModalOpen(true);
              }}
            />
          ))}
          <div className="cx-w-96 cx-my-8">
            <Divider />
          </div>
          <Button
            label={`Add another ${getVendorNature(
              loanType as LoanProductType
            )}`}
            outlined={true}
            slim={true}
            fullWidth={true}
            className={"cx-h-12 cx-mb-4"}
            onClick={() => {
              setFocusedVendor(undefined);
              setIsModalOpen(true);
            }}
          />
        </div>
      )}

      <div className="cx-flex cx-flex-row cx-gap-x-4 cx-w-96">
        <StepNextButton
          label="Next"
          type="button"
          onClick={async () => {
            await update({ stage: LoanApplicationStage.REVIEW });
          }}
        />
      </div>
      <VendorInformationModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setFocusedVendor(undefined);
        }}
        onAddVendor={async () => {
          refetch();
        }}
        vendorState={focusedVendor}
      />
    </div>
  );
};

export default VendorInformation;
