import { useContext, useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import { CredibleLogoLight } from "../../assets/images";
import { useGetActiveLoanApplication } from "../../hooks";
import LoanPaymentScheduleForm from "./LoanPaymentScheduleForm";
import LoanDashboardForm from "./LoanDashboardForm";
import { SDKContext, useLoanDashboard } from "../../contexts";
import info from "../../constants/info.json";
import clsx from "clsx";
import { usePostHog } from "posthog-js/react";
import { useDpConfig } from "../../contexts/dpConfig";
import {
  LoanPaymentDetailsDashboard,
  LoanPaymentHistoryDashboard,
} from "./index";
import PosRevenueBasedLoanTransactions from "./PosRevenueBasedLoanTransactions";
import { datadogRum } from "@datadog/browser-rum";
import { LoanDetailsPage } from "./LoanDetailsPage";
import { Drawdown } from "./LineOfCredit/Drawdown";
import DrawdownSuccess from "./LineOfCredit/DrawdownSuccess";

type Props = {};

const LoanDashboardLayout: React.FC<Props> = () => {
  const [userName, setUserName] = useState("User");
  const {
    state: {
      currentLoanApplicationId,
      currentPaymentId,
      showPaymentDetails,
      showPaymentSchedule,
      showPaymentHistory,
      showTransactionHistory,
      showLoanDetails,
      showDrawdownForm,
      showDrawdownSuccess,
    },
  } = useLoanDashboard();

  const {
    state: { settings },
  } = useDpConfig();
  const { options } = useContext(SDKContext);

  const posthog = usePostHog();

  const { mutateAsync: getActiveLoanApplication } =
    useGetActiveLoanApplication();

  useEffect(() => {
    getActiveLoanApplication().then((data) => {
      if (data?.user?.name) {
        setUserName(data?.user?.name);
      } else if (data?.applicant) {
        setUserName(data?.applicant?.firstName);
      }

      if (data) {
        posthog.identify(data?.id, {
          dpId: data?.distributionPartnerId,
          name: data?.user?.name,
          email: data?.user?.email,
          loanApplicationId: data?.id,
        });
        datadogRum.setUser({
          id: data.id,
          loanApplicationId: data.id,
          distributionPartnerId: data.distributionPartnerId,
          distributionPartnerName: data.distributionPartner?.name,
          name: data?.user?.name,
          email: data?.user?.email,
        });
      }
    });
  }, []);

  return (
    <div className="cx-flex cx-flex-col cx-items-center cx-justify-center cx-w-full cx-min-h-screen cx-sticky">
      {!settings.hideHeader && !showDrawdownSuccess && (
        <div className="cx-flex cx-justify-center cx-items-center cx-w-full cx-border-stroke-primary cx-border-b">
          <div className="cx-flex cx-justify-between cx-items-center cx-w-full cx-max-w-[960px] cx-px-6 cx-py-4">
            <div className="cx-max-h-14 cx-min-h-[42px] cx-flex cx-justify-center cx-items-center">
              <img
                src={options?.logoUrl || CredibleLogoLight}
                className={clsx({
                  "cx-h-6": !options?.logoUrl,
                  "cx-h-14": options?.logoUrl,
                })}
                alt={"Logo"}
              />
            </div>
            <div className="cx-flex cx-justify-center cx-items-center">
              <div className="cx-w-10 cx-h-10 cx-mx-2 cx-rounded-full cx-flex cx-justify-center cx-items-center cx-bg-brand-primary-lighter">
                {userName?.[0]}
              </div>
              {userName}
            </div>
          </div>
        </div>
      )}
      {currentLoanApplicationId && showLoanDetails ? (
        <LoanDetailsPage />
      ) : currentLoanApplicationId && showPaymentSchedule ? (
        <LoanPaymentScheduleForm />
      ) : currentLoanApplicationId && showPaymentHistory ? (
        <LoanPaymentHistoryDashboard />
      ) : showTransactionHistory ? (
        <PosRevenueBasedLoanTransactions />
      ) : currentPaymentId && showPaymentDetails ? (
        <LoanPaymentDetailsDashboard />
      ) : currentLoanApplicationId && showDrawdownForm ? (
        <Drawdown />
      ) : currentLoanApplicationId && showDrawdownSuccess ? (
        <DrawdownSuccess />
      ) : (
        <LoanDashboardForm />
      )}
      {!settings.hideFooter && !showDrawdownSuccess && (
        <div className="cx-w-full cx-p-4">
          <Footer text={info.footer} />
        </div>
      )}
    </div>
  );
};

export default LoanDashboardLayout;
