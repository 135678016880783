import { Fragment, useEffect, useState } from "react";
import { Accordion, Divider, Tabs } from "../../components";
import FixedTermLoanCalculator from "./FixedTermLoanCalculator";
import InvoiceDiscountingLoanCalculator from "./InvoiceDiscountingLoanCalculator";
import { useLoanApplication, useLoanCalculator } from "../../contexts";
import { LoanDurationType, LoanProductType } from "../../hooks/api/types";
import { useDpConfig } from "../../contexts/dpConfig";
import { useLoanMetrics } from "../../hooks";
import TermsAndConditionsModal from "./TermsAndConditionsModal";
import PrivacyPolicyModal from "./PrivacyPolicyModal";
import PayableFinancingLoanCalculator from "./PayableFinancingLoanCalculator";
import RevenueBasedLoanCalculator from "./RevenueBasedLoanCalculator";
import PosRevenueBasedLoanCalculator from "./PosRevenueBasedLoanCalculator";
import BuyNowPayLaterCalculator from "./BuyNowPayLaterCalculator";
import { LoanProduct } from "../../contexts/dpConfig/types";
import LocInvoiceDiscountingCalculator from "./LocInvoiceDiscountingCalculator";
import LocPayableFinancingCalculator from "./LocPayableFinancingLoanCalculator";

type FAQ = {
  label: string;
  content: string | JSX.Element;
};

const PAGE_COPY: {
  [key: string]: {
    default: string;
    repayableByDP: string;
    lineOfCredit: string;
  };
} = {
  TITLE: {
    default: "Loan Calculator",
    repayableByDP: "Payment calculator",
    lineOfCredit: "How much financial support do you need?",
  },
  SUBTITLE: {
    default:
      "Customize loan to your specific financial needs and repayment preference.",
    repayableByDP: "See what you can get paid for your invoice",
    lineOfCredit: "",
  },
};

function getCopyText(
  key: string,
  repayableByDPFlag?: boolean,
  lineOfCredit?: boolean
) {
  if (lineOfCredit) return PAGE_COPY[key]["lineOfCredit"];
  return PAGE_COPY[key][repayableByDPFlag ? "repayableByDP" : "default"];
}

const defaultFAQS: FAQ[] = [
  {
    label: "How does it work?",
    content:
      "We have a simple, online application form that takes around 10 minutes to complete. You will need to have some information ready for the application.",
  },
  {
    label: "How do I know if I am eligible?",
    content:
      "The first step in the application process checks your eligibility. This step takes a couple of minutes.",
  },
  {
    label: "How long does it take to get the loan?",
    content:
      "You will receive a pre-approval typically within 24 hours. Following the signature of the loan contract we will send the funds to your account within 24 hours. Please note that bank transfers in the UAE can sometimes take 2-3 business days to clear.",
  },
  {
    label: "What are the fees?",
    content:
      "There is an upfront processing fee of 1.5% to cover the costs of your application. This fee is only paid if you go receive the loan. The fee is deducted from the loan amount at the time of transfer.",
  },
  {
    label: "What is CredibleX?",
    content: (
      <p>
        CredibleX is a lending company, licensed and regulated by the Abu Dhabi
        Global Market in the United Arab Emirates with registration number
        220134. Find out more about Abu Dhabi Global Market financial centre
        through{" "}
        <a
          href="https://www.adgm.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="cx-text-brand-primary-regular cx-underline"
        >
          here
        </a>
        .
      </p>
    ),
  },
  {
    label: "What information do I need to provide?",
    content: (
      <>
        <div>
          Please have the following documents and information ready to make your
          application as smooth as possible;
        </div>
        <ul className="!cx-list-disc">
          <li>• Valid company trade license</li>
          <li>• Memorandum of Association</li>
          <li>
            • Valid Power of Attorney (for companies with more than one
            shareholder)
          </li>
          <li>• Emirates ID of the applicant</li>
          <li>
            • Names and email addresses of all shareholders owning 25% or more
            of the company - these shareholders will need need to undergo online
            compliance checks using their Emirates ID
          </li>
          <li>• Last 4 quarters of VAT statements (if applicable)</li>
          <li>
            • Corporate bank account login details, or 6 months of bank
            statements
          </li>
        </ul>
        <div>
          Still have questions? Contact us on{" "}
          <a
            className="cx-text-brand-primary-regular cx-underline"
            href="mailto:support@crediblex.io"
          >
            support@crediblex.io
          </a>{" "}
          and our friendly team are standing by to help!{" "}
        </div>
      </>
    ),
  },
];

const LoanCalculator = () => {
  const [termsModalOpen, setTermsModalOpen] = useState(false);
  const [privacyModalOpen, setPrivacyModalOpen] = useState(false);
  const [repayableByDP, setRepayableByDP] = useState(false);
  const [startingTab, setStartingTab] = useState(0);
  const [FAQS, setFAQS] = useState<FAQ[]>();
  const [
    invoiceDiscountingRepayableByDPFlag,
    setInvoiceDiscountingRepayableByDPFlag,
  ] = useState(false);
  const [
    payableFinancingRepayableByDPFlag,
    setPayableFinancingRepayableByDPFlag,
  ] = useState(false);

  const {
    actions: {
      setFixedTermLoan,
      setPayableFinancingLoan,
      setLocPayableFinancingLoan,
      setInvoiceDiscountingLoan,
      setLocInvoiceDiscountingLoan,
      setRevenueBasedLoan,
      setPosRevenueBasedLoan,
      setBuyNowPayLaterLoan,
    },
    state: {
      fixedTermLoan,
      payableFinancingLoan,
      locPayableFinancingLoan,
      invoiceDiscountingLoan,
      locInvoiceDiscountingLoan,
      revenueBasedLoan,
      posRevenueBasedLoan,
      buyNowPayLaterLoan,
      constraints,
    },
  } = useLoanCalculator();

  const {
    state: { products, settings, uiLocalizationData },
  } = useDpConfig();
  const {
    state: { loading, loanType },
    actions: { update },
  } = useLoanApplication();

  const [startingType, setStartingType] = useState<LoanProductType | null>(
    loanType || null
  );

  const { invoiceFinanceProduct, payableFinancingProduct } = useLoanMetrics();

  // This use effect sets the starting tab based on the loan type
  useEffect(() => {
    if (startingType) {
      const index = products.findIndex(
        (product) => product.type === startingType
      );
      const fallbackIndex = index === -1 ? 0 : index;
      setStartingTab(fallbackIndex);
    }
  }, [startingType, products]);

  useEffect(() => {
    const updateLoanState = (product: LoanProduct) => {
      const commonLoanData = {
        amount:
          product.config?.amount?.initial || product?.config?.amount?.min || 0,
        duration:
          product.config?.duration?.initial ||
          product.config?.duration?.min ||
          0,
        durationType: product.config?.duration?.unit as LoanDurationType,
        interestRate: product.config?.interestRate || 0,
      };

      switch (product.type) {
        case LoanProductType.FIXED_TERM:
          setFixedTermLoan({ ...fixedTermLoan, ...commonLoanData });
          break;

        case LoanProductType.INVOICE_DISCOUNTING:
          setInvoiceDiscountingLoan({
            ...invoiceDiscountingLoan,
            invoices: [
              {
                ...commonLoanData,
                invoiceId: "",
                discountRate:
                  constraints?.invoiceDiscountingLoan?.discount?.initial || 90,
                invoiceNo: "",
                invoiceDueDate: new Date(),
                invoiceIssuedDate: new Date(),
                vendorName: "",
              },
            ],
          });
          break;

        case LoanProductType.PAYABLE_FINANCING:
          setPayableFinancingLoan({
            ...payableFinancingLoan,
            invoices: [
              {
                ...commonLoanData,
                invoiceId: "",
                discountRate:
                  constraints.payableFinancingLoan.discount.initial || 90,
                invoiceNo: "",
                invoiceDueDate: new Date(),
                invoiceIssuedDate: new Date(),
                vendorName: "",
              },
            ],
          });
          break;

        case LoanProductType.BNPL:
          setBuyNowPayLaterLoan({ ...buyNowPayLaterLoan, ...commonLoanData });
          break;

        case LoanProductType.POS_REVENUE_BASED_FINANCING:
          setPosRevenueBasedLoan({
            ...posRevenueBasedLoan,
            amount: 300000,
            duration: 6,
            durationType: product.config?.duration?.unit as LoanDurationType,
            factorRate: 1.2,
          });
          break;

        default:
          const configData = constraints.userConstraintsConfig
            ? constraints.revenueBasedLoan
            : product.config;

          setRevenueBasedLoan({
            ...revenueBasedLoan,
            amount: configData.amount.initial || 0,
            duration: configData.duration.initial || 0,
            durationType: configData.duration?.unit as LoanDurationType,
            interestRate: configData.interestRate || 0,
          });
      }
    };

    const productToUpdate =
      products.length === 1
        ? products[0]
        : products.find((product) => product.type === loanType) || products[0];

    updateLoanState(productToUpdate);
  }, [products, loanType]);

  useEffect(() => {
    setStartingType(loanType || LoanProductType.FIXED_TERM);
    console.log("Loan Type: ", loanType);
  }, [loanType]);

  useEffect(() => {
    setInvoiceDiscountingRepayableByDPFlag(
      invoiceFinanceProduct?.flags?.repayableByDP
    );
    setPayableFinancingRepayableByDPFlag(
      payableFinancingProduct?.flags?.repayableByDP
    );
  }, [invoiceFinanceProduct, payableFinancingProduct]);

  useEffect(() => {
    setRepayableByDP(
      invoiceDiscountingRepayableByDPFlag || payableFinancingRepayableByDPFlag
    );
  }, [
    invoiceDiscountingRepayableByDPFlag,
    payableFinancingRepayableByDPFlag,
    settings,
  ]);

  useEffect(() => {
    if (uiLocalizationData) {
      if (uiLocalizationData.loanCalculatorScreen.faqs == null) {
        setFAQS([]);
      } else if (uiLocalizationData.loanCalculatorScreen.faqs.length == 0) {
        setFAQS(defaultFAQS);
      } else {
        var faqs: FAQ[] = [];
        uiLocalizationData.loanCalculatorScreen.faqs.map((faq) => {
          faqs.push({
            label: faq.question,
            content: faq.answer,
          });
        });

        setFAQS(faqs);
      }
    }
  }, [uiLocalizationData]);

  const updateLoanTypeInLocalContext = async (loanType: LoanProductType) => {
    await update(
      {
        loanType: loanType,
      },
      {
        local: true,
      }
    );
  };

  const getCalculatorComponent = (product: LoanProduct) => {
    switch (product.type) {
      case LoanProductType.FIXED_TERM:
        return <FixedTermLoanCalculator />;
      case LoanProductType.INVOICE_DISCOUNTING:
        return <InvoiceDiscountingLoanCalculator />;
      case LoanProductType.PAYABLE_FINANCING:
        return <PayableFinancingLoanCalculator />;
      case LoanProductType.BNPL:
        return <BuyNowPayLaterCalculator />;
      case LoanProductType.POS_REVENUE_BASED_FINANCING:
        return <PosRevenueBasedLoanCalculator />;
      case LoanProductType.LOC_INVOICE_DISCOUNTING:
        return <LocInvoiceDiscountingCalculator />;
      case LoanProductType.LOC_PAYABLE_FINANCING:
        return <LocPayableFinancingCalculator />;
      default:
        return <RevenueBasedLoanCalculator />;
    }
  };

  const renderCalculator = () => {
    if (products.length === 1) {
      const [product] = products;
      return <div className="cx-mt-6">{getCalculatorComponent(product)}</div>;
    }

    if (!settings.allowSmeToChooseProduct) {
      const product = products[startingTab];
      return <div className="cx-mt-6">{getCalculatorComponent(product)}</div>;
    }

    return (
      loading ||
      (products.length > 1 && (
        <Tabs
          tabs={products.map((product) => ({
            label: product.displayName,
            component: (
              <div className="cx-flex cx-flex-col cx-content-center cx-mt-6">
                {product.type === LoanProductType.FIXED_TERM ? (
                  <div className="cx-flex cx-flex-col cx-items-center">
                    <div className="cx-text-text-secondary cx-text-center cx-max-w-xl cx-mb-4">
                      Pay any vendor directly for business needs like taxes,
                      insurance and supplier without tapping into your cash
                      reserves.
                    </div>
                    <FixedTermLoanCalculator />
                  </div>
                ) : product.type === LoanProductType.INVOICE_DISCOUNTING ? (
                  <InvoiceDiscountingLoanCalculator />
                ) : product.type === LoanProductType.PAYABLE_FINANCING ? (
                  <PayableFinancingLoanCalculator />
                ) : product.type === LoanProductType.BNPL ? (
                  <BuyNowPayLaterCalculator />
                ) : product.type ==
                  LoanProductType.POS_REVENUE_BASED_FINANCING ? (
                  <PosRevenueBasedLoanCalculator />
                ) : product.type === LoanProductType.LOC_INVOICE_DISCOUNTING ? (
                  <LocInvoiceDiscountingCalculator />
                ) : product.type === LoanProductType.LOC_PAYABLE_FINANCING ? (
                  <LocPayableFinancingCalculator />
                ) : (
                  <RevenueBasedLoanCalculator />
                )}
              </div>
            ),
            onTabClicked: () => {
              if (product.type === LoanProductType.FIXED_TERM) {
                setFixedTermLoan({ ...fixedTermLoan });
              } else if (product.type === LoanProductType.INVOICE_DISCOUNTING) {
                setInvoiceDiscountingLoan({ ...invoiceDiscountingLoan });
              } else if (product.type === LoanProductType.PAYABLE_FINANCING) {
                setPayableFinancingLoan({ ...payableFinancingLoan });
              } else if (product.type === LoanProductType.BNPL) {
                setBuyNowPayLaterLoan({ ...buyNowPayLaterLoan });
              } else if (
                product.type === LoanProductType.POS_REVENUE_BASED_FINANCING
              ) {
                setPosRevenueBasedLoan({ ...posRevenueBasedLoan });
              } else if (
                product.type === LoanProductType.LOC_INVOICE_DISCOUNTING
              ) {
                setLocInvoiceDiscountingLoan({ ...locInvoiceDiscountingLoan });
              } else if (
                product.type === LoanProductType.LOC_PAYABLE_FINANCING
              ) {
                setLocPayableFinancingLoan({ ...locPayableFinancingLoan });
              } else {
                setRevenueBasedLoan({ ...revenueBasedLoan });
              }
              updateLoanTypeInLocalContext(product.type);
            },
          }))}
          startingTab={startingTab}
        />
      ))
    );
  };

  return (
    <div className="cx-grow cx-flex cx-flex-col cx-items-center cx-justify-center cx-w-full cx-p-8 cx-max-w-[960px]">
      <div className="cx-flex cx-flex-col cx-justify-center cx-items-center cx-gap-2 cx-my-10">
        <div className="cx-text-2xl cx-font-bold cx-text-center">
          {uiLocalizationData.loanCalculatorScreen?.title ||
            getCopyText("TITLE", repayableByDP)}
        </div>
        <div className="cx-text-text-secondary cx-text-center">
          {uiLocalizationData.loanCalculatorScreen?.subtitle ||
            getCopyText("SUBTITLE", repayableByDP)}
        </div>
      </div>
      <div>{renderCalculator()}</div>
      <div className="cx-mt-6 cx-text-text-secondary cx-font-normal cx-text-sm">
        *Depends on your loan application and eligibility
      </div>
      {repayableByDP && (
        <div className="cx-text-text-secondary cx-font-normal cx-text-sm cx-text-center">
          By clicking the <span className="cx-font-bold">Apply now</span>{" "}
          button, you acknowledge and agree to be bound by CredibleX's{" "}
          <span
            className={"cx-text-text-brand hover:cx-cursor-pointer"}
            onClick={() => setTermsModalOpen(true)}
          >
            Terms & Conditions
          </span>
          ,{" "}
          <span
            className={"cx-text-text-brand hover:cx-cursor-pointer"}
            onClick={() => setPrivacyModalOpen(true)}
          >
            Privacy Policy
          </span>
        </div>
      )}
      {!repayableByDP && FAQS && FAQS.length > 0 && (
        <div className="cx-mt-20  cx-w-full">
          <div className="cx-font-bold cx-text-3xl cx-text-center cx-mb-4">
            Have a question?
          </div>
          {FAQS.map((faq, index) => (
            <Fragment key={index}>
              <Accordion heading={faq.label}>{faq.content}</Accordion>
              {index !== FAQS.length - 1 && (
                <div className="cx-my-6">
                  <Divider />
                </div>
              )}
            </Fragment>
          ))}
        </div>
      )}
      {repayableByDP && (
        <>
          <TermsAndConditionsModal
            isOpen={termsModalOpen}
            onClose={() => setTermsModalOpen(false)}
          />
          <PrivacyPolicyModal
            isOpen={privacyModalOpen}
            onClose={() => setPrivacyModalOpen(false)}
          />
        </>
      )}
    </div>
  );
};

export default LoanCalculator;
