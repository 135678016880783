import { StepConfig } from "../../contexts";
import LoanCalculator from "../LoanCalculator/LoanCalculator";
import { BusinessInformationForm } from "./BusinessInformation";
import {
  OTPForm,
  PhoneNumberForm,
  EmailForm,
  PreInformationForm,
} from "./Onboarding";
import { OwnersInformationForm } from "./OwnersInformation";
import ApplicationReview from "./Review/ApplicationReview";
import SuccessScreen from "./Success";
import ShareholdersInformation from "./OwnersInformation/ShareholderInformation";

import InitialPage from "./Onboarding/InitialPage";
import SelectCompanyProfile from "./CompanyProfile/SelectCompanyProfile";
import OwnersKycForm from "./OwnersInformation/OwnersKycForm";
import ManualAccounts from "./FinancialInformation/ManualAccounts";
import LoanReasons from "./Onboarding/LoanReasons";
import LoanTypeSelection from "./Onboarding/LoanTypeSelection";
import { MerchantIDInputForm } from "./MerchantIDStep/MerchantIDInputForm";
import { UploadDocuments } from "./UploadDocuments/UploadDocuments";
import VendorInformation from "./VendorInformation/VendorInformation";

export enum StepName {
  BUSINESS_INFORMATION = "Business Information",
  OWNERS_INFORMATION = "Owners Information",
  FINANCIAL_INFORMATION = "Financial Information",
  LOAN_INFORMATION = "Loan Information",
  APPLICATION_REVIEW = "Application Review",
  LOAN_CALCULATOR = "Loan Calculator",
  SUCCESS = "Success",
  UPLOAD_DOCUMENTS = "Upload Documents",
  VENDOR_INFORMATION = "Vendor Information",
}

export enum SubStepName {
  SELECT_COMPANY_PROFILE = "Select Company Profile",
  INITIAL_PAGE = "Initial Page",
  LOAN_REASON = "Loan Reason",
  LOAN_TYPE = "Loan Type",
  LOAN_CALCULATOR = "Loan Calculator",
  UPLOAD_INVOICE = "Upload invoice",
  UPLOAD_SALES_STATEMENT = "Upload sales statement",
  UPLOAD_POS_SALES_STATEMENT = "Upload POS sales statement",
  UPLOAD_SALES_INVOICE = "Upload sales invoice",
  PRE_APPLICATION_INFORMATION = "Pre-application Information",
  COMPANY_INFORMATION_START_SCREEN = "Compaby Information Start Screen",
  PHONE_NUMBER = "Phone Number",
  OTP_FORM = "OTP Form",
  EMAIL_FORM = "Email Form",
  BUSINESS_INFORMATION_START = "Business Information Start Screen",
  BUSINESS_INFORMATION = "Business Information",
  COMPANY_INFORMATION_START = "Company Information Start Screen",
  BUSINESS_DETAILS = "Business Details",
  DOCUMENTS_UPLOAD = "Documents Upload",
  OWNERS_INFORMATION_START = "Owners Information Start Screen",
  OWNERS_INFORMATION = "Owners Information",
  SHAREHOLDERS_INFORMATION = "Shareholders Information",
  OWNERS_KYC = "Owners KYC",
  FINANCIAL_INFORMATION_START = "Financial Information Start Screen",
  FINANCE_INFORMATION_SELECT = "Finance Information Select",
  FINANCE_INFORMATION = "Finance Information",
  VAT_INFORMATION = "VAT Information",
  ADDITIONAL_FINANCIAL_DOCUMENT = "Additional Financial Document",
  ADDITIONAL_INFORMATION = "Additional Information",
  Manual_Accounts = "Manual Accounts",
  APPLICATION_REVIEW = "Application Review",
  SUCCESS = "Success",
  MASTERCARD_MERCHANTID = "Mastercard Merchant ID",
  UPLOAD_DOCUMENTS = "Upload Documents",
  VENDOR_INFORMATION = "Vendor Information",
}

const selectCompanyProfile = {
  name: SubStepName.SELECT_COMPANY_PROFILE,
  component: <SelectCompanyProfile />,
  metadata: { disableBackroundGradient: true },
};

const initialPage = {
  name: SubStepName.INITIAL_PAGE,
  component: <InitialPage />,
  metadata: { disableBackroundGradient: true },
};

const loanReasons = {
  name: SubStepName.LOAN_REASON,
  component: <LoanReasons />,
  metadata: { disableBackroundGradient: true, hasIllustrations: true },
};

const loanType = {
  name: SubStepName.LOAN_TYPE,
  component: <LoanTypeSelection />,
  metadata: { disableBackroundGradient: true, hasIllustrations: true },
};

const loanCalculator = {
  name: SubStepName.LOAN_CALCULATOR,
  component: <LoanCalculator />,
  metadata: { disableBackroundGradient: true, hasIllustrations: false },
};

const preApplicationInfo = {
  name: SubStepName.PRE_APPLICATION_INFORMATION,
  component: <PreInformationForm />,
  metadata: { disableBackroundGradient: true, hasIllustrations: true },
};

const phoneNumber = {
  name: SubStepName.PHONE_NUMBER,
  component: <PhoneNumberForm />,
};
const otpForm = { name: SubStepName.OTP_FORM, component: <OTPForm /> };
const emailForm = { name: SubStepName.EMAIL_FORM, component: <EmailForm /> };

export const buildStepConfig = (moveCalculateAtStart: boolean): StepConfig => {
  const steps: Record<StepName, StepConfig[number]> = {
    [StepName.LOAN_CALCULATOR]: {
      name: StepName.LOAN_CALCULATOR,
      subSteps: moveCalculateAtStart
        ? [
            selectCompanyProfile,
            initialPage,
            loanReasons,
            loanType,
            loanCalculator,
          ]
        : [loanCalculator],
    },
    [StepName.LOAN_INFORMATION]: {
      name: StepName.LOAN_INFORMATION,
      subSteps: moveCalculateAtStart
        ? [preApplicationInfo, phoneNumber, otpForm, emailForm]
        : [selectCompanyProfile, initialPage, phoneNumber, otpForm, emailForm],
    },
    [StepName.BUSINESS_INFORMATION]: {
      name: StepName.BUSINESS_INFORMATION,
      subSteps: [
        {
          name: SubStepName.BUSINESS_INFORMATION,
          component: <BusinessInformationForm />,
        },
      ],
    },
    [StepName.OWNERS_INFORMATION]: {
      name: StepName.OWNERS_INFORMATION,
      subSteps: [
        {
          name: SubStepName.OWNERS_INFORMATION,
          component: <OwnersInformationForm />,
        },
        {
          name: SubStepName.SHAREHOLDERS_INFORMATION,
          component: <ShareholdersInformation />,
        },
        { name: SubStepName.OWNERS_KYC, component: <OwnersKycForm /> },
      ],
    },
    [StepName.FINANCIAL_INFORMATION]: {
      name: StepName.FINANCIAL_INFORMATION,
      subSteps: [
        // {
        //   name: SubStepName.ADDITIONAL_INFORMATION,
        //   component: <MoreInformation />,
        // },
        { name: SubStepName.Manual_Accounts, component: <ManualAccounts /> },
        {
          name: SubStepName.MASTERCARD_MERCHANTID,
          component: <MerchantIDInputForm />,
        },
      ],
    },
    [StepName.UPLOAD_DOCUMENTS]: {
      name: StepName.UPLOAD_DOCUMENTS,
      subSteps: [
        { name: SubStepName.UPLOAD_DOCUMENTS, component: <UploadDocuments /> },
      ],
    },
    [StepName.VENDOR_INFORMATION]: {
      name: StepName.VENDOR_INFORMATION,
      subSteps: [
        {
          name: SubStepName.VENDOR_INFORMATION,
          component: <VendorInformation />,
        },
      ],
    },
    [StepName.APPLICATION_REVIEW]: {
      name: StepName.APPLICATION_REVIEW,
      subSteps: [
        {
          name: SubStepName.APPLICATION_REVIEW,
          component: <ApplicationReview />,
        },
      ],
    },
    [StepName.SUCCESS]: {
      name: StepName.SUCCESS,
      hideProgress: true,
      subSteps: [{ name: SubStepName.SUCCESS, component: <SuccessScreen /> }],
    },
  };

  const stepsOrder = moveCalculateAtStart
    ? [
        StepName.LOAN_CALCULATOR,
        StepName.LOAN_INFORMATION,
        StepName.BUSINESS_INFORMATION,
        StepName.OWNERS_INFORMATION,
        StepName.FINANCIAL_INFORMATION,
        StepName.UPLOAD_DOCUMENTS,
        StepName.VENDOR_INFORMATION,
        StepName.APPLICATION_REVIEW,
        StepName.SUCCESS,
      ]
    : [
        StepName.LOAN_INFORMATION,
        StepName.BUSINESS_INFORMATION,
        StepName.LOAN_CALCULATOR,
        StepName.OWNERS_INFORMATION,
        StepName.FINANCIAL_INFORMATION,
        StepName.UPLOAD_DOCUMENTS,
        StepName.VENDOR_INFORMATION,
        StepName.APPLICATION_REVIEW,
        StepName.SUCCESS,
      ];

  return [...stepsOrder.map((stepName) => steps[stepName])];
};
