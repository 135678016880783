import { FC, useMemo, useState } from "react";
import { FormikErrors, FormikProps } from "formik";
import clsx from "clsx";

import { InfoIcon, TrashIcon } from "../../../assets/icons";
import { Checkbox, DatePicker, InputField } from "../../../components";
import { DrawdownFields } from "../../LoanCalculator/service";
import { useLoanDashboard } from "../../../contexts";
import { LoanProductType } from "../../../hooks/api/types";
import DeleteInvoiceModal from "./DeleteInvoiceModal";
import { useLoanMetrics } from "../../../hooks";

type InvoicesListProps = {
  availableLimit: number;
  formik: FormikProps<DrawdownFields>;
};

export const InvoicesList: FC<InvoicesListProps> = ({ formik }) => {
  const {
    values,
    errors,
    touched,
    getFieldProps,
    setFieldTouched,
    setFieldValue,
  } = formik;
  const {
    state: { currentLoanApplication },
  } = useLoanDashboard();

  const {
    locInvoiceFinanceProduct: {
      config: { discount, amount },
    },
  } = useLoanMetrics();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const totalAmount = useMemo(() => {
    return values.invoices.reduce(
      (sum, inv) => sum + Number(inv.requiredAmount || 0),
      0
    );
  }, [values?.invoices]);

  const handleAddInvoice = () => {
    if (values.invoices.length >= 10) {
      formik.setErrors({
        ...errors,
        invoices: "You cannot add more than 10 invoices.",
      });
      return;
    }

    setFieldValue("invoices", [
      ...values.invoices,
      {
        invoiceNumber: "",
        invoiceAmount: undefined,
        requiredAmount:
          currentLoanApplication?.loanType ===
          LoanProductType.LOC_INVOICE_DISCOUNTING
            ? amount.max.toString()
            : "",
        issuedDate: undefined,
        dueDate: undefined,
        selected: false,
      },
    ]);
  };

  const handleInvoiceAmountBlur = (index: number) => {
    setFieldTouched(`invoices.${index}.invoiceAmount`);

    if (
      currentLoanApplication?.loanType !==
      LoanProductType.LOC_INVOICE_DISCOUNTING
    ) {
      const invoiceAmount = values.invoices[index].invoiceAmount;

      if (invoiceAmount) {
        const numericAmount = Number(invoiceAmount);
        setFieldValue(`invoices.${index}.requiredAmount`, numericAmount);
      }
    }
  };

  const handleDeleteSelected = () => {
    if (values.invoices.some((invoice) => invoice.selected)) {
      setIsDeleteModalOpen(true);
    }
  };

  const confirmDelete = () => {
    setFieldValue(
      "invoices",
      values.invoices.filter((invoice) => !invoice.selected)
    );
  };

  const handleCheckboxChange = (index: number) => {
    const updatedInvoices = [...values.invoices];
    updatedInvoices[index] = {
      ...updatedInvoices[index],
      selected: !updatedInvoices[index].selected,
    };

    setFieldValue("invoices", updatedInvoices);
  };

  return (
    <div className="cx-bg-white cx-rounded-lg cx-p-6 cx-mb-4 cx-shadow-base">
      <div className="cx-flex cx-justify-between cx-mb-2 ">
        <h2 className="cx-text-xl cx-font-semibold">List of invoices</h2>
        <button
          onClick={handleDeleteSelected}
          disabled={!values.invoices.some((invoice) => invoice.selected)}
          className={clsx(
            "cx-rounded-full cx-border cx-border-gray-200 cx-p-2 cx-transition-colors",
            {
              "cx-text-gray-400 cx-cursor-not-allowed": !values.invoices.some(
                (invoice) => invoice.selected
              ),
              "cx-bg-red-500 cx-text-white": values.invoices.some(
                (invoice) => invoice.selected
              ),
            }
          )}
        >
          <TrashIcon className="cx-w-4 cx-h-4" />
        </button>
      </div>
      {values.invoices.map((invoice: any, index: number) => {
        const invoiceTouched = touched.invoices?.[index] || {};
        const invoiceErrors = errors.invoices
          ? typeof errors.invoices === "string"
            ? {}
            : (errors.invoices as FormikErrors<typeof values.invoices>)?.[
                index
              ] || {}
          : {};
        return (
          <div
            key={index}
            className="cx-grid cx-grid-cols-[1fr_1fr_1fr_1fr_1fr_10px] cx-gap-3 cx-mb-4 cx-items-center"
          >
            <div className="cx-relative cx-w-full">
              <InputField
                label="Invoice no"
                inputProps={{
                  ...getFieldProps(`invoices.${index}.invoiceNumber`),
                  type: "text",
                  onBlur: () =>
                    setFieldTouched(`invoices.${index}.invoiceNumber`),
                }}
                error={
                  invoiceTouched.invoiceNumber && invoiceErrors.invoiceNumber
                    ? String(invoiceErrors.invoiceNumber)
                    : ""
                }
                tick={
                  invoiceTouched.invoiceNumber && !invoiceErrors.invoiceNumber
                }
                className="cx-placeholder:text-medium cx-placeholder:text-gray-400"
              />
            </div>

            <div className="cx-relative cx-w-full">
              <InputField
                label="Invoice amount"
                inputProps={{
                  ...getFieldProps(`invoices.${index}.invoiceAmount`),
                  type: "text",
                  onBlur: () => handleInvoiceAmountBlur(index),
                }}
                error={
                  invoiceTouched.invoiceAmount && invoiceErrors.invoiceAmount
                    ? String(invoiceErrors.invoiceAmount)
                    : ""
                }
                tick={
                  invoiceTouched.invoiceAmount && !invoiceErrors.invoiceAmount
                }
                className="cx-placeholder:text-medium cx-placeholder:text-gray-400"
                prepend={
                  getFieldProps(`invoices.${index}.invoiceAmount`).value ? (
                    <span className="cx-pt-4 cx-text-text-primary">
                      {values.currency || "AED"}
                    </span>
                  ) : undefined
                }
              />
            </div>

            <div className="cx-relative cx-w-full">
              <InputField
                label="Required amount"
                inputProps={{
                  ...getFieldProps(`invoices.${index}.requiredAmount`),
                  type: "text",
                  onBlur: () =>
                    setFieldTouched(`invoices.${index}.requiredAmount`),
                }}
                error={
                  invoiceTouched.requiredAmount && invoiceErrors.requiredAmount
                    ? String(invoiceErrors.requiredAmount)
                    : ""
                }
                tick={
                  invoiceTouched.requiredAmount && !invoiceErrors.requiredAmount
                }
                className="cx-placeholder:text-medium cx-placeholder:text-gray-400"
                prepend={
                  getFieldProps(`invoices.${index}.requiredAmount`).value ? (
                    <span className="cx-pt-4 cx-text-text-primary">
                      {values.currency || "AED"}
                    </span>
                  ) : undefined
                }
              />
            </div>

            <div className="cx-relative cx-w-full">
              <DatePicker
                selectedDate={values.invoices[index].issuedDate}
                onChange={(date) =>
                  setFieldValue(`invoices.${index}.issuedDate`, date)
                }
                onBlur={() => setFieldTouched(`invoices.${index}.issuedDate`)}
                placeholderText="Issued date"
                showDatePickerIcon={false}
                textAlignment="left"
                tick={invoiceTouched.issuedDate && !invoiceErrors.issuedDate}
                error={
                  invoiceTouched.issuedDate && invoiceErrors.issuedDate
                    ? String(invoiceErrors.issuedDate)
                    : ""
                }
              />
            </div>

            <div className="cx-relative cx-w-full">
              <DatePicker
                selectedDate={values.invoices[index].dueDate}
                onChange={(date) =>
                  setFieldValue(`invoices.${index}.dueDate`, date)
                }
                onBlur={() => setFieldTouched(`invoices.${index}.dueDate`)}
                placeholderText="Due date"
                showDatePickerIcon={false}
                textAlignment="left"
                tick={invoiceTouched.dueDate && !invoiceErrors.dueDate}
                error={invoiceTouched.dueDate ? invoiceErrors.dueDate : ""}
              />
            </div>

            <div className="cx-flex cx-justify-center cx-items-center ">
              <Checkbox
                label=""
                variant="rounded"
                checked={values.invoices[index]?.selected || false}
                onChange={() => handleCheckboxChange(index)}
              />
            </div>
          </div>
        );
      })}
      <button
        onClick={handleAddInvoice}
        className="cx-text-blue-600 cx-flex cx-items-center cx-mb-6"
      >
        + Add another invoice
      </button>
      <div className="cx-border-t cx-border-gray-200 cx-my-5"></div>
      <div className="cx-grid cx-grid-cols-2 cx-gap-4 cx-mb-4">
        <div className="cx-relative cx-w-full">
          <InputField
            label="Total drawdown amount"
            inputProps={{
              ...getFieldProps(`totalAmount`),
              name: "totalAmount",
              onBlur: () => setFieldTouched("totalAmount"),
              value: totalAmount,
            }}
            error={touched.totalAmount ? errors.totalAmount : ""}
            tick={touched.totalAmount && !errors.totalAmount}
            inputClassName=" cx-bg-background-secondary cx-text-gray-600"
            readOnly={true}
          />
        </div>{" "}
        <div className="cx-relative cx-w-full">
          <InputField
            label="Number of invoices"
            inputProps={{
              ...getFieldProps(`numberOfInvoices`),
              name: "numberOfInvoices",
              onBlur: () => setFieldTouched("numberOfInvoices"),
              value:
                values.invoices.filter((invoice) =>
                  Object.values(invoice).every(
                    (v) => v !== "" && v !== null && v !== undefined
                  )
                ).length || "",
            }}
            error={touched.numberOfInvoices ? errors.numberOfInvoices : ""}
            tick={touched.numberOfInvoices && !errors.numberOfInvoices}
            inputClassName=" cx-bg-background-secondary cx-text-gray-600"
            readOnly={true}
          />
        </div>
      </div>
      {(currentLoanApplication?.loanProduct.type as LoanProductType) ===
        LoanProductType.LOC_INVOICE_DISCOUNTING &&
        discount.max && (
          <div className="cx-flex cx-items-center cx-text-sm">
            <InfoIcon width={20} height={16} className="cx-text-blue-600" />
            <p className="cx-ml-1  cx-text-gray-500">
              We will provide discount on invoice maximum of{" "}
              <span className="cx-text-blue-600">{discount.max}%</span>
            </p>
          </div>
        )}
      <DeleteInvoiceModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={confirmDelete}
      />
    </div>
  );
};

export default InvoicesList;
