import { FC, useEffect, useState } from "react";
import { useGetKycAccessToken } from "../../../hooks";
import { useLoanApplication } from "../../../contexts";
import { ShareholderKYCApplicationStatus } from "../../../hooks/api/types";
import SumSubKycView from "../../../components/SumSubKycView/SumSubKycView";

type Props = {
  onSubmit: () => void;
};

const SumSubKyc: FC<Props> = ({ onSubmit }) => {
  const [accessToken, setAccessToken] = useState<string>();

  const {
    state: { applicant },
    actions: { update, refetch },
  } = useLoanApplication();

  const { refetch: getAccessToken } = useGetKycAccessToken(false);

  const handleGetAccessToken = async () => {
    const {
      data: { kycAccessToken },
    } = await getAccessToken(applicant?.kycApplication?.id);
    setAccessToken(kycAccessToken.token);
  };

  const handleApplicantSubmitted = async () => {
    await update({
      applicant: {
        ...applicant,
        kycApplication: {
          status: ShareholderKYCApplicationStatus.IN_PROGRESS,
        },
      },
    });
    await refetch();
  };

  useEffect(() => {
    if (applicant?.id && applicant?.id !== "") {
      handleGetAccessToken().catch((err) => console.log(err));
    }
  }, [applicant?.id]);

  return (
    <>
      {accessToken && (
        <SumSubKycView
          accessToken={accessToken}
          nextHandler={onSubmit}
          handleGetAccessToken={handleGetAccessToken}
          handleSumsubApplicantUpdate={handleApplicantSubmitted}
          handleRefetch={refetch}
        />
      )}
    </>
  );
};

export default SumSubKyc;
