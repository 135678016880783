import { useMemo } from "react";
import { useLoanCalculator } from "../contexts";
import {
  calculateCompoundInterest,
  calculateMonthlyPaymentForBuyNowPayLater,
  calculateTotalPayment,
  calculateTotalPaymentForBuyNowPayLater,
  calculateTotalPaymentForPosRevenueBasedFinancing,
  calculateTotalPaymentForRecieveableFinancing,
} from "../utils";
import { LoanDurationType, LoanProductType } from "./api/types";
import { useDpConfig } from "../contexts/dpConfig";
import {
  LoanProduct,
  LoanProductConfig,
  LoanProductInvoiceConfig,
} from "../contexts/dpConfig/types";

type PayableFinancingProduct = LoanProduct & {
  config: LoanProductConfig & LoanProductInvoiceConfig;
  flags: {
    maxDiscount: boolean;
    fullDiscount: boolean;
    disableDiscount: boolean;
    repayableByDP: boolean;
  };
};

type InvoiceFinanceProduct = LoanProduct & {
  config: LoanProductConfig & LoanProductInvoiceConfig;
  flags: {
    maxDiscount: boolean;
    fullDiscount: boolean;
    disableDiscount: boolean;
    repayableByDP: boolean;
  };
};

function getDefaultInvoiceProductFlags(
  loanProduct: InvoiceFinanceProduct | PayableFinancingProduct
) {
  return {
    maxDiscount: loanProduct.config?.discount?.max === 100,
    fullDiscount:
      loanProduct.config?.discount?.min === 100 &&
      loanProduct.config?.discount?.max === 100,
    disableDiscount:
      loanProduct.config?.discount?.min !== loanProduct.config?.discount?.max &&
      loanProduct.config?.discount?.max !== 100,
    repayableByDP: !!loanProduct.config?.repayableByDP,
  };
}

type BusinessLoanProduct = LoanProduct;

type RevenueBasedLoanProduct = LoanProduct;

const useLoanMetrics = () => {
  const { state } = useLoanCalculator();
  const {
    state: { products },
  } = useDpConfig();

  const businessLoanProduct = useMemo(
    () =>
      products.find(
        (product) => product.type === LoanProductType.FIXED_TERM
      )! as BusinessLoanProduct,
    [products]
  );

  const revenueBasedLoanProduct = useMemo(
    () =>
      products.find(
        (product) => product.type === LoanProductType.REVENUE_BASED_FINANCING
      )! as RevenueBasedLoanProduct,
    [products]
  );

  const invoiceFinanceProduct = useMemo(() => {
    const product = products.find(
      (product) => product.type === LoanProductType.INVOICE_DISCOUNTING
    )! as InvoiceFinanceProduct;

    if (product) {
      product.flags = getDefaultInvoiceProductFlags(product);
    }

    return product;
  }, [products]);

  const locInvoiceFinanceProduct = useMemo(() => {
    const product = products.find(
      (product) => product.type === LoanProductType.LOC_INVOICE_DISCOUNTING
    )! as InvoiceFinanceProduct;

    if (product) {
      product.flags = getDefaultInvoiceProductFlags(product);
    }

    return product;
  }, [products]);

  const payableFinancingProduct = useMemo(() => {
    const product = products.find(
      (product) => product.type === LoanProductType.PAYABLE_FINANCING
    )! as InvoiceFinanceProduct;

    if (product) {
      product.flags = getDefaultInvoiceProductFlags(product);
    }
    return product;
  }, [products]);

  const locPayableFinancingProduct = useMemo(() => {
    const product = products.find(
      (product) => product.type === LoanProductType.LOC_PAYABLE_FINANCING
    )! as InvoiceFinanceProduct;

    if (product) {
      product.flags = getDefaultInvoiceProductFlags(product);
    }
    return product;
  }, [products]);

  const borrowedAmount = useMemo(() => {
    switch (state.type) {
      case LoanProductType.FIXED_TERM: {
        return state.fixedTermLoan?.amount;
      }
      case LoanProductType.INVOICE_DISCOUNTING: {
        const [invoice] = state.invoiceDiscountingLoan.invoices;
        return invoice?.amount * (invoice?.discountRate / 100);
      }
      case LoanProductType.PAYABLE_FINANCING: {
        const [invoice] = state.payableFinancingLoan.invoices;
        return invoice?.amount * (invoice?.discountRate / 100);
      }
      case LoanProductType.REVENUE_BASED_FINANCING: {
        return state.revenueBasedLoan?.amount;
      }
      case LoanProductType.POS_REVENUE_BASED_FINANCING: {
        return state.posRevenueBasedLoan.amount;
      }
      case LoanProductType.BNPL: {
        return state.buyNowPayLaterLoan.amount;
      }
      default:
        return 0;
    }
  }, [
    state.type,
    state.fixedTermLoan?.amount,
    state.invoiceDiscountingLoan.invoices,
    state.payableFinancingLoan.invoices,
    state.revenueBasedLoan?.amount,
    state.posRevenueBasedLoan.amount,
    state.buyNowPayLaterLoan.amount,
  ]);
  const duration = useMemo(() => {
    switch (state.type) {
      case LoanProductType.FIXED_TERM: {
        return {
          value: state.fixedTermLoan?.duration,
          unit: state.constraints?.fixedTermLoan?.duration?.unit,
        };
      }
      case LoanProductType.INVOICE_DISCOUNTING: {
        const [invoice] = state.invoiceDiscountingLoan.invoices;
        return {
          value: invoiceFinanceProduct?.config?.repayableByDP
            ? invoice?.invoiceDueDate
            : invoice?.duration,
          unit: invoiceFinanceProduct?.config?.repayableByDP
            ? ""
            : state.constraints?.invoiceDiscountingLoan?.duration?.unit,
        };
      }
      case LoanProductType.PAYABLE_FINANCING: {
        const [invoice] = state.payableFinancingLoan.invoices;
        return {
          value: payableFinancingProduct?.config?.repayableByDP
            ? invoice?.invoiceDueDate
            : invoice?.duration,
          unit: payableFinancingProduct?.config?.repayableByDP
            ? ""
            : state.constraints?.payableFinancingLoan?.duration?.unit,
        };
      }
      case LoanProductType.REVENUE_BASED_FINANCING: {
        return {
          value: state.revenueBasedLoan?.duration,
          unit: state.constraints?.revenueBasedLoan?.duration?.unit,
        };
      }
      case LoanProductType.POS_REVENUE_BASED_FINANCING: {
        return {
          value: state.posRevenueBasedLoan?.duration,
          unit: state.constraints?.posRevenueBasedLoan?.duration?.unit,
        };
      }
      case LoanProductType.BNPL: {
        return {
          value: state.buyNowPayLaterLoan.duration,
          unit: state.constraints.buyNowPayLaterLoan.duration.unit,
        };
      }
    }

    return { value: 0, unit: LoanDurationType.DAYS };
  }, [
    state.type,
    state.fixedTermLoan?.duration,
    state.revenueBasedLoan?.duration,
    state.posRevenueBasedLoan?.duration,
    state.invoiceDiscountingLoan.invoices,
    state.payableFinancingLoan.invoices,
    state.buyNowPayLaterLoan?.duration,
    state.constraints?.fixedTermLoan,
    state.constraints?.invoiceDiscountingLoan,
    state.constraints?.payableFinancingLoan,
    state.constraints?.buyNowPayLaterLoan?.duration,
    payableFinancingProduct,
    invoiceFinanceProduct,
  ]);

  const interestRate = useMemo(() => {
    if (state.type === LoanProductType.FIXED_TERM) {
      return state.fixedTermLoan?.interestRate;
    } else if (state.type === LoanProductType.INVOICE_DISCOUNTING) {
      const [invoice] = state.invoiceDiscountingLoan.invoices;
      return invoice?.interestRate;
    } else if (state.type === LoanProductType.PAYABLE_FINANCING) {
      const [invoice] = state.payableFinancingLoan.invoices;
      return invoice?.interestRate;
    } else if (state.type === LoanProductType.REVENUE_BASED_FINANCING) {
      return state.revenueBasedLoan?.interestRate;
    } else if (state.type === LoanProductType.BNPL) {
      return state.buyNowPayLaterLoan?.interestRate;
    }
    return 0;
  }, [
    state.fixedTermLoan?.interestRate,
    state.invoiceDiscountingLoan.invoices,
    state.payableFinancingLoan.invoices,
    state.revenueBasedLoan?.interestRate,
    state.buyNowPayLaterLoan.interestRate,
  ]);

  const factorRate = useMemo(() => {
    if (state.type === LoanProductType.POS_REVENUE_BASED_FINANCING) {
      return state.posRevenueBasedLoan?.factorRate;
    }
    return 0;
  }, [state.posRevenueBasedLoan?.duration]);

  const currencyUnit = useMemo(() => {
    if (state.type === LoanProductType.FIXED_TERM) {
      return state.constraints?.fixedTermLoan?.amount?.unit;
    } else if (state.type === LoanProductType.INVOICE_DISCOUNTING) {
      return state.constraints?.invoiceDiscountingLoan?.amount?.unit;
    } else if (state.type === LoanProductType.PAYABLE_FINANCING) {
      return state.constraints?.payableFinancingLoan?.amount?.unit;
    } else if (state.type === LoanProductType.REVENUE_BASED_FINANCING) {
      return state.constraints?.revenueBasedLoan?.amount?.unit;
    } else if (state.type === LoanProductType.POS_REVENUE_BASED_FINANCING) {
      return state.constraints?.posRevenueBasedLoan?.amount?.unit;
    } else if (state.type === LoanProductType.BNPL) {
      return state.constraints.buyNowPayLaterLoan?.amount?.unit;
    }
    return "$";
  }, [
    state.type,
    state.constraints?.fixedTermLoan?.amount?.unit,
    state.constraints?.invoiceDiscountingLoan?.amount?.unit,
    state.constraints?.payableFinancingLoan?.amount?.unit,
    state.constraints?.revenueBasedLoan?.amount?.unit,
    state.constraints.buyNowPayLaterLoan?.amount?.unit,
  ]);

  const totalPayment = useMemo(() => {
    if (state.type === LoanProductType.FIXED_TERM) {
      return calculateCompoundInterest(
        state.fixedTermLoan?.amount,
        state.fixedTermLoan?.duration / 12,
        state.fixedTermLoan?.interestRate
      );
    } else if (state.type === LoanProductType.INVOICE_DISCOUNTING) {
      const [invoice] = state.invoiceDiscountingLoan.invoices;
      return calculateTotalPaymentForRecieveableFinancing(
        invoice?.amount * (invoice?.discountRate / 100),
        invoice?.duration / 365,
        invoice?.interestRate
      );
    } else if (state.type === LoanProductType.PAYABLE_FINANCING) {
      const [invoice] = state.payableFinancingLoan.invoices;
      return calculateTotalPayment(
        invoice?.amount * (invoice?.discountRate / 100),
        invoice?.duration / 365,
        invoice?.interestRate
      );
    } else if (state.type === LoanProductType.REVENUE_BASED_FINANCING) {
      return calculateCompoundInterest(
        state.revenueBasedLoan?.amount,
        state.revenueBasedLoan?.duration / 12,
        state.revenueBasedLoan?.interestRate
      );
    } else if (state.type === LoanProductType.POS_REVENUE_BASED_FINANCING) {
      return calculateTotalPaymentForPosRevenueBasedFinancing(
        state.posRevenueBasedLoan?.amount,
        state.posRevenueBasedLoan?.factorRate
      );
    } else if (state.type === LoanProductType.BNPL) {
      return calculateTotalPaymentForBuyNowPayLater(
        state.buyNowPayLaterLoan?.amount,
        state.buyNowPayLaterLoan?.duration,
        state.buyNowPayLaterLoan?.interestRate
      );
    }
    return 0;
  }, [
    state.type,
    state.fixedTermLoan,
    state.invoiceDiscountingLoan.invoices,
    state.payableFinancingLoan.invoices,
    state.revenueBasedLoan,
    state.posRevenueBasedLoan,
    state.buyNowPayLaterLoan,
  ]);
  const totalPaymentWithoutInterest = useMemo(() => {
    if (state.type === LoanProductType.FIXED_TERM) {
      return (
        state.fixedTermLoan?.amount -
        (totalPayment - state.fixedTermLoan?.amount)
      );
    } else if (state.type === LoanProductType.INVOICE_DISCOUNTING) {
      const [invoice] = state.invoiceDiscountingLoan.invoices;
      return invoice?.amount - (totalPayment - invoice?.amount);
    } else if (state.type === LoanProductType.PAYABLE_FINANCING) {
      const [invoice] = state.payableFinancingLoan.invoices;
      return invoice?.amount - (totalPayment - invoice?.amount);
    } else if (state.type === LoanProductType.REVENUE_BASED_FINANCING) {
      return (
        state.revenueBasedLoan?.amount -
        (totalPayment - state.revenueBasedLoan?.amount)
      );
    } else if (state.type === LoanProductType.BNPL) {
      return state.buyNowPayLaterLoan?.amount;
    }
    return 0;
  }, [
    state.type,
    state.fixedTermLoan,
    state.revenueBasedLoan,
    state.invoiceDiscountingLoan.invoices,
    state.payableFinancingLoan.invoices,
    state.buyNowPayLaterLoan,
    totalPayment,
  ]);
  const revenueBasedLoanPaymentPerMonth = useMemo(() => {
    return totalPayment / state.revenueBasedLoan?.duration;
  }, [state.revenueBasedLoan?.duration, totalPayment]);

  const factorFeeForPosRevenueBasedFinancing = useMemo(() => {
    return totalPayment - state.posRevenueBasedLoan?.amount;
  }, [state.posRevenueBasedLoan?.factorRate, totalPayment]);

  const buyNowPayLaterPaymentPerMonth = useMemo(() => {
    return calculateMonthlyPaymentForBuyNowPayLater(
      state.buyNowPayLaterLoan?.amount,
      state.buyNowPayLaterLoan?.duration,
      state.buyNowPayLaterLoan?.interestRate
    );
  }, [
    totalPayment,
    state.buyNowPayLaterLoan.amount,
    state.buyNowPayLaterLoan?.duration,
  ]);

  const totalInvoiceFinancePaymentForSME = useMemo(() => {
    if (!invoiceFinanceProduct) return null;
    const { config } = invoiceFinanceProduct!;
    return config?.repayableByDP ? totalPaymentWithoutInterest : totalPayment;
  }, [
    products,
    totalPayment,
    totalPaymentWithoutInterest,
    invoiceFinanceProduct,
  ]);

  const totalLocInvoiceFinancePaymentForSME = useMemo(() => {
    if (!locInvoiceFinanceProduct) return null;
    const { config } = locInvoiceFinanceProduct!;
    return config?.repayableByDP ? totalPaymentWithoutInterest : totalPayment;
  }, [
    products,
    totalPayment,
    totalPaymentWithoutInterest,
    locInvoiceFinanceProduct,
  ]);

  const totalPayableFinancingPaymentForSME = useMemo(() => {
    if (!payableFinancingProduct) return null;
    const { config } = payableFinancingProduct!;
    return config?.repayableByDP ? totalPaymentWithoutInterest : totalPayment;
  }, [
    products,
    totalPayment,
    totalPaymentWithoutInterest,
    payableFinancingProduct,
  ]);

  const totalLocPayableFinancingPaymentForSME = useMemo(() => {
    if (!locPayableFinancingProduct) return null;
    const { config } = locPayableFinancingProduct!;
    return config?.repayableByDP ? totalPaymentWithoutInterest : totalPayment;
  }, [
    products,
    totalPayment,
    totalPaymentWithoutInterest,
    locPayableFinancingProduct,
  ]);

  const monthlyBusinessLoanPaymentForSME = useMemo(() => {
    return totalPayment / state.fixedTermLoan?.duration;
  }, [totalPayment, state.fixedTermLoan?.duration]);

  const loanAmount = useMemo(() => {
    switch (state.type) {
      case LoanProductType.FIXED_TERM:
        return state.fixedTermLoan?.amount;
      case LoanProductType.INVOICE_DISCOUNTING:
        return state.invoiceDiscountingLoan?.invoices?.[0]?.amount;
      case LoanProductType.PAYABLE_FINANCING:
        return state.payableFinancingLoan?.invoices?.[0]?.amount;
      case LoanProductType.REVENUE_BASED_FINANCING:
        return state.revenueBasedLoan?.amount;
      case LoanProductType.BNPL:
        return state.buyNowPayLaterLoan?.amount;
      default:
        return 0;
    }
  }, [
    state.type,
    state.fixedTermLoan?.amount,
    state.invoiceDiscountingLoan?.invoices,
    state.payableFinancingLoan?.invoices,
    state.revenueBasedLoan?.amount,
    state.buyNowPayLaterLoan?.amount,
  ]);
  return {
    businessLoanProduct,
    invoiceFinanceProduct,
    locInvoiceFinanceProduct,
    payableFinancingProduct,
    locPayableFinancingProduct,
    revenueBasedLoanProduct,
    borrowedAmount,
    duration,
    interestRate,
    factorRate,
    currencyUnit,
    totalPayment,
    totalPaymentWithoutInterest,
    revenueBasedLoanPaymentPerMonth,
    factorFeeForPosRevenueBasedFinancing,
    totalInvoiceFinancePaymentForSME,
    totalLocInvoiceFinancePaymentForSME,
    totalPayableFinancingPaymentForSME,
    totalLocPayableFinancingPaymentForSME,
    monthlyBusinessLoanPaymentForSME,
    buyNowPayLaterPaymentPerMonth,
    loanAmount,
  };
};

export default useLoanMetrics;
