import { FC, useState } from "react";
import { useFormik } from "formik";

import { ArrowLeftIcon } from "../../../assets/icons";
import {
  LoanProductType,
  MediaPurpose,
  MediaType,
} from "../../../hooks/api/types";
import { useLoanDashboard } from "../../../contexts";
import DrawdownForm from "./DrawdownForm";
import {
  DrawdownFields,
  getDrawdownValidationSchema,
} from "../../LoanCalculator/service";
import { useCreateDrawdown, useLoanMetrics, useMedia } from "../../../hooks";
import { UploadMediaArgs } from "../../../hooks/useMedia";

type DrawdownProps = {
  availableLimit?: number;
  previousStep?: () => void;
};

export async function handleLocInvoiceFiles(
  loanApplicationId: string,
  invoiceFiles: File[],
  upload: (payload: UploadMediaArgs) => Promise<string>,
  setUploading?: React.Dispatch<React.SetStateAction<boolean>>
): Promise<string[]> {
  setUploading && setUploading(true);

  const invoiceIds = await Promise.all(
    invoiceFiles.map((file) =>
      upload({
        file,
        type: MediaType.DOCUMENT,
        purpose: MediaPurpose.INVOICE,
        loanApplicationId,
      })
    )
  );

  setUploading && setUploading(false);
  return invoiceIds;
}

export const Drawdown: FC<DrawdownProps> = ({
  availableLimit,
  previousStep,
}) => {
  const [uploading, setUploading] = useState(false);
  const { upload } = useMedia();

  const {
    actions: { update },
    state: {
      currentLoanApplication,
      currentLoanApplicationId: loanApplicationId,
    },
  } = useLoanDashboard();
  const {
    locInvoiceFinanceProduct: {
      config: { amount },
    },
  } = useLoanMetrics();

  const { mutateAsync: createDrawdown, isLoading } = useCreateDrawdown();
  const [error, setError] = useState<string | undefined>(undefined);
  availableLimit =
    currentLoanApplication?.loanType === LoanProductType.LOC_INVOICE_DISCOUNTING
      ? currentLoanApplication?.locInvoiceDiscountingLoan?.loanAmount
      : currentLoanApplication?.locPayableFinancingLoan?.loanAmount || 0;
  const formik = useFormik<DrawdownFields>({
    initialValues: {
      vendorName: "",
      currency: "",
      invoiceFiles: [],
      invoices: [
        {
          invoiceNumber: "",
          invoiceAmount: undefined,
          requiredAmount:
            currentLoanApplication?.loanType ===
            LoanProductType.LOC_INVOICE_DISCOUNTING
              ? amount.max.toString()
              : "",
          issuedDate: undefined,
          dueDate: undefined,
          selected: false,
        },
      ],
      totalAmount: 0,
      numberOfInvoices: 0,
    },
    validationSchema: getDrawdownValidationSchema(availableLimit),
    onSubmit: async (values) => {
      try {
        let uploadedFileIds: string[] = [];
        uploadedFileIds = await handleLocInvoiceFiles(
          loanApplicationId,
          values.invoiceFiles,
          upload,
          setUploading
        );

        const { totalAmount, numberOfInvoices, ...drawdownFieldsToSubmit } =
          values;

        const drawdownFields = {
          ...drawdownFieldsToSubmit,
          invoiceFiles: uploadedFileIds,
          invoices: values.invoices,
        };

        await createDrawdown({ loanApplicationId, drawdownFields });
        update({
          currentLoanApplicationId: loanApplicationId,
          showDrawdownForm: false,
          showDrawdownSuccess: true,
        });
      } catch (error: any) {
        setError(
          error?.message || "Something went wrong, refresh and try again!"
        );
      }
    },
  });
  previousStep = () =>
    update({
      currentLoanApplicationId: loanApplicationId,
      showDrawdownForm: false,
    });

  return (
    <div className="cx-background-radial-gradient cx-w-full cx-min-h-screen">
      <div className="cx-max-w-[960px] cx-mx-auto">
        <div className="cx-flex cx-items-center cx-mb-6 cx-mt-6">
          <div className="cx-flex cx-items-center cx-bg-blue-600 cx-rounded-full cx-px-1 cx-mr-4 cx-shadow-modal">
            <button
              className="cx-flex cx-items-center cx-text-white cx-py-2"
              onClick={previousStep}
            >
              <ArrowLeftIcon className="cx-w-5 cx-h-5 cx-mx-2  cx-text-white" />
              <span className="cx-mr-2">Back</span>
            </button>
          </div>
          <h1 className="cx-text-xl cx-font-semibold cx-flex-grow">
            Request for drawdown
          </h1>
          <div className="cx-bg-white-50 cx-rounded-full cx-px-3 cx-border cx-border-gray-200 cx-flex cx-items-center">
            <span className="cx-text-gray-500">Available limit</span>
            <span className="cx-text-gray-300 cx-mx-1 cx-text-2xl">•</span>
            <span className="cx-text-green-700">
              AED {availableLimit.toLocaleString()}
            </span>
          </div>
        </div>

        <DrawdownForm
          formik={formik}
          availableLimit={availableLimit}
          loanApplicationId={loanApplicationId}
          previousStep={previousStep}
          loading={uploading || isLoading}
          error={error}
        />
      </div>
    </div>
  );
};

export default Drawdown;
