import React, { useEffect, useState } from "react";
import { useLoanDashboard } from "../../../contexts";
import {
  getVendorNature,
  getVendorTitle,
} from "../../LoanApplicationSteps/VendorInformation/utils";
import {
  useGetApplicationVendors,
  useVendorManagementActions,
} from "../../../hooks";
import { truncate } from "../../../utils";
import countries from "world-countries";
import {
  LoanProductType,
  LoanVendorDocumentStatus,
  LoanVendorManagementAction,
  LoanVendorStatus,
  Vendor,
  VendorMediaPurpose,
} from "../../../hooks/api/types";
import { Button } from "../../../components";

import VendorBankStatementSection from "./VendorBankStatementSection";
import NewLoanVendorsSection from "./NewLoanVendorsSection";
import PendingLoanVendorsSection from "./PendingLoanVendorsSection";
import {
  ButtonLoaderIcon,
  HeavyCheckIcon,
  WarningIcon,
} from "../../../assets/icons";

export type PageState = {
  approvedVendors: Array<Vendor>;
  draftVendors: Array<Vendor>;
  pendingVendors: Array<Vendor>;
  pendingBankStatementVendors: Array<Vendor>;
  rejectedVendors: Array<Vendor>;
  pendingReviewVendors: Array<Vendor>;
  isSubmitted: boolean;
  readyForSubmission: boolean;
};

type VendorCategories = {
  approved: Array<Vendor>;
  draft: Array<Vendor>;
  pending: Array<Vendor>;
  rejected: Array<Vendor>;
  pendingReview: Array<Vendor>;
};

type VendorDocumentRequestStatus = {
  vendorsPendingDocs: Array<Vendor>;
  pendingBankStatementVendors: Array<Vendor>;
  informationUploaded: boolean;
};

const categorizeVendors = (vendors: Array<Vendor>): VendorCategories => {
  return vendors.reduce(
    (acc, vendor: Vendor) => {
      if (!vendor?.status) return acc;

      switch (vendor.status) {
        case LoanVendorStatus.APPROVED:
          acc.approved.push(vendor);
          break;
        case LoanVendorStatus.DRAFT:
          acc.draft.push(vendor);
          break;
        case LoanVendorStatus.PENDING_DOCUMENTS:
          acc.pending.push(vendor);
          break;
        case LoanVendorStatus.REJECTED:
          acc.rejected.push(vendor);
          break;
        case LoanVendorStatus.PENDING_REVIEW:
          acc.pendingReview.push(vendor);
          break;
      }
      return acc;
    },
    {
      approved: [] as Array<Vendor>,
      draft: [] as Array<Vendor>,
      pending: [] as Array<Vendor>,
      rejected: [] as Array<Vendor>,
      pendingReview: [] as Array<Vendor>,
    }
  );
};

const getVendorDocumentRequestStatus = (
  vendors: Array<Vendor>
): VendorDocumentRequestStatus => {
  return vendors?.reduce(
    (acc, vendor: Vendor) => {
      if (!vendor?.loanVendorDocumentRequests) return acc;

      const hasOnlyBankStatementRequests =
        vendor.loanVendorDocumentRequests.every(
          (request) => request.type === VendorMediaPurpose.BANK_STATEMENT
        );

      const hasBankStatementRequest = vendor.loanVendorDocumentRequests?.some(
        (request) => request.type === VendorMediaPurpose.BANK_STATEMENT
      );

      const hasDraftDocuments = vendor.loanVendorDocumentRequests?.some(
        (request) =>
          request.loanVendorDocuments?.some(
            (doc) => doc.status === LoanVendorDocumentStatus.DRAFT
          )
      );

      if (!hasOnlyBankStatementRequests) {
        acc.vendorsPendingDocs.push(vendor);
      }

      if (
        vendor.status === LoanVendorStatus.PENDING_DOCUMENTS &&
        hasBankStatementRequest
      ) {
        acc.pendingBankStatementVendors.push(vendor);
      }

      if (hasDraftDocuments) {
        acc.informationUploaded = true;
      }

      return acc;
    },
    {
      vendorsPendingDocs: [] as Vendor[],
      pendingBankStatementVendors: [] as Vendor[],
      informationUploaded: false as boolean,
    }
  );
};

const VendorManagementSection = () => {
  const [currentLoan, setCurrentLoan] = useState<any>();
  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const [pageState, setPageState] = useState<PageState>();

  const {
    state: { loans, currentLoanApplicationId: loanId },
  } = useLoanDashboard();

  const { data, isLoading, refetch } = useGetApplicationVendors({
    loanApplicationId: loanId,
    pageSize: Number.MAX_SAFE_INTEGER,
  });

  const { mutateAsync: finalizeVendorManagement, isLoading: finalizing } =
    useVendorManagementActions();

  const {
    actions: { update },
  } = useLoanDashboard();

  useEffect(() => {
    const loan = loans.find((loan) => loan.id === loanId);
    setCurrentLoan(loan);
  }, [loanId]);

  useEffect(() => {
    if (data && !isLoading) {
      setPageLoading(true);

      const {
        approved: approvedVendors,
        draft: draftVendors,
        pending: pendingVendors,
        rejected: rejectedVendors,
        pendingReview: pendingReviewVendors,
      } = categorizeVendors(data?.data || []);

      const {
        vendorsPendingDocs,
        pendingBankStatementVendors,
        informationUploaded,
      } = getVendorDocumentRequestStatus(pendingVendors || []);

      const isReadyForSubmission =
        Boolean(draftVendors?.length) || informationUploaded;

      setPageState({
        readyForSubmission: isReadyForSubmission,
        //it is considered submitted if there are no pending vendors and no draft vendors
        isSubmitted: !(pendingVendors?.length || draftVendors?.length),
        pendingVendors: vendorsPendingDocs,
        approvedVendors,
        draftVendors,
        rejectedVendors,
        pendingBankStatementVendors,
        pendingReviewVendors,
      });
      setPageLoading(false);
    }
  }, [data, isLoading]);

  if (pageLoading) {
    return (
      <div className="cx-flex cx-flex-col cx-items-center cx-max-w-[960px] cx-w-full">
        <div className="cx-animate-spin cx-w-8 cx-h-8 cx-text-brand-primary-regular">
          <ButtonLoaderIcon />
        </div>
      </div>
    );
  }

  return (
    <div
      className={
        "cx-grow cx-flex cx-flex-col cx-content-center cx cx-items-center cx-w-full cx-max-w-[960px]"
      }
    >
      {/*we show thank you page when no pending and draft vendors exist and
      [either no rejected or approved vendors exist or rejected vendors exist and pending review vendors exist]
       */}
      {pageState?.isSubmitted &&
      (!(
        pageState?.rejectedVendors?.length || pageState?.approvedVendors?.length
      ) ||
        (pageState?.rejectedVendors?.length &&
          pageState?.pendingReviewVendors?.length)) ? (
        <div className="cx-grow cx-w-full cx-max-w-[960px] cx-flex cx-flex-col cx-items-center cx-justify-center">
          <HeavyCheckIcon />
          <div className="cx-text-text-primary cx-font-bold cx-text-3xl cx-text-center cx-my-2">
            Thank you!
          </div>
          <div className="cx-text-text-secondary cx-text-center cx-mb-8">
            Thanks for the update. We are reviewing your application. <br /> We
            will get back to you soon with the offer.
          </div>
          <div className={"cx-w-96"}>
            <Button
              label={"OK, Go back to dashboard"}
              slim={true}
              fullWidth
              className={"cx-font- cx-text-s"}
              onClick={async () => {
                await update({
                  currentLoanApplicationId: undefined,
                  showLoanDetails: false,
                });
              }}
            />
          </div>
        </div>
      ) : (
        <div className={"cx-py-6 cx-w-full cx-flex cx-flex-col"}>
          <div className={"cx-font-bold cx-text-2xl cx-mb-6"}>
            {getVendorTitle(currentLoan?.loanType)}
          </div>

          {!(
            pageState?.approvedVendors.length ||
            pageState?.pendingVendors.length ||
            pageState?.pendingBankStatementVendors.length
          ) &&
            Boolean(pageState?.rejectedVendors.length) && (
              <div className="cx-bg-white cx-rounded-lg cx-w-full cx-shadow-md cx-p-10 cx-mb-6 cx-flex cx-flex-col cx-justify-center cx-items-center cx-h-56">
                <WarningIcon className={"cx-w-12 cx-h-12"} />
                <div className={"cx-font-bold cx-text-2xl cx-mb-2"}>
                  Rejected {getVendorNature(currentLoan?.loanType)}
                </div>
                <div
                  className={
                    "cx-text-text-secondary cx-font-normal cx-text-base cx-text-center"
                  }
                >
                  All the suppliers you added have not been approved. All the
                  suppliers have failed AML.
                  <br />
                  Please add new suppliers in order to proceed with your
                  application.
                </div>
              </div>
            )}

          {/*approved section*/}
          {Boolean(pageState?.approvedVendors?.length) && (
            <div className="cx-bg-white cx-rounded-lg cx-w-full cx-shadow-md cx-p-10 cx-mb-6 ">
              <div className="cx-flex cx-flex-row cx-place-content-between cx-w-full cx-mb-6">
                <div className="cx-text-2xl cx-font-bold">
                  Approved{" "}
                  {getVendorTitle(currentLoan?.loanType as LoanProductType)}s
                </div>
              </div>
              <div
                className={
                  "cx-flex cx-flex-row cx-flex-wrap cx-w-full cx-justify-between"
                }
              >
                {pageState?.approvedVendors?.map((vendor: Vendor) => (
                  <div
                    className={`cx-flex cx-flex-row cx-items-center cx-justify-between cx-w-[428px] cx-max-h-[85px] cx-p-4 cx-border cx-border-stroke-primary cx-rounded-base cx-mb-4 `}
                    key={vendor.id}
                  >
                    <div
                      className={
                        "cx-flex cx-flex-row cx-items-center cx-content-center cx-w-full"
                      }
                    >
                      <div className={"cx-font-bold cx-text-2xl"}>
                        {
                          countries.find(
                            (country) => country.cca3 === vendor.country
                          )?.flag
                        }
                      </div>
                      <div
                        className={
                          "cx-flex cx-flex-row cx-w-full cx-ml-4 cx-justify-between"
                        }
                      >
                        <div className={"cx-font-medium cx-text-base"}>
                          {truncate(vendor.name, 40)}
                        </div>
                        <div
                          className={
                            "cx-w-16 cx-h-[25px] cx-rounded-2xl cx-bg-success-lighter cx-text-text-success cx-text-center cx-font-semibold cx-text-s cx-flex cx-flex-col cx-justify-center"
                          }
                        >
                          <div>Active</div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/*pending section*/}
          {Boolean(pageState?.pendingVendors?.length) && (
            <PendingLoanVendorsSection
              loanApplication={currentLoan}
              vendors={pageState?.pendingVendors}
              onSuccess={() => {
                refetch();
              }}
            />
          )}

          {Boolean(pageState?.pendingBankStatementVendors?.length) && (
            <VendorBankStatementSection
              vendors={pageState?.pendingBankStatementVendors}
              loanApplicationId={loanId}
              onSuccess={() => {
                refetch();
              }}
            />
          )}

          {(!pageState?.approvedVendors?.length ||
            Boolean(pageState?.draftVendors?.length)) && (
            <NewLoanVendorsSection
              loanApplication={currentLoan}
              vendors={pageState?.draftVendors}
              onSuccess={() => {
                refetch();
              }}
            />
          )}

          <div className={"cx-w-full"}>
            {!pageState?.isSubmitted && (
              <div className={"cx-flex cx-flex-row cx-w-full cx-gap-4"}>
                {Boolean(pageState?.approvedVendors?.length) && (
                  <Button
                    label={"Continue without pending vendors"}
                    loader={finalizing ? "right" : undefined}
                    fullWidth
                    slim
                    onClick={async () => {
                      await finalizeVendorManagement({
                        loanApplicationId: loanId,
                        type: LoanVendorManagementAction.CONTINUE_WITHOUT_PENDING_VENDORS,
                      });
                      await refetch();
                    }}
                    disabled={finalizing}
                  />
                )}
                <Button
                  label={"Submit documents"}
                  disabled={!pageState?.readyForSubmission || finalizing}
                  fullWidth
                  loader={finalizing ? "right" : undefined}
                  slim
                  outlined={true}
                  onClick={async () => {
                    await finalizeVendorManagement({
                      loanApplicationId: loanId,
                      type: LoanVendorManagementAction.SUBMIT_DOCUMENTS,
                    });
                    await refetch();
                  }}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default VendorManagementSection;
