import React, { useState } from "react";
import { Button, Checkbox, Modal } from "../../../components";

interface DeleteInvoiceModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const DeleteInvoiceModal: React.FC<DeleteInvoiceModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
}) => {
  const [understood, setUnderstood] = useState(false);

  const handleConfirm = () => {
    if (understood) {
      onConfirm();
      onClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Delete row?"
      subtitle="Are you sure you want to delete this?
      This action cannot be undone."
      variant="expanded"
    >
      <div className="cx-flex cx-flex-col cx-gap-6">
        <div className="cx-flex cx-items-start cx-gap-2  cx-bg-background-secondary cx-p-4 cx-rounded-md">
          <Checkbox
            label=""
            checked={understood}
            onChange={() => setUnderstood(!understood)}
          />
          <label
            htmlFor="understood"
            className="cx-text-text-secondary cx-text-sm"
          >
            I understand that deleting this cannot be{" "}
            <span className="cx-font-semibold">undone</span>.
          </label>
        </div>

        <div className="cx-flex cx-gap-3">
          <Button
            label="Cancel"
            onClick={onClose}
            fullWidth
            secondary
            applyBorder
          />
          <Button
            label="Delete"
            onClick={handleConfirm}
            disabled={!understood}
            danger
            fullWidth
          />
        </div>
      </div>
    </Modal>
  );
};

export default DeleteInvoiceModal;
