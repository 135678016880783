import { FC, useEffect, useState } from "react";
import { useGetKycShareholderAccessToken } from "../../../hooks";
import { useLoanApplication } from "../../../contexts";
import { ButtonLoaderIcon } from "../../../assets/icons";
import { ShareholderKYCApplicationStatus } from "../../../hooks/api/types";
import SumSubKycView from "../../../components/SumSubKycView/SumSubKycView";

type Props = {
  onSubmit: () => void;
};

const ShareHolderSumSubKyc: FC<Props> = ({ onSubmit }) => {
  const [accessToken, setAccessToken] = useState<string>();

  const {
    state: { individualShareholders, shareHolderPendingKYC },
    actions: { update, refetch },
  } = useLoanApplication();
  const { refetch: refetchAccessToken } = useGetKycShareholderAccessToken(
    shareHolderPendingKYC?.id ?? ""
  );

  useEffect(() => {
    if (shareHolderPendingKYC && shareHolderPendingKYC.id !== "") {
      handleGetAccessToken();
    }
  }, [shareHolderPendingKYC]);

  const handleGetAccessToken = async () => {
    const {
      data: { kycAccessToken },
    } = await refetchAccessToken();
    setAccessToken(kycAccessToken.token);
  };

  const handleResult = async () => {
    individualShareholders.forEach((sh) => {
      if (shareHolderPendingKYC!.id === sh.id) {
        sh.latestKycApplication!.status =
          ShareholderKYCApplicationStatus.IN_PROGRESS;
      }
    });

    await update({
      individualShareholders: [...individualShareholders],
    });
    await refetch();
  };

  if (accessToken) {
    return (
      <div className="cx-max-w-[960px] cx-min-h-[400px] cx-w-full cx-grid">
        <SumSubKycView
          nextHandler={onSubmit}
          accessToken={accessToken}
          handleGetAccessToken={handleGetAccessToken}
          handleRefetch={refetch}
          handleSumsubApplicantUpdate={handleResult}
        />
      </div>
    );
  }

  return (
    <div className="cx-max-w-[960px] cx-min-h-[400px] cx-w-full cx-flex cx-justify-center cx-items-center">
      <div className="cx-animate-spin cx-text-brand-primary-regular cx-w-10 cx-h-10">
        <ButtonLoaderIcon width="100%" height="100%" />
      </div>
    </div>
  );
};

export default ShareHolderSumSubKyc;
