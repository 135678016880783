import { useContext, useEffect, useMemo, useState } from "react";
import {
  SignModeType,
  StepperContext,
  useLoanOffer,
  useMicroFlow,
} from "../../contexts";
import { Button, Divider, StepNextButton, Tooltip } from "../../components";
import {
  ButtonLoaderIcon,
  DocumentIcon,
  InfoIcon,
  ReadyClockIcon,
  SuccessIcon,
} from "../../assets/icons";
import LoanOfferRejectModel from "./LoanOfferRejectModal";
import {
  useApproveLoanOffer,
  useDirectDebitBankAccounts,
  useGetExistingShareholderSignature,
  useGetLoanOffer,
  useTheme,
} from "../../hooks";
import { useLocation } from "react-router-dom";
import { LOCALSTORAGE_KEYS } from "../../constants";
import { DateTime } from "luxon";
import {
  convertBnplMonthsToInstallments,
  formatAmount,
  getFutureDate,
  isEmptyObject,
} from "../../utils";
import {
  LOAN_PRODUCT_DISPLAY_NAME,
  LoanOfferStage,
  LoanProductType,
} from "../../hooks/api/types";
import { STEP_STAGE_NAME_MAP } from ".";
import { getCopyText } from "./constants";
import moment from "moment-timezone";
import Transaction from "../../views/LoanCalculator/Transaction";
import { CredibleLogoLight } from "../../assets/images";
import { InterestFrequency } from "../../types";
import { format } from "date-fns";
import countries from "world-countries";

/**
 * Calculates the next stage in the loan offer process based on the provided data.
 * @returns {string} The next stage, represented by a constant from LoanOfferStage.
 */
export function calculateNextStage(
  isMainApplicant: boolean,
  hasBankAccounts: boolean,
  directDebitRequired = false
): LoanOfferStage {
  if (isMainApplicant || !directDebitRequired) {
    return hasBankAccounts
      ? LoanOfferStage.SELECT_BANK_ACCOUNT
      : LoanOfferStage.ADD_BANK_ACCOUNT;
  }

  return LoanOfferStage.SELECT_SIGN_MODE;
}
type LoanInfoItemProps = {
  label: string;
  text?: string;
  subText?: string;
  noDivider?: boolean;
  dividerClass?: string;
};

const LoanInfoItem = ({
  label,
  text,
  subText,
  noDivider,
  dividerClass,
}: LoanInfoItemProps) => (
  <div className="cx-w-full lg:cx-max-w-[420px]">
    <div className="cx-flex cx-flex-col cx-gap-y-1 cx-py-4">
      <div className="cx-text-text-secondary cx-text-sm cx-font-medium">
        {label}
      </div>
      <div className="cx-flex cx-flex-row cx-items-base cx-gap-x-1">
        {text && (
          <div className="cx-text-text-primary cx-font-medium">{text}</div>
        )}
        {subText && (
          <div className="cx-text-text-primary cx-font-bold cx-text-xl">
            {subText}
          </div>
        )}
      </div>
    </div>
    {!noDivider && <Divider className={dividerClass} />}
  </div>
);

const LoanOfferStart = () => {
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const {
    actions: { update: updateMicroFlowContext },
  } = useMicroFlow();

  const { setTheme, themeLoading } = useTheme();

  const { gotoStep } = useContext(StepperContext);
  const {
    state: {
      isAlternateOffer,
      lastOfferExpiredAt,
      uiLocalizationData,
      id,
      loanApplicationId,
      loanConfig,
    },
    actions: { update },
  } = useLoanOffer();

  const {
    mutateAsync: getLoanOfferDetails,
    data,
    isLoading: loanOfferLoading,
  } = useGetLoanOffer();
  const { mutateAsync: getBankAccounts, data: bankAccounts } =
    useDirectDebitBankAccounts();
  const {
    mutateAsync: getExistingShareholderSignature,
    data: existingShareholderSignature,
  } = useGetExistingShareholderSignature();
  const { mutateAsync: approveLoanOffer } = useApproveLoanOffer();
  const [invoiceDueDate, setInvoiceDuedate] = useState("");
  const { search } = useLocation();
  const isLOC =
    data?.loanType === LoanProductType.LOC_INVOICE_DISCOUNTING ||
    data?.loanType === LoanProductType.LOC_PAYABLE_FINANCING;

  const vendors = data?.approvedVendors || [];

  useEffect(() => {
    const params = new URLSearchParams(search);
    const code = params.get("code");

    const handleCode = async () => {
      if (code) {
        localStorage.setItem(LOCALSTORAGE_KEYS.SESSION_TOKEN, code);
        await getLoanOfferDetails();

        if (data?.invoiceDueDate === null) {
          setInvoiceDuedate("Not Available");
        } else {
          setInvoiceDuedate(data?.invoiceDueDate);
        }
      }
    };

    handleCode();
  }, [search]);

  useEffect(() => {
    if (data?.dp?.themeConfig) {
      setTheme(data.dp.themeConfig);
      updateMicroFlowContext({
        logo: uiLocalizationData?.loanOfferMicroflow?.usePartnerLogo
          ? data?.dp?.themeConfig?.logo
          : CredibleLogoLight,
        footerLogo:
          (uiLocalizationData?.footer?.usePartnerLogo &&
            uiLocalizationData?.footer?.logoUrl) ||
          CredibleLogoLight,
      });
    }
  }, [
    loanOfferLoading,
    data?.dp?.themeConfig,
    uiLocalizationData?.loanOfferMicroflow?.usePartnerLogo,
  ]);

  useEffect(() => {
    if (
      (!data?.dp?.themeConfig || isEmptyObject(data?.dp?.themeConfig)) &&
      !loanOfferLoading &&
      !isLoading
    ) {
      setTheme();
    }
  }, [loanOfferLoading, isLoading, data?.dp?.themeConfig]);

  useEffect(() => {
    if (data) {
      if (data?.dp?.settings) {
        update({ dpSettings: data?.dp?.settings });
      }
      update({
        id: data.id,
        businessName: data.companyName,
        tradeLicenseNumber: data.licenseNumber,
        duration: data.loanInput?.duration,
        loanType: data.loanInput?.type,
        interestRate: data.interestRate,
        monthlyPayment: data.installmentAmount,
        totalAmount: data.approvedAmount,
        totalInterestPaid: data.totalInterestPaid,
        totalRepayment: data.totalAmount,
        loanApplicationId: data.loanApplicationId,
        stage: data.stage,
        isMainApplicant: data.isMainApplicant,
        totalShareholders: data.totalShareholders,
        shareholderId: data.shareholderId,
        isActive: data.isActive,
        lastOfferExpiredAt: data.lastOfferExpiredAt,
        isAlternateOffer: data.isAlternateOffer,
        preSelectedSignMode: data.preSelectedSignMode,
        uiLocalizationData: data.dp.uiLocalizationData,
        loanConfig: data.loanConfig,
        companyName: data.companyName,
        distributionPartnerId: data.dp.id,
        distributionPartnerName: data.dp.name,
      });

      if (data.preSelectedSignMode === SignModeType.PRINT_SCAN) {
        update({ signMode: SignModeType.PRINT_SCAN });
      } else {
        getExistingShareholderSignature({
          loanApplicationId: data.loanApplicationId,
          shareholderId: data.shareholderId,
        });
      }

      if (data?.loanConfig?.directDebitRequired) getBankAccounts(data.id);
      if (data?.stage !== LoanOfferStage.REVIEW) {
        gotoStep(STEP_STAGE_NAME_MAP[data?.stage as LoanOfferStage]);
      } else if (!data?.isMainApplicant) {
        if (data?.loanConfig?.directDebitRequired) {
          gotoStep(STEP_STAGE_NAME_MAP[LoanOfferStage.SELECT_SIGN_MODE]);
        } else {
          handleApprove();
        }
      } else {
        setIsLoading(false);
      }
    }
  }, [data]);

  useEffect(() => {
    if (bankAccounts?.length) {
      update({
        banklist: bankAccounts.map((account: any) => ({
          bankName: account.bankName,
          bankLogo: account.bankLogo,
          iban: account.iban,
          accountName: account.accountName,
          accountId: account.accountId,
          sourceType: account.sourceType,
        })),
      });
    }
  }, [bankAccounts]);

  useEffect(() => {
    if (existingShareholderSignature) {
      update({
        existingShareholderSignatureSignedUrl: existingShareholderSignature.url,
      });
    }
  }, [existingShareholderSignature]);

  const handleApprove = async () => {
    const isDirectDebitRequired = loanConfig?.directDebitRequired;
    const isDisbursementAccountProvided =
      loanConfig?.disbursementAccountProvided ?? false;
    if (isDirectDebitRequired && !isDisbursementAccountProvided) {
      gotoStep(STEP_STAGE_NAME_MAP[LoanOfferStage.SETUP_DIRECT_DEBIT]);
    } else {
      await approveLoanOffer({
        id: id || data.id,
      });
      gotoStep(STEP_STAGE_NAME_MAP[LoanOfferStage.COMPLETED]);
    }
    setIsLoading(false);
  };
  const handleDecline = () => {
    setShowRejectModal(true);
  };

  const handleDeclineConfirm = () => {
    gotoStep([4, 0]);
  };

  // TODO: refactor this to a util function
  const formattedDate = useMemo(() => {
    if (lastOfferExpiredAt) {
      const date = moment.tz(
        lastOfferExpiredAt,
        DateTime.fromISO(lastOfferExpiredAt).toFormat("z")
      );
      const day = date.date();
      const suffix = () => {
        if (day === 1 || day === 21 || day === 31) return "st";
        else if (day === 2 || day === 22) return "nd";
        else if (day === 3 || day === 23) return "rd";
        else return "th";
      };

      return `${date.format("MMMM")} ${day}${suffix()} at ${date.format(
        "hh:mm a z"
      )}`;
    }
  }, [lastOfferExpiredAt]);

  const loanTypeText =
    LOAN_PRODUCT_DISPLAY_NAME[data?.loanType as LoanProductType];
  const repayableByDPFlag = data?.loanConfig?.repayableByDP;

  const showAnnualInterestOnOfferFlag =
    !!data?.loanConfig?.showAnnualInterestOnOffer;

  const loanType = data?.loanType;

  if (isLoading) {
    return (
      <div className="cx-flex cx-flex-col cx-items-center cx-max-w-[960px] cx-w-full">
        <div className="cx-animate-spin cx-w-8 cx-h-8 cx-text-brand-primary-regular">
          <ButtonLoaderIcon />
        </div>
      </div>
    );
  }

  // This function determines whether to show interest rate annually or monthly
  const getFormattedInterestRate = (interestRate: number) => {
    const interestFrequency = data?.interestFrequency;

    if (
      showAnnualInterestOnOfferFlag &&
      interestFrequency === InterestFrequency.MONTHLY
    ) {
      return (interestRate * 12).toFixed(2);
    } else if (
      !showAnnualInterestOnOfferFlag &&
      interestFrequency === InterestFrequency.ANNUALLY
    ) {
      return (interestRate / 12).toFixed(2);
    } else {
      return interestRate.toFixed(2);
    }
  };

  const generatePaymentSchedule = (): string[] => {
    const initialDate = DateTime.now();
    const totalInstallments = convertBnplMonthsToInstallments(
      data.loanDuration
    );
    return Array.from({ length: totalInstallments }, (_, index) => {
      const date =
        index === 0
          ? initialDate
          : getFutureDate(1, "months", initialDate.plus({ months: index - 1 }));
      return date.toFormat("dd MMM yyyy");
    });
  };

  if (loanOfferLoading || themeLoading) {
    return (
      <div className="cx-flex cx-flex-col cx-items-center cx-max-w-[960px] cx-w-full">
        <div className="cx-animate-spin cx-w-8 cx-h-8 cx-text-brand-primary-regular">
          <ButtonLoaderIcon />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="cx-grow cx-w-full cx-max-w-[960px] cx-flex cx-flex-col cx-items-center cx-py-12">
        <div className="cx-mb-6">
          <ReadyClockIcon />
        </div>
        <div className="cx-text-text-primary cx-font-bold cx-text-3xl cx-text-center cx-mb-2">
          {uiLocalizationData?.loanOfferMicroflow?.loanOfferStartPage
            ?.pageHeading ??
            `Your ${isLOC ? "Line of credit" : "loan"} offer is ready`}
        </div>

        <Tooltip
          content={
            <div className="cx-flex cx-flex-row cx-items-center">
              <InfoIcon
                height={16}
                width={16}
                className="cx-stroke-brand-white cx-mr-2"
              />
              <div className="cx-text-sm">
                Loan offer has been updated on {formattedDate}
              </div>
            </div>
          }
          open={isAlternateOffer}
          position={"bottom center"}
          closeOnDocumentClick={false}
          isEnabled={isAlternateOffer}
          trigger={
            <div className="cx-text-text-secondary cx-text-center cx-mb-6">
              {uiLocalizationData?.loanOfferMicroflow?.loanOfferStartPage
                ?.pageDescription ??
                `Please review the ${
                  isLOC ? "line of credit" : "loan"
                } details`}
              , this offer is valid until{" "}
              {isLOC
                ? format(new Date(data?.validity), "do 'of' MMMM yyyy")
                : DateTime.fromFormat(data?.validity, "yyyy-MM-dd").toFormat(
                    "dd MMM yyyy"
                  )}
              .
            </div>
          }
        />
        {isAlternateOffer && (
          <div className="cx-text-text-secondary cx-text-center cx-mb-12"></div>
        )}
        <div className="cx-mb-10">
          <Button
            outlined
            slim
            label={
              <div className="cx-flex cx-flex-row cx-items-center cx-gap-2">
                <div className="cx-w-4 cx-h-4">
                  <DocumentIcon className="cx-stroke-2" />
                </div>
                <div className="cx-text-interaction-text-default cx-text-sm cx-font-semibold">
                  Download as PDF
                </div>
              </div>
            }
            onClick={() => {
              window.open(data?.link, "_blank");
            }}
          />
        </div>
        <div className="cx-w-full cx-flex cx-flex-wrap cx-bg-background-default cx-rounded-lg cx-shadow-base cx-gap-x-10 cx-px-10 cx-py-6 cx-mb-10">
          <LoanInfoItem label={"Business Name"} subText={data?.companyName} />
          <LoanInfoItem
            label={
              uiLocalizationData?.loanOfferMicroflow?.loanOfferStartPage
                ?.approvedAmountLabel ||
              `Approved ${isLOC ? "Line of credit" : "loan"} amount`
            }
            text={`AED ${formatAmount(data?.approvedAmount)}`}
          />
          {!uiLocalizationData?.loanOfferMicroflow?.loanOfferStartPage
            ?.hideLoanType &&
            !isLOC && <LoanInfoItem label="Loan type" text={loanTypeText} />}
          {!uiLocalizationData?.loanOfferMicroflow?.loanOfferStartPage
            ?.hideMonthlyPayment &&
            (data?.loanType === LoanProductType.FIXED_TERM ||
              data?.loanType === LoanProductType.BNPL) && (
              <LoanInfoItem
                label="Monthly payment"
                text={`AED ${formatAmount(data?.installmentAmount)}`}
              />
            )}
          {/* <LoanInfoItem
            label={getCopyText("INTEREST_RATE", "loanType", loanType)!(
              loanType
            )}
            text={`Fixed, ${
              data?.loanType !== LoanProductType.BNPL
                ? `${data?.annualInterestRate}%`
                : `${calculateMonthlyInterestRate(data?.annualInterestRate)}%`
            }`}
          /> */}
          {!loanConfig?.hideInterestPaymentOnOffer &&
            data?.loanType !== LoanProductType.POS_REVENUE_BASED_FINANCING &&
            !isLOC && (
              <LoanInfoItem
                label={
                  uiLocalizationData?.loanOfferMicroflow?.loanOfferStartPage
                    ?.totalInterestAmountLabel ||
                  getCopyText(
                    "TOTAL_INTEREST_PAID",
                    "repayableByDP",
                    repayableByDPFlag
                  )!()
                }
                text={`AED ${formatAmount(data?.totalInterestPaid)}`}
              />
            )}
          {data?.loanType !== LoanProductType.POS_REVENUE_BASED_FINANCING && (
            <LoanInfoItem
              label={
                isLOC
                  ? getCopyText("INTEREST_RATE", "loanType", loanType)!(
                      loanType
                    )
                  : getCopyText(
                      "INTEREST_RATE",
                      "showAnnualInterestOnOffer",
                      showAnnualInterestOnOfferFlag
                    )!()
              }
              text={`Fixed, ${`${getFormattedInterestRate(
                data?.interestRate
              )}%`}`}
            />
          )}

          {isLOC && (
            <LoanInfoItem
              label="Processing fee"
              text={`AED ${data?.loanConfig?.processingFee}`}
            />
          )}

          {repayableByDPFlag && (
            <LoanInfoItem label="Invoice Due Date" text={invoiceDueDate} />
          )}
          {!loanConfig?.hideInterestPaymentOnOffer &&
            !isLOC &&
            data.arrangementFee !== 0 && (
              <LoanInfoItem
                label={
                  loanType === LoanProductType.PAYABLE_FINANCING
                    ? getCopyText("TOTAL_REPAYMENT", "loanType", loanType)!()
                    : getCopyText(
                        "TOTAL_REPAYMENT",
                        "repayableByDP",
                        repayableByDPFlag
                      )!()
                }
                text={`AED ${formatAmount(
                  Number(data?.approvedAmount) - Number(data?.arrangementFee)
                )}`}
              />
            )}

          {loanType == LoanProductType.REVENUE_BASED_FINANCING && (
            <LoanInfoItem
              label={"Total repayment amount"}
              text={`AED ${formatAmount(
                data?.approvedAmount + data?.totalInterestPaid
              )}`}
            />
          )}

          {data.loanType == LoanProductType.POS_REVENUE_BASED_FINANCING && (
            <LoanInfoItem
              label="One time upfront fee"
              text={`AED ${formatAmount(data?.arrangementFee)}`}
            />
          )}

          {/* <LoanInfoItem
            label="Duration"
            text={`${data?.loanInput?.duration} months`}
            dividerClass="cx-block lg:cx-hidden"
          /> */}
          {data.loanType == LoanProductType.INVOICE_DISCOUNTING && (
            <LoanInfoItem
              label="Loan Duration"
              text={
                data?.loanDuration === 1 ? "1 Day" : `${data.loanDuration} Days`
              }
            />
          )}
          {[
            LoanProductType.FIXED_TERM,
            LoanProductType.REVENUE_BASED_FINANCING,
            LoanProductType.POS_REVENUE_BASED_FINANCING,
            LoanProductType.LOC_INVOICE_DISCOUNTING,
            LoanProductType.LOC_PAYABLE_FINANCING,
          ].includes(data.loanType) && (
            <LoanInfoItem
              label={isLOC ? "Tenure" : "Loan Duration"}
              text={
                data?.loanDuration === 1
                  ? "1 Month"
                  : `${data.loanDuration} Months`
              }
            />
          )}

          {data.loanType == LoanProductType.POS_REVENUE_BASED_FINANCING && (
            <LoanInfoItem
              label="Loan Duration"
              text={
                data?.loanDuration === 1 ? "1 Day" : `${data.loanDuration} Days`
              }
            />
          )}

          {loanConfig?.disbursementAccountProvided && (
            <>
              {data.bankName?.length > 0 && (
                <LoanInfoItem label="Bank Name" text={data.bankName} />
              )}
              {data.IBAN?.length > 0 && (
                <LoanInfoItem label="IBAN" text={data.IBAN} />
              )}
            </>
          )}
        </div>
        {loanType === LoanProductType.BNPL && (
          <div
            className="
              bnpl-transactions cx-w-full cx-flex cx-flex-wrap cx-bg-background-default 
              cx-rounded-lg cx-shadow-base cx-gap-x-10 cx-px-10 cx-py-14 cx-mb-10"
          >
            <div className="cx-container cx-flex cx-gap-2 cx-items-center cx-pb-8 cx-relative">
              <p className="cx-font-bold cx-text-black cx-text-2xl">
                Payment schedule
              </p>
            </div>
            {generatePaymentSchedule().map((date, index) => (
              <Transaction
                key={index}
                installmentNo={index}
                isLast={index === data?.loanDuration + 1}
                isFirst={index === 0}
                monthlyPayable={data.installmentAmount}
                currencyUnit={data?.loanConfig?.amount?.unit}
                date={date}
                isReviewScreen={true}
              />
            ))}
          </div>
        )}

        {isLOC && (
          <div className="cx-w-full cx-bg-background-default cx-rounded-lg cx-shadow-base cx-px-8 cx-py-6 cx-mb-10">
            <div className="cx-text-text-primary cx-font-bold cx-text-lg cx-mb-4">
              Approved{" "}
              {getCopyText("VENDOR_TYPE_TEXT", "vendorType", true)!(
                vendors[0]?.type
              )}
            </div>
            <div className="cx-grid cx-grid-cols-2 cx-gap-6">
              {vendors?.map((vendor: any) => (
                <div
                  key={vendor.loanVendorId}
                  className="cx-flex cx-items-center cx-p-4 cx-bg-white cx-rounded-lg cx-shadow-sm cx-border cx-border-gray-200"
                >
                  <div className="cx-text-2xl cx-mr-4  cx-mt-1">
                    {
                      countries.find(
                        (country) => country.cca3 === vendor.country
                      )?.flag
                    }
                  </div>
                  <div className="cx-flex-1 cx-text-text-primary cx-font-medium">
                    {vendor.name}
                  </div>
                  <div className="cx-w-5 cx-h-5 cx-text-green-500">
                    <SuccessIcon />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="cx-w-full cx-flex cx-flex-row cx-justify-center cx-gap-x-6">
          <div className="cx-w-full cx-max-w-[400px]">
            <StepNextButton label="Accept" onClick={handleApprove} />
          </div>
          <div className="cx-w-full cx-max-w-[400px]">
            <Button
              label="Decline"
              type="button"
              fullWidth
              outlined
              onClick={handleDecline}
            />
          </div>
        </div>
      </div>
      <LoanOfferRejectModel
        isOpen={showRejectModal}
        onConfirm={() => {
          setShowRejectModal(false);
          handleDeclineConfirm();
        }}
        onCancel={() => {
          setShowRejectModal(false);
        }}
      />
    </>
  );
};

export default LoanOfferStart;
