import { FC, useState } from "react";
import { FormikProps } from "formik";
import currencyCodes from "currency-codes";

import { DocumentIcon, PdfIcon } from "../../../assets/icons";
import { Button, CardList, Combobox, Dropzone } from "../../../components";
import { formatBytes } from "../../../utils";
import {
  CardVariantType,
  DisplayVariant,
} from "../../../components/CardList/CardList";
import { DrawdownFields } from "../../LoanCalculator/service";
import { InvoicesList } from "./InvoicesList";
import { useGetApplicationVendors } from "../../../hooks";
import {
  LoanProductType,
  LoanVendorStatus,
  LoanVendorType,
} from "../../../hooks/api/types";
import { useLoanDashboard } from "../../../contexts";

type DrawdownFormProps = {
  formik: FormikProps<DrawdownFields>;
  availableLimit: number;
  loanApplicationId: string;
  previousStep?: () => void;
  loading?: boolean;
  error?: string;
};

export const DrawdownForm: FC<DrawdownFormProps> = ({
  formik,
  availableLimit,
  loanApplicationId,
  previousStep,
  loading,
  error,
}) => {
  const [uploading, setUploading] = useState(false);
  const {
    state: { currentLoanApplication },
  } = useLoanDashboard();

  const { data: vendors } = useGetApplicationVendors({
    loanApplicationId,
    pageSize: Number.MAX_SAFE_INTEGER,
    status: LoanVendorStatus.APPROVED,
    type:
      currentLoanApplication?.loanProduct.type ===
      LoanProductType.LOC_PAYABLE_FINANCING
        ? LoanVendorType.SUPPLIER
        : LoanVendorType.BUYER,
  });
  const vendorOptions =
    vendors?.data?.map((vendor) => ({
      value: vendor.name,
      label: vendor.name,
      countryCode: vendor.country,
    })) ?? [];

  const currencyOptions: { value: string; label: string }[] = currencyCodes
    .codes()
    .map((code) => {
      const currency = currencyCodes.code(code);
      return currency ? { value: code, label: code } : undefined;
    })
    .filter((item): item is { value: string; label: string } => Boolean(item));

  const handleFileClose = (index: number) => {
    setFieldValue(
      "invoiceFiles",
      values.invoiceFiles.filter((_, i) => i !== index)
    );
  };

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    setFieldTouched,
    setFieldValue,
    isValid,
    dirty,
  } = formik;

  const handleUpload = (file: File) => {
    setUploading(true);
    setFieldValue("invoiceFiles", [...(values.invoiceFiles || []), file]);

    setUploading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="cx-bg-white cx-rounded-lg cx-p-6 cx-mb-4 cx-shadow-base">
        <h2 className="cx-text-lg cx-font-medium cx-mb-4">Basic details</h2>
        <div className="cx-grid cx-grid-cols-2 cx-gap-4">
          <div className="cx-mb-4">
            <Combobox
              placeholder="Vendor Name"
              options={vendorOptions}
              inputProps={{
                name: "vendorName",
                onBlur: () => setFieldTouched("vendorName"),
              }}
              onChange={handleChange("vendorName")}
              error={touched.vendorName ? errors.vendorName : ""}
            />
          </div>

          <div className="cx-mb-4">
            <Combobox
              placeholder="Currency"
              options={currencyOptions}
              inputProps={{
                name: "currency",
                onBlur: () => setFieldTouched("currency"),
              }}
              onChange={handleChange("currency")}
              error={touched.currency ? errors.currency : ""}
            />
          </div>
        </div>
      </div>

      <InvoicesList availableLimit={availableLimit} formik={formik} />
      <div className="cx-w-full ">
        <div className="cx-bg-white cx-rounded-lg cx-p-6 cx-mb-4 cx-shadow-base">
          <h2 className="cx-text-lg cx-font-semibold cx-mb-2">
            Upload invoice
          </h2>
          <p className="cx-text-sm cx-text-gray-500 cx-mb-4">
            Please upload your invoices individually or combine them into a
            single PDF and upload the consolidated file.
          </p>

          <Dropzone
            variant={uploading ? "uploading" : "simple"}
            extensions={["PDF file", "file size is 5 MB max"]}
            options={{
              accept: { "application/pdf": [".pdf"] },
              maxFiles: 1,
              maxSize: 100 * 1024 * 1024,
            }}
            onDrop={async (e) => {
              const file = e[0];
              if (!file) return;
              handleUpload(file);
            }}
          />

          <div className="cx-w-full cx-space-y-2 cx-mt-3 ">
            {values.invoiceFiles.map((invoice: File, index: number) => (
              <CardList
                key={index}
                variant={
                  uploading
                    ? CardVariantType.UPLOADING
                    : invoice.name
                    ? CardVariantType.UPLOADED
                    : CardVariantType.UPLOAD
                }
                title={invoice.name}
                description={formatBytes(invoice.size, 2)}
                inputProps={{ name: "invoice" }}
                displayVariant={DisplayVariant.NEW}
                icon={
                  uploading ? (
                    <DocumentIcon width="100%" height="100%" />
                  ) : (
                    <PdfIcon className="cx-w-5 cx-h-5 cx-text-red-500" />
                  )
                }
                handleFileClose={() => handleFileClose(index)}
                onChange={async (file) => {
                  if (!file) return;
                  handleUpload(file);
                }}
                error={
                  touched.invoiceFiles?.[index] &&
                  !!errors.invoiceFiles?.[index]
                }
              />
            ))}
          </div>
          {touched.invoiceFiles &&
            errors.invoiceFiles &&
            typeof errors.invoiceFiles === "string" && (
              <div className="cx-text-text-error cx-text-xs ">
                {errors.invoiceFiles}
              </div>
            )}
        </div>

        <div className="cx-text-red-500 cx-text-sm cx-p-2">{error}</div>

        <div className="cx-flex cx-justify-around cx-gap-4">
          <Button
            label="Cancel"
            outlined
            onClick={previousStep}
            disabled={uploading || loading}
            fullWidth
            slim
          />
          <Button
            label="Submit"
            disabled={
              uploading ||
              loading ||
              !values.invoices.length ||
              !values.invoiceFiles.length ||
              !isValid ||
              !dirty
            }
            loader={loading ? "right" : undefined}
            type="submit"
            fullWidth
            slim
          />
        </div>
      </div>
    </form>
  );
};

export default DrawdownForm;
