import { ReactNode, useEffect, useMemo, useState } from "react";
import { Circle } from "rc-progress";
import { Button, Card, Modal, ProgressBar } from "../../components";
import {
  LoanApplication,
  useLoanDashboard,
  useSDKContext,
} from "../../contexts";
import { formatAmount } from "../../utils";
import { LoanApplicationStatus } from "../../hooks/api/types";
import {
  ButtonLoaderIcon,
  EmptyApplicationIcon,
  PenIcon,
  PlusIcon,
} from "../../assets/icons";
import { useGetActiveLoanApplication, useLoansSummary } from "../../hooks";
import LoanCard from "./LoanCard";
import { usePostHog } from "posthog-js/react";
import { useDpConfig } from "../../contexts/dpConfig";
import { getStepperStage, useStepper } from "../LoanApplicationSteps/Stepper";
import { datadogRum } from "@datadog/browser-rum";

type LoanDashboardFormCardPros = {
  label?: string;
  children?: ReactNode;
};

export const LoanDashboardFormCard: React.FC<LoanDashboardFormCardPros> = ({
  label,
  children,
}) => (
  <div className="cx-grow">
    <div className="cx-px-4">
      <div className="cx-grow cx-text-text-secondary cx-font-medium cx-text-base cx-text-center">
        {label}
      </div>
      {children}
    </div>
  </div>
);

type LoanDashboardProgressProps = {
  value: number;
  size?: "small" | "large";
};

export const LoanDashboardProgress: React.FC<LoanDashboardProgressProps> = ({
  value,
  size = "small",
}) => (
  <div
    className={`cx-grow cx-flex cx-justify-center ${
      size === "small" ? "cx-max-w-[220px]" : "cx-max-w-[300px]"
    }]`}
  >
    <div
      className={`cx-px-4 cx-overflow-clip cx-relative ${
        size === "small" ? "cx-w-[170px]" : "cx-w-[270px]"
      } ${size === "small" ? "cx-h-[58px]" : "cx-h-[116px]"}`}
    >
      <Circle
        percent={value}
        strokeWidth={size === "small" ? 4 : 6}
        strokeLinecap="round"
        trailWidth={size === "small" ? 4 : 6}
        gapDegree={size === "small" ? 220 : 210}
        strokeColor={{ "0%": "#00D2FF", "100%": "#0064FF" }}
      />
      <div
        className={`cx-flex cx-flex-col cx-items-center cx-justify-center cx-absolute cx-top-0 cx-left-0 cx-right-0 ${
          size === "small" ? "cx-pt-3" : "cx-pt-5"
        }`}
      >
        <div
          className={`cx-grow cx-text-text-primary cx-font-bold cx-text-center ${
            size === "small" ? "cx-text-xl" : "cx-text-5xl"
          }`}
        >
          {`${value}%`}
        </div>
        <div
          className={`cx-grow cx-text-text-secondary cx-text-center ${
            size === "small" ? "cx-font-medium" : "cx-font-bold"
          } ${size === "small" ? "cx-text-sm" : "cx-text-xl"}`}
        >
          Complete
        </div>
      </div>
    </div>
  </div>
);

type Props = {};

const LoanDashboardForm: React.FC<Props> = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [allowNewLoan, setAllowNewLoan] = useState({
    allow: false,
    existing: false,
  });
  const [activeLoanApplication, setActiveLoanApplication] =
    useState<LoanApplication>();
  const [activeLoanContractsSummary, setActiveLoanContractsSummary] = useState({
    totalAmountDisbursed: 0,
    totalOutstandingAmount: 0,
  });
  const {
    state: { loans, loading },
  } = useLoanDashboard();
  const { currentStep, steps, gotoStep, reset } = useStepper();

  const { switchDisplayMode } = useSDKContext();
  const {
    mutateAsync: getActiveLoanApplication,
    isLoading: activeApplicationLoading,
  } = useGetActiveLoanApplication();

  const posthog = usePostHog();

  const { mutateAsync: getLoansSummary, isLoading } = useLoansSummary();

  const {
    state: { uiLocalizationData, settings },
  } = useDpConfig();

  useEffect(() => {
    getActiveLoanApplication().then((data) => {
      setActiveLoanApplication(data);
    });
  }, []);

  const readyForDisplay = useMemo(
    () => !loading && !activeApplicationLoading,
    [loading, activeApplicationLoading]
  );

  useEffect(() => {
    if (
      activeLoanApplication?.id &&
      activeLoanApplication?.stage &&
      readyForDisplay
    ) {
      const [stepName, subStepIndex] = getStepperStage(activeLoanApplication);
      const stepIndex = steps.findIndex((step) => step.name === stepName) || 1;
      gotoStep([stepIndex, subStepIndex]);
    } else if (!activeLoanApplication?.id && readyForDisplay) {
      reset();
    }
  }, [
    activeLoanApplication?.id,
    activeLoanApplication?.stage,
    readyForDisplay,
  ]);

  useEffect(() => {
    getLoansSummary()
      .then((res) => {
        setAllowNewLoan({
          existing: res?.flags?.continueExistingLoanApplication,
          allow: res?.flags?.allowNewLoanApplication,
        });
        setActiveLoanContractsSummary({
          totalAmountDisbursed: res?.totalAmountDisbursed,
          totalOutstandingAmount: res?.totalOutstandingAmount,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleLoanApplicationFlow = () => {
    // The user clicks on the "Continue loan application" button
    // or, the user clicks on "Apply for a new loan" button
    posthog.reset();
    datadogRum.clearUser();
    posthog.capture(
      `User clicked on button ${
        dashboardCardOptions.buttonTitle ?? "undefined?"
      } from dashboard`
    );
    datadogRum.addAction(
      `User clicked on button ${
        dashboardCardOptions.buttonTitle ?? "undefined?"
      } from dashboard`
    );
    switchDisplayMode("loan-application");
  };

  let dashboardCardOptions: {
    buttonTitle?: string;
    icon?: ReactNode;
    onClick?: () => void;
  } = {};
  // if there is an existing loan
  if (allowNewLoan?.existing) {
    dashboardCardOptions = {
      buttonTitle: "Continue loan application",
      icon: <PenIcon className="cx-mr-2" />,
      onClick: handleLoanApplicationFlow,
    };
  }
  // no existing loan, but allow new loan is true
  else if (allowNewLoan?.allow) {
    dashboardCardOptions = {
      buttonTitle: "Apply for a new loan",
      icon: <PlusIcon className="cx-mr-2" />,
      onClick: handleLoanApplicationFlow,
    };
  }

  const activeLoansCount = useMemo<number | null>(() => {
    return (
      loans?.filter((l) => l?.status === LoanApplicationStatus.ACTIVE)
        ?.length ?? 0
    );
  }, [loans]);

  return (
    <div className="cx-grow cx-flex cx-flex-col cx-items-center cx-background-radial-gradient cx-w-full cx-p-8">
      {loading || isLoading ? (
        <div className="cx-animate-spin cx-text-brand-primary-regular cx-w-10 cx-h-10">
          <ButtonLoaderIcon width="100%" height="100%" />
        </div>
      ) : (
        <>
          <Card title="Summary" {...dashboardCardOptions}>
            <div className="sm:cx-flex cx-flex-wrap cx-items-center cx-py-4 cx-px-2 sm:cx-divide-x sm:cx-divide-neutral-light">
              <LoanDashboardFormCard label="Active loans">
                <div className="cx-grow cx-text-text-primary cx-font-bold cx-text-3xl cx-text-center">
                  {activeLoansCount}
                </div>
              </LoanDashboardFormCard>
              <LoanDashboardFormCard
                label={
                  uiLocalizationData.loanDashboard.approvedTotalAmountLabel
                }
              >
                <div className="cx-grow cx-flex cx-items-baseline cx-justify-center">
                  <div className="cx-text-text-primary cx-font-medium cx-text-xl cx-mr-1">
                    AED
                  </div>
                  <div className="cx-text-text-primary cx-font-bold cx-text-3xl">
                    {formatAmount(
                      activeLoanContractsSummary.totalAmountDisbursed
                    )}
                  </div>
                </div>
              </LoanDashboardFormCard>
              <LoanDashboardFormCard label="Total outstanding">
                <div className="cx-grow cx-flex cx-items-baseline cx-justify-center">
                  <div className="cx-text-text-primary cx-font-medium cx-text-xl cx-mr-1">
                    AED
                  </div>
                  <div className="cx-text-text-primary cx-font-bold cx-text-3xl">
                    {formatAmount(
                      activeLoanContractsSummary.totalOutstandingAmount
                    )}
                  </div>
                </div>
              </LoanDashboardFormCard>
              {/* <LoanDashboardFormCard label="Total repayment amount">
                <div className="cx-grow cx-flex cx-items-baseline cx-justify-center">
                  <div className="cx-text-text-primary cx-font-medium cx-text-xl cx-mr-1">
                    AED
                  </div>
                  <div className="cx-text-text-primary cx-font-bold cx-text-3xl">
                    {formatAmount(totalRepayment)}
                  </div>
                </div>
              </LoanDashboardFormCard> */}
            </div>
          </Card>

          {activeLoanApplication && (
            <Card title={"Loan application in progress"}>
              <div className="cx-w-full sm:cx-flex cx-flex-row-reverse cx-px-6 cx-pb-4 cx-border-b-0.5 cx-border-neutral-light">
                <div className="cx-w-full sm:cx-flex cx-flex-row cx-items-center cx-justify-between cx-gap-x-2">
                  <div className={"cx-flex cx-items-center"}>
                    <div
                      className={
                        "cx-text-text-primary cx-font-semibold cx-text-lg cx-mr-4"
                      }
                    >
                      Application in progress
                    </div>
                    <div
                      className={
                        "cx-text-text-secondary cx-font-medium cx-text-base cx-mr-4"
                      }
                    >
                      You are almost there!
                    </div>
                    <div
                      className={
                        "cx-text-base cx-text-text-success cx-font-semibold"
                      }
                    >
                      {currentStep[0]}/{steps.length - 1} steps completed!
                    </div>
                  </div>
                  <div className="cx-text-text-secondary cx-font-medium cx-text-sm">
                    {`Loan #${activeLoanApplication?.externalId}`}
                  </div>
                </div>
              </div>
              <div>
                <ProgressBar currentStep={currentStep} steps={steps} />
              </div>
              <div
                className={
                  "cx-flex cx-flex-col cx-content-center cx-items-center cx-pt-8 cx-pb-2 cx-gap-5"
                }
              >
                <EmptyApplicationIcon />
                <div
                  className={
                    "cx-font-normal cx-text-text-secondary cx-text-center"
                  }
                >
                  You have not submitted your loan application yet. Once an
                  application is <br />
                  processed and approved, all loan information will be displayed
                  here.
                </div>
              </div>
            </Card>
          )}
          {loans
            ?.filter((loan) => {
              return loan.status !== LoanApplicationStatus.STARTED;
            })
            .map((loan, index) => (
              <LoanCard key={`loan-${index}`} loan={loan} index={index} />
            ))}
          <Modal
            className="cx-w-full"
            title="Early settlement"
            isOpen={isOpenModal}
            onClose={() => setIsOpenModal(false)}
          >
            <div>
              <div className="cx-w-full cx-text-text-secondary cx-text-base">
                Account Name
              </div>
              <div className="cx-w-full cx-text-text-secondary cx-text-base">
                IBAN
              </div>
              <div className="cx-w-full cx-text-text-secondary cx-text-base">
                Account Number
              </div>
              <div className="cx-w-full cx-text-text-secondary cx-text-base">
                Bank Name
              </div>
              <div className="cx-w-full cx-text-text-secondary cx-text-base">
                Bank Address
              </div>
            </div>
            <div className="cx-py-6 cx-w-full cx-text-text-secondary cx-text-base">
              Terms and conditions goes herw
            </div>
            <div className="cx-flex cx-flex-col cx-gap-y-4">
              <Button slim label="Done" onClick={() => setIsOpenModal(false)} />
              <Button
                slim
                outlined
                label="Cancel"
                onClick={() => setIsOpenModal(false)}
              />
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

export default LoanDashboardForm;
