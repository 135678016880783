import { useMemo, useState } from "react";
import { Divider, StepNextButton } from "../../../components";
import { useLoanApplication } from "../../../contexts";
import ShareholderCard from "./ShareholderCard";
import AddShareholder from "./AddShareholder";
import {
  CompanyShareholderFields,
  IndividualShareholderFields,
  ShareholderFields,
} from "./service";
import {
  CompanyShareholderType,
  LoanApplicationStage,
  ShareholderEntityType,
} from "../../../hooks/api/types";
import { useStepper } from "../Stepper";
import {
  useDeleteCompanyShareholder,
  useDeleteIndividualShareholder,
} from "../../../hooks";
import { useLoanProductConfigFlags } from "../../../hooks/useLoanProductConfigFlags";

const ShareholdersInformation = () => {
  const [formMode, setFormMode] = useState(false);
  const [currentShareholder, setCurrentShareholder] =
    useState<ShareholderFields | null>(null);
  const {
    state,
    actions: { update, refetch },
  } = useLoanApplication();
  const { enableVendorScreen } = useLoanProductConfigFlags(state.loanType);

  const { nextStep, gotoStep } = useStepper();

  const { mutateAsync: deleteIndividualShareholder } =
    useDeleteIndividualShareholder();

  const { mutateAsync: deleteCompanyShareholder } =
    useDeleteCompanyShareholder();

  const { shouldHideFinancialInformation } = useLoanProductConfigFlags(
    state.loanType
  );

  const shouldHaveOnePOA = useMemo(
    () =>
      state.individualShareholders.some((shareholder) => shareholder.hasPOA),
    [state.individualShareholders]
  );

  const handleAddIndividualShareholder = (
    values: IndividualShareholderFields
  ) => {
    setFormMode(false);
  };

  const handleAddCompanyShareholder = (values: CompanyShareholderFields) => {
    setFormMode(false);
  };

  const handleUpdateIndividualShareholder = (
    values: IndividualShareholderFields
  ) => {
    update(
      {
        individualShareholders: state.individualShareholders.map(
          (shareholder) => {
            if (shareholder.id === values.id) {
              return values;
            }
            return shareholder;
          }
        ),
      },
      { local: false }
    );
  };

  const handleUpdateCompanyShareholder = (values: CompanyShareholderFields) => {
    update(
      {
        companyShareholders: state.companyShareholders.map((shareholder) => {
          if (shareholder.id === values.id) {
            return values;
          }
          return shareholder;
        }),
      },
      { local: true }
    );
  };

  const handleDeleteIndividualShareholder = async (id: string) => {
    if (id == "") {
      return;
    }
    await deleteIndividualShareholder(id);
    refetch();
  };

  const handleDeleteCompanyShareholder = async (id: string) => {
    if (id == "") {
      return;
    }
    await deleteCompanyShareholder(id);
    refetch();
  };

  return formMode ? (
    <AddShareholder
      onSubmit={(values) => {
        if (values.__entityType === ShareholderEntityType.INDIVIDUAL) {
          if (currentShareholder) {
            handleUpdateIndividualShareholder(values);
          } else {
            handleAddIndividualShareholder(values);
          }
        } else {
          if (currentShareholder) {
            handleUpdateCompanyShareholder(values);
          } else {
            handleAddCompanyShareholder(values);
          }
        }
        setCurrentShareholder(null);
        setFormMode(false);
      }}
      {...(currentShareholder ? { shareholder: currentShareholder } : {})}
      onCancel={() => {
        setCurrentShareholder(null);
        setFormMode(false);
      }}
    />
  ) : (
    <div className="cx-flex cx-flex-col cx-items-center">
      <div className="cx-text-text-primary cx-font-bold cx-text-3xl cx-text-center">
        Shareholders information
      </div>
      <div className="cx-font-semibold cx-text-text-primary cx-text-base cx-mb-10 cx-mt-6">
        Please list any additional shareholders who own 25% or more of{" "}
        {state.businessName}
      </div>
      <div className="cx-flex cx-justify-center">
        <div className="cx-grid cx-grid-cols-1 sm:cx-grid-cols-2 lg:cx-grid-cols-3 cx-gap-6 cx-items-stretch cx-justify-items-center">
          {state.individualShareholders.map((shareholder, idx) => (
            <ShareholderCard
              key={shareholder.email}
              mode="edit"
              name={`${shareholder.firstName} ${shareholder.lastName}`}
              onClick={() => {
                setCurrentShareholder({
                  id: shareholder.id,
                  __entityType: ShareholderEntityType.INDIVIDUAL,
                  firstName: shareholder.firstName,
                  lastName: shareholder.lastName,
                  countryCode: shareholder.countryCode,
                  localNumber: shareholder.localNumber,
                  email: shareholder.email,
                  isStakeholder: shareholder.isStakeholder,
                  hasPOA: shareholder.hasPOA,
                  type: shareholder.type ?? CompanyShareholderType.SHAREHOLDER,
                });
                setFormMode(true);
              }}
              {...(shareholder.type === CompanyShareholderType.SHAREHOLDER && {
                onDelete: () => {
                  handleDeleteIndividualShareholder(shareholder.id ?? "");
                },
              })}
              isPrimaryApplicant={
                shareholder.type === CompanyShareholderType.APPLICANT
              }
              email={shareholder.email}
              isPOAHolder={shareholder.hasPOA}
              isShareholder={shareholder.isStakeholder}
            />
          ))}
          {state.companyShareholders.map((shareholder, idx) => (
            <ShareholderCard
              key={shareholder.email}
              mode="edit"
              name={shareholder.name}
              email={shareholder.email}
              onDelete={() => {
                handleDeleteCompanyShareholder(shareholder.id ?? "");
              }}
              onClick={() => {
                setCurrentShareholder({
                  id: shareholder.id,
                  __entityType: ShareholderEntityType.COMPANY,
                  name: shareholder.name,
                  countryCode: shareholder.countryCode,
                  localNumber: shareholder.localNumber,
                  email: shareholder.email,
                  hasPOA: shareholder.hasPOA,
                  isStakeholder: shareholder.isStakeholder,
                });
                setFormMode(true);
              }}
              isCompanyShareholder={true}
              isPOAHolder={shareholder.hasPOA}
              isShareholder={shareholder.isStakeholder}
            />
          ))}
          <ShareholderCard
            mode="add"
            name="Add another shareholder"
            onClick={() => {
              setFormMode(true);
            }}
            isCompanyShareholder={true}
          />
        </div>
      </div>

      <div className="cx-max-w-[400px] cx-w-full">
        {!state.updating && (
          <div>
            <div className="cx-my-10">
              <Divider />
            </div>
            {!shouldHaveOnePOA && (
              <div className="cx-text-xs cx-text-text-error">
                Please add at least one shareholder with the Power of Attorney
              </div>
            )}
          </div>
        )}
        <div className="cx-mt-4">
          <StepNextButton
            loading={state.updating}
            disabled={state.updating || !shouldHaveOnePOA}
            onClick={async () => {
              await update({
                individualShareholders: state.individualShareholders.filter(
                  ({ type }) => type === CompanyShareholderType.SHAREHOLDER
                ),
                companyShareholders: state.companyShareholders,
              });
              if (state.applicant.hasPOA || state.applicant.isStakeholder) {
                await update({
                  stage: LoanApplicationStage.KYC,
                });
                nextStep();
              } else {
                await update({
                  stage: shouldHideFinancialInformation
                    ? LoanApplicationStage.DOCUMENTS_UPLOAD
                    : LoanApplicationStage.FINANCIAL_INFORMATION,
                });
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ShareholdersInformation;
