import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { MicroFlowContextProvider, SDKContextProvider } from "./contexts";
import { RouterProvider } from "react-router-dom";
import router from "./routes";
import { PostHogProvider } from "posthog-js/react";
import { datadogRum } from "@datadog/browser-rum";

const queryClient = new QueryClient();

type Props = {};

datadogRum.init({
  applicationId: import.meta.env.VITE_DATADOG_RUM_APPLICATION_ID,
  clientToken: import.meta.env.VITE_DATADOG_RUM_CLIENT_TOKEN,
  site: "datadoghq.com",
  service: import.meta.env.VITE_DATADOG_RUM_SERVICE_NAME,
  env: import.meta.env.VITE_DATADOG_RUM_ENV,
  version: import.meta.env.VITE_ENV_VERSION || "0.0.0",
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
  allowedTracingUrls: [/https:\/\/.*\.crediblex\.io/],
});

const NON_MASKED_INPUT_FIELDS = ["loanAmount", "duration"];

const App: React.FC<Props> = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <SDKContextProvider>
        <PostHogProvider
          apiKey={import.meta.env.VITE_POSTHOG_KEY}
          options={{
            api_host: import.meta.env.VITE_POSTHOG_HOST,
            persistence: "sessionStorage",
            autocapture: false,
            session_recording: {
              maskAllInputs: true,
              maskInputFn: (text, elem) => {
                if (!elem) return "*".repeat(text.length);

                if (
                  NON_MASKED_INPUT_FIELDS.includes(elem.getAttribute("name")!)
                ) {
                  return text;
                }

                return "*".repeat(text.length);
              },
            },
          }}
        >
          <MicroFlowContextProvider>
            <RouterProvider router={router} />
          </MicroFlowContextProvider>
        </PostHogProvider>
      </SDKContextProvider>
    </QueryClientProvider>
  );
};

export default App;
