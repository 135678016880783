import Lottie from "react-lottie";
import { BulletIcon, CardList, StepNextButton } from "../../../components";
import { SuccessPartyLottie } from "../../../assets/lottie";
import { SuccessTickIcon } from "../../../assets/icons";
import { CardVariantType } from "../../../components/CardList/CardList";
import {
  useLoanApplication,
  useLoanDashboard,
  useSDKContext,
} from "../../../contexts";
import { usePostHog } from "posthog-js/react";
import { datadogRum } from "@datadog/browser-rum";
import { useStepper } from "../../LoanApplicationSteps/Stepper";

const DrawdownSuccess = () => {
  const { switchDisplayMode } = useSDKContext();
  const posthog = usePostHog();
  const { reset } = useStepper();
  const {
    actions: { reset: applicationReset },
  } = useLoanApplication();
  const {
    actions: { update },
    state: { currentLoanApplication },
  } = useLoanDashboard();

  const poaShareholder =
    currentLoanApplication?.company?.individualShareholders?.find(
      (shareholder) => shareholder.hasPOA
    );

  const thankYouSteps = [
    {
      title: "Approve drawdown request",
      description: (
        <>
          Email sent to
          <span className="cx-text-black">
            {" "}
            {poaShareholder?.firstName + " " + poaShareholder?.lastName}{" "}
          </span>
          (POA holder) to approve the drawdown request
        </>
      ),
    },
    {
      title: "Review",
      description: "CredibleX team to review the drawdown request",
    },
    {
      title: "Disbursal",
      description:
        "After approving the drawdown request, funds will be disbursed",
    },
  ];

  return (
    <div className="cx-flex cx-flex-col cx-items-center">
      <div className="cx-w-20 cx-h-20 cx-mb-4 cx-relative cx-text-text-brand">
        <div className="cx-absolute cx--top-16 cx--left-20 cx-w-60 cx--z-10">
          <Lottie
            height="100%"
            width="100%"
            options={{
              animationData: SuccessPartyLottie,
              autoplay: true,
            }}
          />
        </div>
        <div className="cx-bg-gradient-to-r cx-from-[#00D2FF] cx-to-[#0064FF] cx-rounded-full cx-inline-block p-2">
          <SuccessTickIcon className="cx-text-transparent cx-bg-clip-text" />
        </div>
      </div>

      <div className="cx-text-text-primary cx-font-bold cx-text-3xl cx-text-center cx-mb-2">
        Thank you!
      </div>

      <div className="cx-text-text-secondary cx-text-center cx-mb-10">
        <p>Your request has been submitted successfully. We will get </p>
        <p>
          back to you soon.{" "}
          <span className="cx-text-base cx-font-medium cx-text-black">
            What are the next steps?
          </span>
        </p>
      </div>

      <div className="cx-max-w-[400px]">
        <div className="cx-flex cx-flex-col cx-w-full">
          {thankYouSteps.map((step, index) => (
            <div key={index} className="cx-mb-6">
              <CardList
                title={step.title}
                description={step.description}
                variant={CardVariantType.SIMPLE}
                icon={<BulletIcon text={String(index + 1)} />}
              />
            </div>
          ))}

          <div className="cx-text-text-secondary cx-text-sm cx-mb-10 cx-text-center">
            Your loan will be paid to you when each step is successfully
            completed.
          </div>

          <StepNextButton
            label="Go to dashboard"
            onClick={() => {
              posthog.reset(true);
              datadogRum.clearUser();
              reset();
              applicationReset();
              switchDisplayMode("dashboard");
              update({
                currentLoanApplicationId: currentLoanApplication?.id,
                showDrawdownSuccess: false,
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DrawdownSuccess;
