import { useEffect, useState } from "react";
import { MediaPurpose, MediaType } from "../../../hooks/api/types";
import { FileObject } from "../BusinessInformation/service";
import { UploadDocumentItem } from "./UploadDocumentItem";
import { Button, Modal } from "../../../components";
import UploadMultipleDocs from "../BusinessInformation/UploadMultipleDocs";
import { useLoanApplication } from "../../../contexts/loanApplication";
import { useDeleteMedia, useMedia } from "../../../hooks";
import { CustomFile } from "../FinancialInformation/service";

const businessDocuments = [
  {
    title: "Trade license",
    description: "Please upload your trade license",
    type: MediaPurpose.TRADE_LICENSE,
  },
  {
    title: "Memorandum of association",
    description: "Please upload your MOA",
    type: MediaPurpose.MEMORANDUM_OF_ASSOCIATION,
  },
  {
    title: "Power of attorney",
    description: "Please upload your power of attorney",
    type: MediaPurpose.POWER_OF_ATTORNEY,
    isOptional: true,
  },
];

export const BusinessDocuments = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeDocument, setActiveDocument] = useState<MediaPurpose | null>(
    null
  );

  const {
    state,
    actions: { update },
  } = useLoanApplication();
  const { upload, get } = useMedia();
  const { mutateAsync: deleteMediaById } = useDeleteMedia();

  const [uploading, setUploading] = useState(false);
  const [tradeLicenseDoc, setTradeLicenseDoc] = useState<FileObject[]>([]);
  const [moaDocs, setMoaDocs] = useState<FileObject[]>([]);
  const [poaDocs, setPoaDocs] = useState<FileObject[]>([]);

  const handleFileClose = async (
    fileName: string,
    docState: FileObject[],
    setDocState: React.Dispatch<React.SetStateAction<FileObject[]>>
  ) => {
    const removedDoc = docState.find(
      (fileObj) => fileObj.file.name === fileName
    );

    if (!removedDoc) {
      return;
    }

    if (removedDoc.id) {
      await deleteMediaById(removedDoc.id);
    }
    const updatedList = docState.filter(
      (fileObj) => fileObj.file.name !== fileName
    );
    setDocState(updatedList);
  };

  const handleDocumentClick = (documentType: MediaPurpose) => {
    setActiveDocument(documentType);
    setIsOpen(true);
  };

  const resetDocumentsLocalState = () => {
    setTradeLicenseDoc((prev) => prev.filter((doc) => doc.id));
    setMoaDocs((prev) => prev.filter((doc) => doc.id));
    setPoaDocs((prev) => prev.filter((doc) => doc.id));
  };

  const getModalTitle = (documentType: MediaPurpose | null) => {
    if (!documentType) {
      return "";
    }
    return businessDocuments.find((doc) => doc.type === documentType)?.title;
  };

  const updateMediaLocalState = (purpose: MediaPurpose, id: string) => {
    const updateMap: Partial<
      Record<MediaPurpose, { [key: string]: string[] }>
    > = {
      [MediaPurpose.TRADE_LICENSE]: {
        docTradeLicenses: [...state.docTradeLicenses, id],
      },
      [MediaPurpose.MEMORANDUM_OF_ASSOCIATION]: {
        docMOAs: [...state.docMOAs, id],
      },
      [MediaPurpose.POWER_OF_ATTORNEY]: {
        docPOAs: [...state.docPOAs, id],
      },
    };

    const updateData = updateMap[purpose];
    if (updateData) {
      update(updateData, { local: true });
    }
  };

  const handleSubmit = async (modalPurpose: MediaPurpose | null) => {
    if (!modalPurpose) {
      return;
    }
    setUploading(true);

    const handleUploadsForPurpose = async (
      docs: FileObject[],
      purpose: MediaPurpose
    ) => {
      const uploadPromises = docs.map(async (doc) => {
        if (doc.uploaded) return doc.id;

        const id = await upload({
          file: doc.file,
          type: MediaType.DOCUMENT,
          purpose,
          loanApplicationId: state.id,
        });

        doc.id = id;

        updateMediaLocalState(purpose, id);

        return id;
      });

      return Promise.all(uploadPromises);
    };

    const uploadPromises = [];
    if (modalPurpose === MediaPurpose.TRADE_LICENSE) {
      uploadPromises.push(
        handleUploadsForPurpose(tradeLicenseDoc, MediaPurpose.TRADE_LICENSE)
      );
    } else if (modalPurpose === MediaPurpose.MEMORANDUM_OF_ASSOCIATION) {
      uploadPromises.push(
        handleUploadsForPurpose(moaDocs, MediaPurpose.MEMORANDUM_OF_ASSOCIATION)
      );
    } else if (modalPurpose === MediaPurpose.POWER_OF_ATTORNEY) {
      uploadPromises.push(
        handleUploadsForPurpose(poaDocs, MediaPurpose.POWER_OF_ATTORNEY)
      );
    }

    await Promise.all([...uploadPromises]);

    setUploading(false);
    closeModal();
  };

  const getMediaFromDocumentIds = (
    documentIds: string[],
    setDocs: React.Dispatch<React.SetStateAction<FileObject[]>>
  ) => {
    const retreivedDocs: FileObject[] = [];

    documentIds.forEach((documentId) => {
      get({ id: documentId })
        .then((res) => {
          const file = new CustomFile(
            new File([], res.media.fileName),
            res.media.id
          );
          Object.defineProperty(file, "size", {
            value: res.media.size,
          });
          retreivedDocs.push({ id: res.media.id, file, uploaded: true });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setDocs(retreivedDocs);
        });
    });
  };

  useEffect(() => {
    if (state && state.docTradeLicenses.length > 0) {
      getMediaFromDocumentIds(state.docTradeLicenses, setTradeLicenseDoc);
    }

    if (state && state.docMOAs.length > 0) {
      getMediaFromDocumentIds(state.docMOAs, setMoaDocs);
    }

    if (state && state.docPOAs.length > 0) {
      getMediaFromDocumentIds(state.docPOAs, setPoaDocs);
    }
  }, []);

  const closeModal = () => {
    setIsOpen(false);
    setActiveDocument(null);
    setUploading(false);
    resetDocumentsLocalState();
  };

  const getUploadedDocumentsCountByPurpose = (purpose: MediaPurpose) => {
    if (purpose === MediaPurpose.TRADE_LICENSE) {
      return tradeLicenseDoc.length;
    } else if (purpose === MediaPurpose.MEMORANDUM_OF_ASSOCIATION) {
      return moaDocs.length;
    } else if (purpose === MediaPurpose.POWER_OF_ATTORNEY) {
      return poaDocs.length;
    }
    return 0;
  };

  return (
    <div>
      <div className="cx-text-text-primary cx-font-medium cx-text-lg cx-mb-4">
        Business documents
      </div>
      <div className="cx-grid cx-gap-4 cx-grid-cols-1 md:cx-grid-cols-2 cx-w-full">
        {businessDocuments.map((document, index) => (
          <div
            key={document.title}
            className={`${
              index === businessDocuments.length - 1 &&
              businessDocuments.length % 2 !== 0
                ? "md:cx-col-span-2"
                : ""
            }`}
          >
            <UploadDocumentItem
              title={document.title}
              description={document.description}
              isOptional={document?.isOptional}
              onClick={() => handleDocumentClick(document.type)}
              uploadedDocsCount={getUploadedDocumentsCountByPurpose(
                document.type
              )}
            />
          </div>
        ))}
      </div>
      <Modal
        isOpen={isOpen}
        onClose={closeModal}
        title={`Upload ${getModalTitle(activeDocument)?.toLowerCase()}`}
        className="cx-max-w-[520px]"
      >
        {activeDocument === MediaPurpose.TRADE_LICENSE && (
          <UploadMultipleDocs
            type={MediaPurpose.TRADE_LICENSE}
            label="Trade License"
            setDocuments={setTradeLicenseDoc}
            documents={tradeLicenseDoc}
            handleFileClose={(fileName) =>
              handleFileClose(fileName, tradeLicenseDoc, setTradeLicenseDoc)
            }
            uploadedDocs={state.docTradeLicenses}
          ></UploadMultipleDocs>
        )}
        {activeDocument === MediaPurpose.MEMORANDUM_OF_ASSOCIATION && (
          <UploadMultipleDocs
            type={MediaPurpose.MEMORANDUM_OF_ASSOCIATION}
            label="Memorandum of Association"
            setDocuments={setMoaDocs}
            documents={moaDocs}
            handleFileClose={(fileName) =>
              handleFileClose(fileName, moaDocs, setMoaDocs)
            }
            uploadedDocs={state.docMOAs}
          ></UploadMultipleDocs>
        )}
        {activeDocument === MediaPurpose.POWER_OF_ATTORNEY && (
          <UploadMultipleDocs
            type={MediaPurpose.POWER_OF_ATTORNEY}
            label="Power of Attorney"
            setDocuments={setPoaDocs}
            documents={poaDocs}
            handleFileClose={(fileName) =>
              handleFileClose(fileName, poaDocs, setPoaDocs)
            }
            uploadedDocs={state.docPOAs}
          ></UploadMultipleDocs>
        )}
        <div className="cx-mt-6">
          <Button
            label={"Submit"}
            type={"submit"}
            disabled={uploading}
            fullWidth
            onClick={() => {
              setUploading(true);
              handleSubmit(activeDocument);
            }}
          />
          <Button label={"Cancel"} fullWidth secondary onClick={closeModal} />
        </div>
      </Modal>
    </div>
  );
};
