import { useMemo } from "react";
import { useDpConfig } from "../contexts/dpConfig";
import {
  LoanProductConfigFlag,
  LoanProductConfigFlags,
} from "../contexts/dpConfig/types";
import { LoanProductType } from "./api/types";

const resolveFlag = (
  flag: LoanProductConfigFlag | undefined,
  loanAmount?: number
): boolean => {
  if (typeof flag === "boolean") {
    return flag; // Direct boolean values remain unchanged
  }

  if (!flag || typeof flag !== "object") {
    return false; // Default to false if undefined or not an object
  }

  if (
    "minLoanAmount" in flag &&
    typeof flag.minLoanAmount === "number" &&
    loanAmount !== undefined
  ) {
    return loanAmount <= flag.minLoanAmount;
  }

  return false;
};

type ResolvedLoanProductConfigFlags = Omit<
  LoanProductConfigFlags,
  | "disableSalesStatementsUpload"
  | "disableBankStatementsUpload"
  | "disableVATStatementsUpload"
> & {
  disableSalesStatementsUpload: boolean;
  disableBankStatementsUpload: boolean;
  disableVATStatementsUpload: boolean;
};

export const useLoanProductConfigFlags = (
  loanType?: LoanProductType,
  loanAmount?: number // New param to determine conditional flags
): ResolvedLoanProductConfigFlags & {
  shouldHideFinancialInformation: boolean | undefined;
} => {
  const {
    state: { products },
  } = useDpConfig();

  const loanProductConfig = useMemo(() => {
    console.log("Fetching loan product config for loan type:", loanType);
    const productConfig =
      products.find((product) => product.type === loanType)?.config ??
      products[0].config;

    return productConfig;
  }, [loanType, products]);

  const {
    directDebitRequired,
    disableInvoiceUpload,
    disableSalesInvoiceUpload,
    hideInterestPaymentOnOffer,
    disableSalesStatementsUpload,
    disableBankStatementsUpload,
    disableVATStatementsUpload,
    disableRevenueHistory,
    isPartnerTermsAccepted,
    disbursementAccountProvided,
    showMerchantIdStep,
    enableVendorScreen,
  } = loanProductConfig || {};

  const shouldHideFinancialInformation =
    !disbursementAccountProvided && !showMerchantIdStep;
  let resolvedDisableSalesStatementsUpload: boolean = false;
  let resolvedDisableBankStatementsUpload: boolean = false;
  let resolveDisableVATStatementsUpload: boolean = false;
  if (loanAmount) {
    resolvedDisableSalesStatementsUpload = resolveFlag(
      disableSalesStatementsUpload,
      loanAmount
    );

    resolvedDisableBankStatementsUpload = resolveFlag(
      disableBankStatementsUpload,
      loanAmount
    );

    resolveDisableVATStatementsUpload = resolveFlag(
      disableVATStatementsUpload,
      loanAmount
    );
  }

  return {
    directDebitRequired,
    disableInvoiceUpload,
    disableSalesInvoiceUpload,
    hideInterestPaymentOnOffer,
    disableSalesStatementsUpload: resolvedDisableSalesStatementsUpload,
    disableBankStatementsUpload: resolvedDisableBankStatementsUpload,
    disableVATStatementsUpload: resolveDisableVATStatementsUpload,
    disableRevenueHistory,
    isPartnerTermsAccepted,
    disbursementAccountProvided,
    shouldHideFinancialInformation,
    showMerchantIdStep,
    enableVendorScreen,
  };
};
