import clsx from "clsx";
import { useId, ReactNode } from "react";

type Props = {
  label: string | ReactNode;
  checked?: boolean;
  error?: string;
  labelClassnames?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  variant?: "regular" | "slim" | "rounded";
};

const Checkbox = ({
  label,
  checked,
  error,
  onChange,
  labelClassnames = "",
  inputProps = {},
  variant = "regular",
}: Props) => {
  const id = useId();

  return (
    <>
      <div className="cx-flex cx-flex-row cx-items-center">
        <input
          id={id}
          value=""
          checked={checked}
          type="checkbox"
          onChange={onChange}
          className={clsx(
            "cx-appearance-none cx-peer focus:cx-ring-transparent cx-border-2 hover:cx-cursor-pointer",
            {
              "cx-min-w-[16px] cx-w-4 cx-h-4 cx-rounded-checkbox":
                variant === "slim",
              "cx-min-w-[24px] cx-w-6 cx-h-6 cx-rounded-checkbox":
                variant === "regular",
              "cx-min-w-[16px] cx-w-5 cx-h-5 cx-rounded-full":
                variant === "rounded",
              "checked:cx-bg-tick checked:cx-bg-cover checked:cx-bg-brand-primary-regular checked:cx-border-brand-primary-regular":
                !error,
              "cx-text-text-error cx-border-error-regular": error, // Add checked:bg-error-regular checked:border-error-regular if red checkbox is needed on error
              "cx-text-text-brand cx-border-stroke-primary": !error,
            }
          )}
          {...inputProps}
        />
        {variant !== "rounded" && label && (
          <label
            htmlFor={id}
            className={clsx(
              "cx-ml-2 peer-checked:cx-text-text-primary cx-text-text-secondary",
              labelClassnames
            )}
          >
            {label}
          </label>
        )}
      </div>
      {error && (
        <div className="cx-text-xs cx-py-1 cx-text-text-error">{error}</div>
      )}
    </>
  );
};

export default Checkbox;
