import { DeepPartial } from "../../types";
import { IndividualShareholder, SignModeType, OTPType } from "../../contexts";
import { Invoice } from "../../views/LoanCalculator/service";

type WithId<T extends Record<string, unknown>> = {
  id: string;
} & T;

export type APIError = {
  message: string;
  error?: string;
};

export enum LoanProductType {
  FIXED_TERM = "FIXED_TERM",
  INVOICE_DISCOUNTING = "INVOICE_DISCOUNTING",
  LOC_INVOICE_DISCOUNTING = "LOC_INVOICE_DISCOUNTING",
  ACCOUNT_RECEIVABLES = "ACCOUNT_RECEIVABLES",
  PAYABLE_FINANCING = "PAYABLE_FINANCING",
  LOC_PAYABLE_FINANCING = "LOC_PAYABLE_FINANCING",
  REVENUE_BASED_FINANCING = "REVENUE_BASED_FINANCING",
  POS_REVENUE_BASED_FINANCING = "POS_REVENUE_BASED_FINANCING",
  BNPL = "BNPL",
}

export enum LoanProductReason {
  PAY_SUPPLIER = "PAY_SUPPLIER",
  PAY_UNEXPECTED_BILL = "PAY_UNEXPECTED_BILL",
  COVER_CASH_FLOW_GAP = "COVER_CASH_FLOW_GAP",
  COVER_OPERATIONAL_EXPENSE = "COVER_OPERATIONAL_EXPENSE",
  BUY_NEW_EQUIPMENT = "BUY_NEW_EQUIPMENT",
  BUY_NEW_INVENTORY = "BUY_NEW_INVENTORY",
  GROW_BUSINESS = "GROW_BUSINESS",
  EXPAND_NEW_LOCATION = "EXPAND_NEW_LOCATION",
  NEED_WORKING_CAPITAL = "NEED_WORKING_CAPITAL",
  RECRUIT_STAFF = "RECRUIT_STAFF",
  UNPAID_INVOICES = "UNPAID_INVOICES",
}

export enum LoanDurationType {
  DAYS = "days",
  MONTHS = "months",
  WEEKS = "weeks",
  YEARS = "years",
}

export enum LoanApplicationStage {
  BRANDING = "BRANDING",
  CALCULATOR = "CALCULATOR",
  ONBOARDING = "ONBOARDING",
  BUSINESS_INFORMATION = "BUSINESS_INFORMATION",
  KYB = "KYB",
  COMPANY_INFORMATION = "COMPANY_INFORMATION",
  DOCUMENTS_UPLOAD = "DOCUMENTS_UPLOAD",
  OWNER_INFORMATION = "OWNER_INFORMATION",
  SHAREHOLDER_INFORMATION = "SHAREHOLDER_INFORMATION",
  KYC = "KYC",
  FINANCIAL_INFORMATION = "FINANCIAL_INFORMATION",
  BANK_STATEMENTS = "BANK_STATEMENTS",
  VAT_STATEMENTS = "VAT_STATEMENTS",
  GENERIC_DOCUMENTS = "GENERIC_DOCUMENTS",
  MORE_INFORMATION = "MORE_INFORMATION",
  MANUAL_ACCOUNTS = "MANUAL_ACCOUNTS",
  VENDOR_INFORMATION = "VENDOR_INFORMATION",
  REVIEW = "REVIEW",
  SUCCESS = "SUCCESS",
}

export enum LoanApplicationStatus {
  STARTED = "STARTED",
  SUBMITTED = "SUBMITTED",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  CANCELLED = "CANCELLED",
  PREAPPROVED = "PREAPPROVED",
  ADDITIONAL_INFO_REQUESTED = "ADDITIONAL_INFO_REQUESTED",
  WITHDRAW = "WITHDRAW",
  ON_HOLD = "ON_HOLD",
  ACTIVE = "ACTIVE",
  REVIEW_OFFER = "REVIEW_OFFER",
  COUNTER_OFFER_SENT = "COUNTER_OFFER_SENT",
  MISSING_INFORMATION = "MISSING_INFORMATION",
  APPROVED_WAITING_FOR_OFFER = "APPROVED_WAITING_FOR_OFFER",
  OFFER_ACCEPTED = "OFFER_ACCEPTED",
  OFFER_REJECTED = "OFFER_REJECTED",
  CONTRACT_PENDING_SIGNATURE = "CONTRACT_PENDING_SIGNATURE",
  CONTRACT_SIGNED = "CONTRACT_SIGNED",
  WAITING_FOR_DISBURSEMENT = "WAITING_FOR_DISBURSEMENT",
  REFER = "REFER",
  MLRO_REVIEW = "MLRO_REVIEW",
  UNDERWRITER_REJECTED = "UNDERWRITER_REJECTED",
  SYSTEM_REJECTED = "SYSTEM_REJECTED",
  INPRINCIPAL_APPROVAL = "INPRINCIPAL_APPROVAL",
  INREVIEW_MLRO = "INREVIEW_MLRO",
  DECLINED_MLRO = "DECLINED_MLRO",
  INREVIEW_OPS = "INREVIEW_OPS",
  INREVIEW_UW = "INREVIEW_UW",
  INREVIEW_SUW = "INREVIEW_SUW",
  INREVIEW_CRO = "INREVIEW_CRO",
  SME_REJECTED = "SME_REJECTED",
  DECLINED_SYSTEM = "DECLINED_SYSTEM",
  DECLINE_MLRO = "DECLINE_MLRO",
  DECLINED_UW = "DECLINED_UW",
  DECLINED_SUW = "DECLINED_SUW",
  APPROVED_SUW = "APPROVED_SUW",
  DECLINED_CRO = "DECLINED_CRO",
  APPROVED_CRO = "APPROVED_CRO",
  DECLINED_SME = "DECLINED_SME",
  CREDIT_MODIFICATION_LOAN_OPS = "CREDIT_MODIFICATION_LOAN_OPS",
  CREDIT_MODIFICATION_UW = "CREDIT_MODIFICATION_UW",
  WAITING_FOR_DP_REVIEW = "WAITING_FOR_DP_REVIEW",
  CLOSED = "CLOSED",
  FORECLOSED = "FORECLOSED",
  EXPIRED = "EXPIRED",
}

export const LoanApplicationStatusDashboardMappingToText: Record<
  LoanApplicationStatus,
  string
> = {
  [LoanApplicationStatus.STARTED]: "Requested Amount",
  [LoanApplicationStatus.SUBMITTED]: "Requested Amount",
  [LoanApplicationStatus.APPROVED]: "Approved Amount",
  [LoanApplicationStatus.REJECTED]: "Requested Amount",
  [LoanApplicationStatus.CANCELLED]: "Requested Amount",
  [LoanApplicationStatus.PREAPPROVED]: "Approved Amount",
  [LoanApplicationStatus.ADDITIONAL_INFO_REQUESTED]: "Requested Amount",
  [LoanApplicationStatus.WITHDRAW]: "Requested Amount",
  [LoanApplicationStatus.ON_HOLD]: "Requested Amount",
  [LoanApplicationStatus.ACTIVE]: "Disbursed Amount",
  [LoanApplicationStatus.REVIEW_OFFER]: "Approved Amount",
  [LoanApplicationStatus.COUNTER_OFFER_SENT]: "Approved Amount",
  [LoanApplicationStatus.MISSING_INFORMATION]: "Requested Amount",
  [LoanApplicationStatus.APPROVED_WAITING_FOR_OFFER]: "Approved Amount",
  [LoanApplicationStatus.OFFER_ACCEPTED]: "Approved Amount",
  [LoanApplicationStatus.OFFER_REJECTED]: "Requested Amount",
  [LoanApplicationStatus.CONTRACT_PENDING_SIGNATURE]: "Approved Amount",
  [LoanApplicationStatus.CONTRACT_SIGNED]: "Approved Amount",
  [LoanApplicationStatus.WAITING_FOR_DISBURSEMENT]: "Disbursed Amount",
  [LoanApplicationStatus.REFER]: "Requested Amount",
  [LoanApplicationStatus.MLRO_REVIEW]: "Requested Amount",
  [LoanApplicationStatus.UNDERWRITER_REJECTED]: "Requested Amount",
  [LoanApplicationStatus.SYSTEM_REJECTED]: "Requested Amount",
  [LoanApplicationStatus.INPRINCIPAL_APPROVAL]: "Requested Amount",
  [LoanApplicationStatus.INREVIEW_MLRO]: "Requested Amount",
  [LoanApplicationStatus.DECLINED_MLRO]: "Requested Amount",
  [LoanApplicationStatus.INREVIEW_OPS]: "Requested Amount",
  [LoanApplicationStatus.INREVIEW_UW]: "Requested Amount",
  [LoanApplicationStatus.INREVIEW_SUW]: "Requested Amount",
  [LoanApplicationStatus.INREVIEW_CRO]: "Requested Amount",
  [LoanApplicationStatus.SME_REJECTED]: "Requested Amount",
  [LoanApplicationStatus.DECLINED_SYSTEM]: "Requested Amount",
  [LoanApplicationStatus.DECLINE_MLRO]: "Requested Amount",
  [LoanApplicationStatus.DECLINED_UW]: "Requested Amount",
  [LoanApplicationStatus.DECLINED_SUW]: "Requested Amount",
  [LoanApplicationStatus.APPROVED_SUW]: "Requested Amount",
  [LoanApplicationStatus.DECLINED_CRO]: "Requested Amount",
  [LoanApplicationStatus.APPROVED_CRO]: "Requested Amount",
  [LoanApplicationStatus.DECLINED_SME]: "Approved Amount",
  [LoanApplicationStatus.CREDIT_MODIFICATION_LOAN_OPS]: "Requested Amount",
  [LoanApplicationStatus.CREDIT_MODIFICATION_UW]: "Requested Amount",
  [LoanApplicationStatus.WAITING_FOR_DP_REVIEW]: "Requested Amount",
  [LoanApplicationStatus.CLOSED]: "Disbursed Amount",
  [LoanApplicationStatus.FORECLOSED]: "Disbursed Amount",
  [LoanApplicationStatus.EXPIRED]: "Requested Amount",
};

export enum MediaType {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
  DOCUMENT = "DOCUMENT",
}

export enum MediaPurpose {
  TRADE_LICENSE = "TRADE_LICENSE",
  MEMORANDUM_OF_ASSOCIATION = "MEMORANDUM_OF_ASSOCIATION",
  POWER_OF_ATTORNEY = "POWER_OF_ATTORNEY",
  VAT_STATEMENT = "VAT_STATEMENT",
  EMIRATES_ID_FRONT = "EMIRATES_ID_FRONT",
  EMIRATES_ID_BACK = "EMIRATES_ID_BACK",
  FACE_ID = "FACE_ID",
  BANK_STATEMENT = "BANK_STATEMENT",
  BANK_ACCOUNT_CONNECTION = "BANK_ACCOUNT_CONNECTION",
  SIGNATURE = "SIGNATURE",
  SIGNATURE_STROKE = "SIGNATURE_STROKE",
  DIRECT_DEBIT_SIGNED_FORM = "DIRECT_DEBIT_SIGNED_FORM",
  INVOICE = "INVOICE",
  SALES_STATEMENT = "SALES_STATEMENT",
  POS_SALES_STATEMENT = "POS_SALES_STATEMENT",
  GENERIC_DOCUMENT = "GENERIC_DOCUMENT",
  SALES_INVOICE = "SALES_INVOICE",
  VENDOR_TRADE_LICENSE = "VENDOR_TRADE_LICENSE",
  VENDOR_INVOICE = "VENDOR_INVOICE",
  VENDOR_VAT_STATEMENT = "VENDOR_VAT_STATEMENT",
  VENDOR_IBAN_LETTER = "VENDOR_IBAN_LETTER",
  VENDOR_ADDITIONAL_DOCUMENT = "VENDOR_ADDITIONAL_DOCUMENT",
}

export enum OpenBankingStatus {
  INITIATED = "INITIATED",
  IN_PROGRESS = "IN_PROGRESS",
  SUCCESSFUL = "SUCCESSFUL",
  FAILED = "FAILED",
  CANCELLED = "CANCELLED",
  RECONNECT_REQUIRED = "RECONNECT_REQUIRED",
  RESULTS_PENDING = "RESULTS_PENDING",
}

export enum ShareholderEntityType {
  INDIVIDUAL = "INDIVIDUAL",
  COMPANY = "COMPANY",
}

export enum CompanyShareholderType {
  APPLICANT = "APPLICANT",
  SHAREHOLDER = "SHAREHOLDER",
}

export enum ShareholderKYCApplicationStatus {
  PENDING = "PENDING",
  INITIATED = "INITIATED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
}

export enum LoanOfferStage {
  REVIEW = "REVIEW",
  SETUP_DIRECT_DEBIT = "SETUP_DIRECT_DEBIT",
  SELECT_BANK_ACCOUNT = "SELECT_BANK_ACCOUNT",
  ADD_BANK_ACCOUNT = "ADD_BANK_ACCOUNT",
  SELECT_SIGN_MODE = "SELECT_SIGN_MODE",
  COMPLETED = "COMPLETED",
  DECLINED = "DECLINED",
}

export enum DirectDebitBankAccountSourceType {
  LEANTECH_CONNECTED = "LEANTECH_CONNECTED",
  PDF_BANK_STATEMENT = "PDF_BANK_STATEMENT",
  MANUAL = "MANUAL",
}

export enum OpenbankingApplicationStage {
  INITIATED = "INITIATED",
  WAITING_FOR_WEBHOOK = "WAITING_FOR_WEBHOOK",
  FETCH_ACCOUNTS = "FETCH_ACCOUNTS",
  FETCH_TRANSACTIONS = "FETCH_TRANSACTIONS",
  FETCH_BALANCES = "FETCH_BALANCES",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
}

export enum AdditionalInfoType {
  SHAREHOLDERS_CONTACTS = "SHAREHOLDERS_CONTACTS", //info about shareholders
  DOC_TLN = "DOC_TLN", // Trade License Document
  DOC_MOA = "DOC_MOA", // Memorandum of Association Document
  DOC_POA = "DOC_POA", // Power of Attorney Document
  DOC_VAT = "DOC_VAT", // VAT Document
  DOC_BANK = "DOC_BANK", // Bank Statements Document
  DOC_OTHER = "DOC_OTHER", // Any Other Document
  DOC_SALES_STATEMENT = "DOC_SALES_STATEMENT", // Sales Statement Document
  DOC_POS_SALES_STATEMENT = "DOC_POS_SALES_STATEMENT", // POS Sales Statement Document
  SHAREHOLDERS_AECB = "SHAREHOLDERS_AECB", // Shareholders AECB
  DOC_SALES_INVOICE = "DOC_SALES_INVOICE", // Sales Invoice Document
}

export type AdditionalInfo = {
  loanApplicationId: string;
  correlationId: string;
  type: AdditionalInfoType;
  hint?: string;
  userId: number;
  shareholder?: IndividualShareholder;
};

export type CreateLoanApplicationRequest = {
  user: Partial<{
    name: string;
    email?: string;
    countryCode: string;
    localNumber: string;
    phoneNumber: string;
    otpCode: string;
    otpType: OTPType;
  }>;
  loanType?: LoanProductType;
  reason: string;
  refId?: string;
};

export type Company = {
  id: string;
  name: string;
  businessEmail: string;
  licenseNumber: string;
  annualRevenue: number;
  taxRegistrationNumber: string;
  estabilishedYear: string;
  type: string;
  officeEmirate: string;
  officeArea: string;
  officeStreet: string;
  officeBuilding: string;
  officeFloor: string;
  documents: {
    tradeLicense: string;
    memorandumOfAssociation: string;
    powerOfAttorney: string;
    q1VatStatement: string;
    q2VatStatement: string;
    q3VatStatement: string;
    q4VatStatement: string;
  };
  individualShareholders?: {
    firstName: string;
    lastName: string;
    hasPOA?: boolean;
  }[];
};

export type UpdateLoanApplicationRequest = { id: string } & DeepPartial<{
  stage: LoanApplicationStage;
  company: {
    id: string;
    name: string;
    businessEmail: string;
    licenseNumber: string;
    annualRevenue: number;
    taxRegistrationNumber: string;
    estabilishedYear: string;
    type: string;
    officeEmirate: string;
    officeArea: string;
    officeStreet: string;
    officeBuilding: string;
    officeFloor: string;
    documents: {
      tradeLicense: string;
      memorandumOfAssociation: string;
      powerOfAttorney: string;
      q1VatStatement: string;
      q2VatStatement: string;
      q3VatStatement: string;
      q4VatStatement: string;
    };
  };
  loanInput: {
    type: LoanProductType;
    amount: number;
    duration: number;
  };
  user: {
    name: string;
    email: string;
    phoneNumber: string;
  };
  additionalDPMetadata: Record<string, string> | null;
}>;

export type UploadMediaRequest = {
  name: string;
  type: MediaType;
  purpose: MediaPurpose;
  contentType: string;
  size: number;
  loanApplicationId: string;
};

export type FinalizeMediaRequest = {
  mediaId: string;
};

export type UploadMedia = {
  url: string;
  file: File;
};

export type InitiateOpenBankingRequest = {
  loanApplicationId: string;
};

export type UpdateOpenBankingStageRequest = {
  loanApplicationId: string;
  stage: OpenbankingApplicationStage;
};

export type GetOpenBankingStatusRequest = {
  loanApplicationId: string;
};

export type SendOTPRequest = {
  countryCode?: string;
  localNumber?: string;
};

export type SendOTPByLoanApplicationIdRequest = {
  loanApplicationId: string;
};

export type SendEmailOTPRequest = {
  email: string;
};

export type VerifyOTPRequest = {
  otpCode: string;
};

export type DeclineLoanOfferRequest = {
  id: string;
  reason: string;
};

export type ApproveLoanOfferRequest = {
  id: string;
  shareholderSignMode?: SignModeType;
};

export type UpdateLoanOfferStageRequest = {
  id: string;
  stage: LoanOfferStage;
};

export type SaveDirectDebitBankAccountRequest = {
  loanOfferId: string;
  accountId: string;
  accountName: string;
  bankName: string;
  iban: string;
  sourceType: DirectDebitBankAccountSourceType;
};

export type GetSignatureStrokeRequest = {
  mediaId: string;
  shareholderId: string;
};

export type GetExistingShareholderSignatureSignelUrlRequest = {
  loanApplicationId: string;
  shareholderId: string;
};

export type GetMediaResponse = {
  media: {
    id: string;
    path: string;
    purpose: MediaPurpose;
    type: MediaType;
    thumbnailPath: string;
    fileName: string;
    metdata: {
      userId?: string;
      loanApplicationId?: string;
    };
  };
  url: string;
  thumbnailUrl: string;
};

export type VerifyOriginRequest = {
  origin: string;
};

export type VerifyOriginResponse = {
  allowed: boolean;
};

export type GetUserResponse = {
  countryCode: string;
  email: string;
  localNumber: string;
  name: string;
  id: string;
  distributionPartner: {
    id: string;
    name: string;
  };
};

export type UpdateIndividualShareholderRequest = WithId<
  Partial<{
    firstName: string;
    lastName: string;
    email: string;
    countryCode: string;
    localNumber: string;
    isStakeholder: boolean;
    hasPOA: boolean;
  }>
>;

export type UpdateCompanyShareholderRequest = WithId<
  Partial<{
    name: string;
    email: string;
    countryCode: string;
    localNumber: string;
  }>
>;

export type InitiateCopyRequest = {
  loanApplicationId: string; // loanAppId TO which we need to copy
  refId: string; // loanAppId FROM which we ween to copy data
};

export type ValidateLoanApplicationUniquenessRequest = {
  tradeLicenseNumber: string;
  licensingAuthority: string;
  loanId: string; // already created loan application Id
};

export type SaveMastercardMerchantIdsRequest = {
  merchantIds: string[];
  loanApplicationId: string;
};

export type DeleteMastercardMerchantIdsRequest = {
  merchantIds: string[];
  loanApplicationId: string;
};

export type UpdateMastercardMerchantIdsRequest = {
  merchantId: string;
  loanApplicationId: string;
  merchantDataId: string;
};

export type UpdateAdditionalInfo = WithId<{
  infoType: AdditionalInfoType;
  userId?: number;
  values?: string[];
}>;

export enum PaymentTransactionsSortColumns {
  DATE = "DATE",
  REMITTER = "REMITTER",
  TRANSACTION_ID = "TRANSACTION_ID",
  AMOUNT = "AMOUNT",
}

export enum SortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export interface UsePaymentDetailsProps {
  loanApplicationId: string;
  paymentId: string;
  search?: string;
  sortColumn?: PaymentTransactionsSortColumns;
  sortOrder?: SortOrder;
  limit?: number;
}

type StatusLabels<T extends {}> = {
  [k in keyof T]: {
    label: string;
    value: string;
    displayClasses: string;
  };
};
export const LoanApplicationStatusLabels: StatusLabels<
  typeof LoanApplicationStatus
> = {
  STARTED: {
    label: "Started",
    value: LoanApplicationStatus.STARTED,
    displayClasses: "cx-text-text-secondary cx-bg-background-secondary",
  },
  SUBMITTED: {
    label: "Submitted",
    value: LoanApplicationStatus.SUBMITTED,
    displayClasses: "cx-bg-highlight-lighter cx-text-text-highlight",
  },
  WAITING_FOR_DP_REVIEW: {
    label: "Submitted",
    value: LoanApplicationStatus.WAITING_FOR_DP_REVIEW,
    displayClasses: "cx-bg-highlight-lighter cx-text-text-highlight",
  },

  REJECTED: {
    label: "Rejected",
    value: LoanApplicationStatus.REJECTED,
    displayClasses: "cx-text-text-error cx-bg-error-lighter",
  },
  CANCELLED: {
    label: "Cancelled",
    value: LoanApplicationStatus.CANCELLED,
    displayClasses: "cx-text-text-error cx-bg-error-lighter",
  },
  PREAPPROVED: {
    label: "In Review",
    value: LoanApplicationStatus.PREAPPROVED,
    displayClasses: "cx-bg-highlight-lighter cx-text-text-highlight",
  },
  ADDITIONAL_INFO_REQUESTED: {
    label: "Additional Info Requested",
    value: LoanApplicationStatus.ADDITIONAL_INFO_REQUESTED,
    displayClasses: "cx-bg-error-dark cx-text-white",
  },
  WITHDRAW: {
    label: "Withdrawn",
    value: LoanApplicationStatus.WITHDRAW,
    displayClasses: "cx-text-text-secondary cx-bg-background-secondary",
  },
  ON_HOLD: {
    label: "On Hold",
    value: LoanApplicationStatus.ON_HOLD,
    displayClasses: "cx-text-text-secondary cx-bg-background-secondary",
  },
  ACTIVE: {
    label: "Active",
    value: LoanApplicationStatus.ACTIVE,
    displayClasses: "cx-text-text-success cx-bg-success-lighter",
  },
  REVIEW_OFFER: {
    label: "Review Offer",
    value: LoanApplicationStatus.REVIEW_OFFER,
    displayClasses: "cx-text-text-success cx-bg-success-lighter",
  },
  MISSING_INFORMATION: {
    label: "In Review",
    value: LoanApplicationStatus.MISSING_INFORMATION,
    displayClasses: "cx-bg-highlight-lighter cx-text-text-highlight",
  },
  APPROVED_WAITING_FOR_OFFER: {
    label: "In Review",
    value: LoanApplicationStatus.APPROVED_WAITING_FOR_OFFER,
    displayClasses: "cx-bg-highlight-lighter cx-text-text-highlight",
  },
  OFFER_ACCEPTED: {
    label: "Offer accepted",
    value: LoanApplicationStatus.OFFER_ACCEPTED,
    displayClasses: "cx-text-text-success cx-bg-success-lighter",
  },
  OFFER_REJECTED: {
    label: "Offer Rejected",
    value: LoanApplicationStatus.OFFER_REJECTED,
    displayClasses: "cx-text-text-error cx-bg-error-lighter",
  },
  COUNTER_OFFER_SENT: {
    label: "Counter Offer Sent",
    value: LoanApplicationStatus.COUNTER_OFFER_SENT,
    displayClasses: "cx-text-text-success cx-bg-success-lighter",
  },
  CONTRACT_PENDING_SIGNATURE: {
    label: "Contract Pending Signature",
    value: LoanApplicationStatus.CONTRACT_PENDING_SIGNATURE,
    displayClasses: "cx-text-text-success cx-bg-success-lighter",
  },
  CONTRACT_SIGNED: {
    label: "Contract Signed",
    value: LoanApplicationStatus.CONTRACT_SIGNED,
    displayClasses: "cx-text-text-success cx-bg-success-lighter",
  },
  WAITING_FOR_DISBURSEMENT: {
    label: "Waiting for Disbursement",
    value: LoanApplicationStatus.WAITING_FOR_DISBURSEMENT,
    displayClasses: "cx-text-text-success cx-bg-success-lighter",
  },
  REFER: {
    label: "In Review",
    value: LoanApplicationStatus.REFER,
    displayClasses: "cx-text-text-success cx-bg-success-lighter",
  },
  MLRO_REVIEW: {
    label: "In Review",
    value: LoanApplicationStatus.MLRO_REVIEW,
    displayClasses: "cx-text-text-success cx-bg-success-lighter",
  },
  UNDERWRITER_REJECTED: {
    label: "Rejected",
    value: LoanApplicationStatus.UNDERWRITER_REJECTED,
    displayClasses: "cx-text-text-error cx-bg-error-lighter",
  },
  SYSTEM_REJECTED: {
    label: "Rejected",
    value: LoanApplicationStatus.SYSTEM_REJECTED,
    displayClasses: "cx-text-text-error cx-bg-error-lighter",
  },
  INPRINCIPAL_APPROVAL: {
    label: "In Principal Approval",
    value: LoanApplicationStatus.INPRINCIPAL_APPROVAL,
    displayClasses: "cx-text-text-success cx-bg-success-lighter",
  },
  INREVIEW_MLRO: {
    label: "In Review",
    value: LoanApplicationStatus.INREVIEW_MLRO,
    displayClasses: "cx-text-text-success cx-bg-success-lighter",
  },
  DECLINED_MLRO: {
    label: "In Review",
    value: LoanApplicationStatus.DECLINED_MLRO,
    displayClasses: "cx-text-text-success cx-bg-success-lighter",
  },
  INREVIEW_OPS: {
    label: "In Review",
    value: LoanApplicationStatus.INREVIEW_OPS,
    displayClasses: "cx-text-text-success cx-bg-success-lighter",
  },
  INREVIEW_UW: {
    label: "In Review",
    value: LoanApplicationStatus.INREVIEW_UW,
    displayClasses: "cx-text-text-success cx-bg-success-lighter",
  },
  INREVIEW_SUW: {
    label: "In Review",
    value: LoanApplicationStatus.INREVIEW_SUW,
    displayClasses: "cx-text-text-success cx-bg-success-lighter",
  },
  INREVIEW_CRO: {
    label: "In Review",
    value: LoanApplicationStatus.INREVIEW_CRO,
    displayClasses: "cx-text-text-success cx-bg-success-lighter",
  },
  SME_REJECTED: {
    label: "Offer Rejected",
    value: LoanApplicationStatus.SME_REJECTED,
    displayClasses: "cx-text-text-error cx-bg-error-lighter",
  },
  DECLINED_SYSTEM: {
    label: "Rejected",
    value: LoanApplicationStatus.DECLINED_SYSTEM,
    displayClasses: "cx-text-text-error cx-bg-error-lighter",
  },
  DECLINE_MLRO: {
    label: "In Review",
    value: LoanApplicationStatus.DECLINE_MLRO,
    displayClasses: "cx-text-text-success cx-bg-success-lighter",
  },
  DECLINED_UW: {
    label: "In Review",
    value: LoanApplicationStatus.DECLINED_UW,
    displayClasses: "cx-text-text-success cx-bg-success-lighter",
  },
  DECLINED_SUW: {
    label: "In Review",
    value: LoanApplicationStatus.DECLINED_SUW,
    displayClasses: "cx-text-text-success cx-bg-success-lighter",
  },
  APPROVED_SUW: {
    label: "In Review",
    value: LoanApplicationStatus.APPROVED_SUW,
    displayClasses: "cx-text-text-success cx-bg-success-lighter",
  },
  DECLINED_CRO: {
    label: "In Review",
    value: LoanApplicationStatus.DECLINED_CRO,
    displayClasses: "cx-text-text-success cx-bg-success-lighter",
  },
  APPROVED_CRO: {
    label: "In Review",
    value: LoanApplicationStatus.APPROVED_CRO,
    displayClasses: "cx-text-text-success cx-bg-success-lighter",
  },
  DECLINED_SME: {
    label: "Offer Rejected",
    value: LoanApplicationStatus.DECLINED_SME,
    displayClasses: "cx-text-text-error cx-bg-error-lighter",
  },
  CREDIT_MODIFICATION_LOAN_OPS: {
    label: "In Review",
    value: LoanApplicationStatus.CREDIT_MODIFICATION_LOAN_OPS,
    displayClasses: "cx-text-text-success cx-bg-success-lighter",
  },
  CREDIT_MODIFICATION_UW: {
    label: "In Review",
    value: LoanApplicationStatus.CREDIT_MODIFICATION_UW,
    displayClasses: "cx-text-text-success cx-bg-success-lighter",
  },
  APPROVED: {
    label: "In Review",
    value: LoanApplicationStatus.APPROVED,
    displayClasses: "cx-text-text-success cx-bg-success-lighter",
  },
  CLOSED: {
    label: "Closed",
    value: LoanApplicationStatus.CLOSED,
    displayClasses: "cx-text-text-secondary cx-bg-background-secondary",
  },
  FORECLOSED: {
    label: "Foreclosed",
    value: LoanApplicationStatus.FORECLOSED,
    displayClasses: "cx-text-text-secondary cx-bg-background-secondary",
  },
  EXPIRED: {
    label: "Expired",
    value: LoanApplicationStatus.EXPIRED,
    displayClasses: "cx-text-text-secondary cx-bg-background-secondary",
  },
};

export const LOAN_PRODUCT_DISPLAY_NAME: Record<LoanProductType, string> = {
  FIXED_TERM: "Advance Cashflow",
  INVOICE_DISCOUNTING: "Receivable Finance",
  LOC_INVOICE_DISCOUNTING: "Line of credit",
  ACCOUNT_RECEIVABLES: "Account Receivables",
  PAYABLE_FINANCING: "Payable Finance",
  LOC_PAYABLE_FINANCING: "Line of credit",
  REVENUE_BASED_FINANCING: "Revenue Based Finance",
  BNPL: "Buy Now Pay Later",
  POS_REVENUE_BASED_FINANCING: "POS Revenue Based Finance",
} as const;

export type MonthlyAmounts = {
  paidAmount: number;
  exceededAmount: number;
  outstandingAmount: number;
  installmentAmount: number;
  loanContractScheduleIds: string[];
  installmentDates: string[];
  tag: string;
};

export type PaymentHistoryDTO = {
  [key: string]: MonthlyAmounts;
};

export type PaymentHistoryResponseDTO = {
  history: PaymentHistoryDTO;
};

export enum LoanContractLoanFrequency {
  DAYS = "DAYS",
  MONTHLY = "MONTHLY",
  BIWEEKLY = "BIWEEKLY",
}

export type PaymentSummaryResponseDTO = {
  monthDueAmount: number;
  monthInstallmentAmount: number;
  totalOutstandingAmount: number;
  totalInstallmentAmount: number;
  tenure?: number;
  loanFrequency?: LoanContractLoanFrequency;
};

export type PaymentDetailsResponseDTO = {
  transactionId: string;
  remitter: string;
  amount: number;
  date: string;
};

export type LoanContractScheduleTransactionListDTO = {
  transactions: Array<PaymentDetailsResponseDTO>;
  count: number;
  lastUpdatedAt: string;
};

export type LatestTransactionResponseDTO = {
  date: string;
};

export type ManualAccount = {
  id: string;

  companyId: string;

  name?: string;

  currency?: string;

  bankName?: string;
  iban?: string;
};
export type ManualAccountsResponseDTO = {
  accounts: ManualAccount[];
};

export type LoanApplicationUniquenessResponseDTO = {
  isDuplicateApplication: boolean;
  isPhoneNumberMatching: boolean;
  sourceId: string; // loanId of application found with same trade license and licensing authority
  phoneNumber: string;
};

export type LogoDevBrand = {
  name: string;
  // logo_url?: string;
  // domain: string;
};

export enum LoanVendorType {
  SUPPLIER = "SUPPLIER",
  BUYER = "BUYER",
}

export enum LoanVendorStatus {
  DRAFT = "DRAFT",

  // PENDING_REVIEW is when the LoanVendor has been verified and now is waiting
  // a review by Mission-Control.
  PENDING_REVIEW = "PENDING_REVIEW",

  // PENDING_DOCUMENTS is when the LoanVendor is missing some documents, per review
  // from Mission-Control, and it might be approved if more supporting documents
  // are provided.
  PENDING_DOCUMENTS = "PENDING_DOCUMENTS",

  // ON_HOLD is when Mission-Control decided that they want to put this vendor
  // on hold for furture
  ON_HOLD = "ON_HOLD",

  // APPROVED is when the LoanVendor has been approved by Mission-Control, and that
  // LoanVendor can be considered as an approved SMEVendor.
  APPROVED = "APPROVED",

  // REJECTED is when the LoanVendor has been rejected by Mission-Control, and nothing
  // can be done to reconsider approving it for this loan.
  REJECTED = "REJECTED",
}

export enum LoanVendorDocumentRequestStatus {
  DRAFT = "DRAFT",
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
}

export type CreateVendorRequest = {
  loanApplicationId: string;
  name: string;
  country: string;
  licenseNumber?: string;
  registrationAuthority?: string;
  type: LoanVendorType;
  tradeLicenseDocumentIds?: string[];
  invoicesDocumentIds?: string[];
  vatCertificateDocumentIds?: string[];
  ibanDocumentIds?: string[];
  additionalDocumentIds?: string[];
};

export type GetApplicationVendorsRequest = {
  loanApplicationId: string;
  search?: string;
  page?: number;
  pageSize?: number;
  sortOrder?: SortOrder.DESC;
  status?: LoanVendorStatus;
  type?: LoanVendorType;
};

export interface LoanVendorDocumentDTO {
  documentId: string;
  documentPurpose: MediaPurpose;
  documentUrl: string;
  status: LoanVendorDocumentStatus;
  //incase we get details from loan application details endpoint
  document?: {
    id?: string;
    purpose?: MediaPurpose;
  };
}

export enum LoanVendorManagementAction {
  SUBMIT_DOCUMENTS = "SUBMIT_DOCUMENTS",
  CONTINUE_WITHOUT_PENDING_VENDORS = "CONTINUE_WITHOUT_PENDING_VENDORS",
}

export enum LoanVendorDocumentStatus {
  DRAFT = "DRAFT",
  ACTIVE = "ACTIVE",
}

export enum VendorMediaPurpose {
  VENDOR_TRADE_LICENSE = "VENDOR_TRADE_LICENSE",
  VENDOR_INVOICE = "VENDOR_INVOICE",
  VENDOR_VAT_STATEMENT = "VENDOR_VAT_STATEMENT",
  VENDOR_IBAN_LETTER = "VENDOR_IBAN_LETTER",
  VENDOR_ADDITIONAL_DOCUMENT = "VENDOR_ADDITIONAL_DOCUMENT",
  BANK_STATEMENT = "BANK_STATEMENT",
}

export type VendorMediaType = Extract<
  keyof typeof MediaPurpose,
  keyof typeof VendorMediaPurpose
>;

export enum LoanVendorDocumentRequestReason {
  MISSING_DOCUMENT = "MISSING_DOCUMENT",
  EXPIRED_DOCUMENT = "EXPIRED_DOCUMENT",
  OTHER_REASON = "OTHER_REASON",
}

export interface VendorDocumentRequestDTO {
  id: string;
  type: VendorMediaType;
  loanVendorId: LoanVendorDocumentRequestReason;
  reason: string;
  reasonDescription?: string;
  status: LoanVendorDocumentRequestStatus;
  loanVendorDocuments: LoanVendorDocumentDTO[];
}

export interface Vendor {
  id?: string;
  loanVendorId: string;
  vendorId: string;
  name: string;
  country: string;
  status?: LoanVendorStatus;
  licenseNumber?: string;
  registrationAuthority?: string;
  type: LoanVendorType;
  tradeLicenseDocumentIds?: string[];
  invoicesDocumentIds?: string[];
  vatCertificateDocumentIds?: string[];
  ibanCertificatesDocumentIds?: string[];
  additionalDocumentIds?: string[];
  documents?: LoanVendorDocumentDTO[];
  loanVendorDocumentRequests?: VendorDocumentRequestDTO[];
}

export interface UpdateLoanVendorDocumentRequestDTO {
  documentIds: string[];
  status?: LoanVendorDocumentRequestStatus;
}

export interface UpdateVendorRequest extends CreateVendorRequest {
  loanVendorId: string;
}

export type PaginatedResponse<T> = {
  data: T[];
  meta: {
    page: number;
    pageSize: number;
    skip: number;
    itemsCount: number;
    pageCount: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
};

export type PageMeta = {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  itemsCount: number;
  page: number;
  pageCount: number;
  pageSize: number;
  skip: number;
};

export type TransactionDTO = {
  transactionId: string;
  remitter?: string;
  paidAmount: number;
  timestamp: string;
};

export type TransactionsResponseDTO = {
  data: TransactionDTO[];
  meta: PageMeta;
};

export interface GetAllTransactionsProps {
  page?: number;
  pageSize?: number;
  disablePagination?: boolean;
  loanApplicationId: string;
  filters?: {
    searchText?: string;
    sortColumn?: PaymentTransactionsSortColumns;
    sortOrder?: SortOrder;
    limit?: number;
  };
}

export type GetAllTransactionsExportProps = Omit<
  GetAllTransactionsProps,
  "page" | "pageSize"
>;

export type DrawdownFieldsRequestDTO = {
  invoiceFiles: string[];
  vendorName: string;
  invoices: Invoice[];
  currency: string;
};
