import { useState } from "react";
import { LoanProductType, MediaPurpose } from "../../../hooks/api/types";
import { UploadDocumentItem } from "./UploadDocumentItem";
import { Modal } from "../../../components";
import UploadStatement from "../FinancialInformation/UploadStatement";
import { useLoanApplication } from "../../../contexts/loanApplication";
import VATStatementsUpload from "../FinancialInformation/VATStatementsUpload";
import LinkBankLite from "../FinancialInformation/LinkBankLite";
import { useLoanProductConfigFlags } from "../../../hooks/useLoanProductConfigFlags";
import LoanUploadInvoice from "../../LoanCalculator/UploadInvoice";
import UploadSalesStatement from "../../LoanCalculator/UploadSalesStatement";
import UploadPosSalesStatement from "../../LoanCalculator/UploadPosSalesStatement";
import UploadSalesInvoice from "../../LoanCalculator/UploadSalesInvoice";
import { useLoanMetrics } from "../../../hooks";

const financialDocuments = [
  {
    title: "Bank statements",
    description: "Please upload your bank statements",
    type: MediaPurpose.BANK_STATEMENT,
  },
  {
    title: "Bank account",
    description: "Please connect your bank account",
    isOptional: true,
    isConnected: true,
    isBankConnection: true,
  },
  {
    title: "VAT statements",
    description: "Please upload your VAT statements",
    type: MediaPurpose.VAT_STATEMENT,
  },
  {
    title: "Invoices",
    description: "Please upload your invoices",
    type: MediaPurpose.INVOICE,
  },
  {
    title: "Sales statements",
    description: "Please upload your sales statements",
    type: MediaPurpose.SALES_STATEMENT,
  },
  {
    title: "POS sales statements",
    description: "Please upload your POS sales statements",
    type: MediaPurpose.POS_SALES_STATEMENT,
  },
  {
    title: "Sales invoices",
    description: "Please upload your sales invoices",
    type: MediaPurpose.SALES_INVOICE,
  },
];

export const FinancialDocuments = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeDocument, setActiveDocument] = useState<MediaPurpose | null>(
    null
  );
  const [isBankConnection, setIsBankConnection] = useState(false);
  const {
    state: {
      loanType,
      docBankStatements,
      docVatStatements,
      invoices = [],
      revenueBasedLoan: { salesStatements = [] },
      buyNowPayLaterLoan: { salesInvoices = [] },
      posRevenueBasedLoan: { posSalesStatements = [] },
      leanApplicationId,
    },
  } = useLoanApplication();

  const { loanAmount } = useLoanMetrics();

  const {
    disableBankStatementsUpload,
    disableVATStatementsUpload,
    disableInvoiceUpload,
    disableSalesStatementsUpload,
    disableSalesInvoiceUpload,
  } = useLoanProductConfigFlags(loanType, loanAmount);

  const filteredFinancialDocuments = financialDocuments.filter((doc) => {
    if (disableBankStatementsUpload && doc.type === MediaPurpose.BANK_STATEMENT)
      return false;
    if (disableBankStatementsUpload && doc.isBankConnection) return false;
    if (disableVATStatementsUpload && doc.type === MediaPurpose.VAT_STATEMENT)
      return false;
    if (
      (disableInvoiceUpload ||
        (loanType !== LoanProductType.PAYABLE_FINANCING &&
          loanType !== LoanProductType.INVOICE_DISCOUNTING)) &&
      doc.type === MediaPurpose.INVOICE
    )
      return false;
    if (
      (disableSalesStatementsUpload ||
        loanType !== LoanProductType.REVENUE_BASED_FINANCING) &&
      doc.type === MediaPurpose.SALES_STATEMENT
    )
      return false;
    if (
      loanType !== LoanProductType.POS_REVENUE_BASED_FINANCING &&
      doc.type === MediaPurpose.POS_SALES_STATEMENT
    )
      return false;
    if (
      (disableSalesInvoiceUpload || loanType !== LoanProductType.BNPL) &&
      doc.type === MediaPurpose.SALES_INVOICE
    )
      return false;

    return true;
  });

  const handleDocumentClick = (
    documentType: MediaPurpose | undefined,
    isBankConnection: boolean = false
  ) => {
    if (isBankConnection) {
      setIsBankConnection(true);
      setActiveDocument(null);
      setIsOpen(true);
      return;
    }
    if (!documentType) return;
    setActiveDocument(documentType);
    setIsOpen(true);
  };

  const getModalTitle = (documentType: MediaPurpose | null) => {
    if (!documentType) {
      return "";
    }
    if (documentType === MediaPurpose.SALES_INVOICE) return "";
    const title = financialDocuments
      .find((doc) => doc.type === documentType)
      ?.title?.toLowerCase();
    return title ? `Upload ${title}` : "";
  };

  const closeModal = () => {
    setIsOpen(false);
    setActiveDocument(null);
    setIsBankConnection(false);
  };

  const getUploadedDocumentsCountByPurpose = (
    purpose: MediaPurpose | undefined
  ) => {
    if (!purpose) return 0;
    if (purpose === MediaPurpose.BANK_STATEMENT) {
      return docBankStatements.length;
    }
    if (purpose === MediaPurpose.VAT_STATEMENT) {
      return docVatStatements.length;
    }
    if (purpose === MediaPurpose.SALES_STATEMENT) {
      return salesStatements.length;
    }
    if (purpose === MediaPurpose.SALES_INVOICE) {
      return salesInvoices.length;
    }
    if (purpose === MediaPurpose.POS_SALES_STATEMENT) {
      return posSalesStatements.length;
    }
    if (purpose === MediaPurpose.INVOICE) {
      return invoices.length > 0 && invoices[0].vendorName
        ? invoices.length
        : 0;
    }

    return 0;
  };

  const getModifiedDescription = (
    description: string,
    isBankConnection: boolean = false
  ) => {
    if (isBankConnection && leanApplicationId) {
      return "Leantech connection added";
    }
    return description;
  };

  if (filteredFinancialDocuments.length === 0) {
    return null;
  }

  return (
    <div>
      <div className="cx-text-text-primary cx-font-medium cx-text-lg cx-mb-4">
        Financial documents
      </div>
      <div className="cx-grid cx-gap-4 cx-grid-cols-1 md:cx-grid-cols-2 cx-w-full">
        {filteredFinancialDocuments.map((document, index) => (
          <div
            key={document.title}
            className={`${
              index === filteredFinancialDocuments.length - 1 &&
              filteredFinancialDocuments.length % 2 !== 0
                ? "md:cx-col-span-2"
                : ""
            }`}
          >
            <UploadDocumentItem
              title={document.title}
              description={getModifiedDescription(
                document.description,
                document?.isBankConnection
              )}
              isOptional={document?.isOptional}
              isConnected={document?.isConnected}
              onClick={() =>
                handleDocumentClick(document.type, document?.isBankConnection)
              }
              uploadedDocsCount={getUploadedDocumentsCountByPurpose(
                document.type
              )}
              leantechConection={
                document.isBankConnection && leanApplicationId !== null
              }
              showUploadBtn={
                document.type === MediaPurpose.INVOICE
                  ? !(invoices.length > 0 && invoices[0].vendorName) // only need to check for 0th index because we only support one invoice
                  : undefined
              }
            />
          </div>
        ))}
      </div>
      <Modal
        isOpen={isOpen}
        onClose={closeModal}
        title={isBankConnection ? "" : getModalTitle(activeDocument)}
      >
        {(() => {
          if (isBankConnection) {
            return <LinkBankLite />;
          }
          switch (activeDocument) {
            case MediaPurpose.BANK_STATEMENT:
              return <UploadStatement closeModal={closeModal} />;
            case MediaPurpose.VAT_STATEMENT:
              return <VATStatementsUpload closeModal={closeModal} />;
            case MediaPurpose.INVOICE:
              if (
                loanType === LoanProductType.INVOICE_DISCOUNTING ||
                loanType === LoanProductType.PAYABLE_FINANCING
              ) {
                return <LoanUploadInvoice closeModal={closeModal} />;
              }
              break;
            case MediaPurpose.SALES_STATEMENT:
              if (loanType === LoanProductType.REVENUE_BASED_FINANCING) {
                return <UploadSalesStatement closeModal={closeModal} />;
              }
              break;
            case MediaPurpose.POS_SALES_STATEMENT:
              if (loanType === LoanProductType.POS_REVENUE_BASED_FINANCING) {
                return <UploadPosSalesStatement closeModal={closeModal} />;
              }
              break;
            case MediaPurpose.SALES_INVOICE:
              if (loanType === LoanProductType.BNPL) {
                return <UploadSalesInvoice closeModal={closeModal} />;
              }
              break;
            default:
              return null;
          }
        })()}
      </Modal>
    </div>
  );
};
