import { FC, useRef, useState } from "react";
import {
  ButtonLoaderIcon,
  HeavyCheckIcon,
  RedoKycIcon,
} from "../../assets/icons";
import SumsubWebSdk from "@sumsub/websdk-react";
import { StepNextButton } from "../index";
import { clsx } from "clsx";

export type Props = {
  shareholderId?: string;
  nextHandler: () => void;
  accessToken: string;
  handleGetAccessToken: () => void;
  handleRefetch: () => Promise<void>;
  handleSumsubApplicantUpdate: (type?: any, payload?: any) => void;
};

export enum ScreenVariant {
  IN_PROGRESS = "inProgress",
  PROCESSING = "processing",
  PASSED = "passed",
  FAILED = "failed",
}

export enum SumSubReviewStatus {
  INIT = "init",
  PENDING = "pending",
  PRECHECKED = "prechecked",
  QUEUED = "queued",
  COMPLETED = "completed",
  ON_HOLD = "onHold",
}

export enum SumSubMessageType {
  APPLICANT_LOADED = "idCheck.onApplicantLoaded",
  APPLICANT_STATUS_CHANGED = "idCheck.onApplicantStatusChanged",
  APPLICANT_RESUBMITTED = "idCheck.onApplicantResubmitted",
  APPLICANT_SUBMITTED = "idCheck.onApplicantSubmitted",
  APPLICANT_MODULE_RESULT = "idCheck.moduleResultPresented",
}

const SumSubKycView: FC<Props> = ({
  nextHandler,
  accessToken,
  handleRefetch,
  handleSumsubApplicantUpdate,
  handleGetAccessToken,
  shareholderId,
}) => {
  const [kycApplicantId, setKycApplicantId] = useState<string>();
  const [isNext, setIsNext] = useState<boolean>();
  const [screenVariant, setScreenVariant] = useState<ScreenVariant>();

  const kycRef = useRef<string>();
  kycRef.current = kycApplicantId;

  const onSubmit = async () => {
    if (screenVariant === ScreenVariant.FAILED) {
      setScreenVariant(undefined);
      setIsNext(false);
    } else {
      nextHandler();
    }
  };

  const handleResult = async (type: any, payload: any) => {
    switch (type) {
      case SumSubMessageType.APPLICANT_LOADED: {
        setKycApplicantId(payload.applicantId);
        break;
      }
      case SumSubMessageType.APPLICANT_RESUBMITTED:
      case SumSubMessageType.APPLICANT_SUBMITTED: {
        // handle Applicant Submitted
        handleSumsubApplicantUpdate(type, payload);
        await handleRefetch();
        setIsNext(true);
        break;
      }
      case SumSubMessageType.APPLICANT_STATUS_CHANGED: {
        if (payload.reviewStatus === SumSubReviewStatus.COMPLETED) {
          if (payload.reviewResult) {
            switch (payload?.reviewResult?.reviewAnswer) {
              case "GREEN": {
                setScreenVariant(ScreenVariant.PASSED);
                break;
              }
              case "RED": {
                if (payload.reviewResult.reviewRejectType == "RETRY") {
                  setScreenVariant(ScreenVariant.FAILED);
                }
                break;
              }
              case "YELLOW": {
                setScreenVariant(ScreenVariant.PROCESSING);
                break;
              }
            }
          }
          handleSumsubApplicantUpdate(type, payload);
          await handleRefetch();
          setIsNext(true);
        }
        break;
      }
      case SumSubMessageType.APPLICANT_MODULE_RESULT: {
        setScreenVariant(payload.answer);
        handleSumsubApplicantUpdate(type, payload);
        break;
      }
      default:
        break;
    }
  };

  const getButtonLabel = () => {
    if (screenVariant === ScreenVariant.PASSED) {
      return "Continue";
    }
    if (screenVariant === ScreenVariant.FAILED) {
      return "Retry KYC";
    }
    if (screenVariant === ScreenVariant.PROCESSING) {
      return "Continue";
    }
    return "Next";
  };

  const renderKyc = () => {
    if (accessToken) {
      return (
        <div className="cx-max-w-[960px] cx-min-h-[400px] cx-w-full cx-grid cx-content-center">
          <div
            className={clsx(`cx-flex-col cx-gap-4 cx-items-center cx-mb-8`, {
              "cx-hidden":
                !screenVariant || screenVariant !== ScreenVariant.PASSED,
              "cx-flex":
                screenVariant && screenVariant === ScreenVariant.PASSED,
            })}
          >
            <HeavyCheckIcon />
            <div className={"cx-font-bold cx-text-2xl"}>Congratulations</div>
            <div
              className={
                "cx-text-base cx-text-text-secondary cx-font-normal cx-text-center"
              }
            >
              Congratulations! Your profile has been successfully verified.
              <br /> You can continue with the loan application
            </div>
          </div>
          <div
            className={clsx("cx-flex-col cx-gap-4 cx-items-center cx-mb-8", {
              "cx-hidden":
                !screenVariant || screenVariant !== ScreenVariant.PROCESSING,
              "cx-flex":
                screenVariant && screenVariant === ScreenVariant.PROCESSING,
            })}
          >
            <div className="cx-animate-spin cx-w-20 cx-h-20 cx-text-brand-primary-regular">
              <ButtonLoaderIcon />
            </div>
            <div className={"cx-font-bold cx-text-2xl"}>
              Processing your information
            </div>
            <div
              className={
                "cx-text-base cx-text-text-secondary cx-font-normal cx-text-center"
              }
            >
              Your information is submitted. Please allow us a few moments to
              examine
              <br /> your documents. In the meantime, you can click on Continue
              to complete the
              <br /> loan application. We will notify you in case of any issues
            </div>
          </div>
          <div
            className={clsx("cx-flex-col cx-gap-4 cx-items-center cx-mb-8", {
              "cx-hidden":
                !screenVariant || screenVariant !== ScreenVariant.FAILED,
              "cx-flex":
                screenVariant && screenVariant === ScreenVariant.FAILED,
            })}
          >
            <RedoKycIcon />
            <div className="cx-font-bold cx-text-2xl">Start KYC again</div>
            <div className="cx-text-base cx-text-text-secondary cx-font-normal cx-text-center">
              Your profile could not be verified due to an issue with <br /> the
              provided information. Please complete the KYC process again <br />{" "}
              to complete verification
            </div>
          </div>
          <div
            className={clsx(
              {
                "cx-flex": !screenVariant,
                "cx-hidden": !!screenVariant,
              },
              "cx-flex-col cx-items-center cx-content-center cx-w-full"
            )}
          >
            <SumsubWebSdk
              className={"cx-w-full"}
              key={accessToken}
              accessToken={accessToken}
              config={{
                lang: "en",
                country: "ARE",
              }}
              options={{ addViewportTag: false, adaptIframeHeight: true }}
              expirationHandler={handleGetAccessToken}
              onMessage={handleResult}
              onError={(err: any) => console.log("onError", err)}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="cx-max-w-[960px] cx-min-h-[400px] cx-w-full cx-flex cx-justify-center cx-items-center">
          <div className="cx-animate-spin cx-text-brand-primary-regular cx-w-10 cx-h-10">
            <ButtonLoaderIcon width="100%" height="100%" />
          </div>
        </div>
      );
    }
  };

  return (
    <div
      className={
        "cx-flex cx-flex-col cx-items-center cx-content-center cx-w-full"
      }
    >
      {renderKyc()}
      {(isNext && !shareholderId) ||
      (shareholderId && screenVariant === ScreenVariant.FAILED) ? (
        <div className="cx-w-1/2 cx-justify-self-center cx-max-w-[480px]">
          <StepNextButton label={getButtonLabel()} onClick={onSubmit} />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SumSubKycView;
