import { DocumentIcon } from "../../../assets/icons";
import { screamingSnakeCaseToTitleCase, truncate } from "../../../utils";
import { Button, Modal } from "../../../components";
import React, { FC, useEffect, useState } from "react";
import UploadMultipleDocs from "../../LoanApplicationSteps/BusinessInformation/UploadMultipleDocs";
import {
  LoanVendorDocumentRequestReason,
  MediaPurpose,
  MediaType,
  VendorDocumentRequestDTO,
} from "../../../hooks/api/types";
import { FileObject } from "../../LoanApplicationSteps/BusinessInformation/service";
import {
  useDeleteLoanVendorDocument,
  useMedia,
  useUpdateLoanVendorDocumentRequest,
} from "../../../hooks";
import { CustomFile } from "../../LoanApplicationSteps/FinancialInformation/service";

export type Props = {
  loanApplicationId: string;
  vendorDocumentRequest: VendorDocumentRequestDTO;
  onSuccess?: () => void;
};

const VendorDocumentRequestRow: FC<Props> = ({
  loanApplicationId,
  vendorDocumentRequest,
  onSuccess,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [documents, setDocuments] = useState<Array<FileObject>>([]);
  const [loading, setLoading] = useState(false);

  const { upload, get } = useMedia();
  const { mutateAsync: deleteDocument } = useDeleteLoanVendorDocument();

  const { mutateAsync: updateLoanVendorDocumentRequest, isLoading } =
    useUpdateLoanVendorDocumentRequest();

  const reason =
    vendorDocumentRequest?.reason !==
    LoanVendorDocumentRequestReason.OTHER_REASON
      ? vendorDocumentRequest?.reason
      : vendorDocumentRequest?.reasonDescription || "";

  useEffect(() => {
    const loanVendorDocuments = vendorDocumentRequest?.loanVendorDocuments;
    if (loanVendorDocuments?.length) {
      const docs = loanVendorDocuments.map((loanVendorDoc) => {
        return loanVendorDoc.document?.id;
      }) as string[];
      getMediaFromDocumentIds(docs, setDocuments);
    }
  }, [vendorDocumentRequest?.loanVendorDocuments]);

  const getMediaFromDocumentIds = (
    documentIds: string[],
    setDocs: React.Dispatch<React.SetStateAction<FileObject[]>>
  ) => {
    const retreivedDocs: FileObject[] = [];

    documentIds.forEach((documentId) => {
      get({ id: documentId })
        .then((res) => {
          const file = new CustomFile(
            new File([], res.media.fileName),
            res.media.id
          );
          Object.defineProperty(file, "size", {
            value: res.media.size,
          });
          retreivedDocs.push({ id: res.media.id, file, uploaded: true });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setDocs(retreivedDocs);
        });
    });
  };

  async function handleSubmit() {
    setLoading(true);
    const documentUploadPromises = documents.map(async (doc) => {
      if (doc.uploaded) return doc.id;

      const id = await upload({
        file: doc.file,
        type: MediaType.DOCUMENT,
        purpose: vendorDocumentRequest.type as MediaPurpose,
        loanApplicationId: loanApplicationId,
      });
      doc.id = id;
      return id;
    });

    const ids = await Promise.all(documentUploadPromises);

    await updateLoanVendorDocumentRequest({
      loanApplicationId: loanApplicationId,
      loanVendorId: vendorDocumentRequest.loanVendorId,
      documentRequestId: vendorDocumentRequest.id,
      update: {
        documentIds: ids,
      },
    });
    setIsOpen(false);
    setLoading(false);
    onSuccess?.();
  }

  const handleFileClose = async (
    fileName: string,
    docState: FileObject[],
    setDocState: React.Dispatch<React.SetStateAction<FileObject[]>>
  ) => {
    const removedDoc = docState.find(
      (fileObj) => fileObj.file.name === fileName
    );

    if (!removedDoc) {
      return;
    }

    await deleteDocument({
      loanApplicationId: loanApplicationId,
      loanVendorId: vendorDocumentRequest.loanVendorId,
      documentId: removedDoc.id,
    });
    const updatedList = docState.filter(
      (fileObj) => fileObj.file.name !== fileName
    );
    setDocState(updatedList);
  };

  return (
    <div className={"cx-flex cx-flex-row cx-items-center cx-w-full"}>
      <div className={"cx-flex cx-flex-row cx-items-center cx-w-5/12"}>
        <DocumentIcon className={"cx-w-8 cx-h-8 cx-text-text-brand"} />
        <div
          className={"cx-ml-2 cx-font-normal cx-text-s cx-text-text-primary"}
        >
          {screamingSnakeCaseToTitleCase(vendorDocumentRequest?.type)}
        </div>
      </div>
      <div
        className={"cx-text-text-error cx-w-5/12"}
        title={screamingSnakeCaseToTitleCase(reason)}
      >
        {truncate(screamingSnakeCaseToTitleCase(reason), 48)}
      </div>
      {documents?.length ? (
        <div
          className={
            "cx-text-interaction-button-primary-default cx-font-semibold cx-text-s cx-cursor-pointer"
          }
          onClick={() => {
            setIsOpen(true);
          }}
        >
          View Details
        </div>
      ) : (
        <Button
          label={"Upload"}
          outlined={true}
          slim={true}
          className={"cx-h-8"}
          onClick={() => {
            setIsOpen(true);
          }}
        />
      )}
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        title={`Upload ${screamingSnakeCaseToTitleCase(
          vendorDocumentRequest?.type
        )}`}
        className={"cx-pb-4"}
      >
        <div className={"cx-flex cx-flex-col cx-gap-4"}>
          <UploadMultipleDocs
            label={`${screamingSnakeCaseToTitleCase(
              vendorDocumentRequest?.type
            )}`}
            setDocuments={(docs) => {
              setDocuments(() => [...docs]);
            }}
            documents={documents}
            handleFileClose={(fileName) =>
              handleFileClose(fileName, documents, setDocuments)
            }
            type={vendorDocumentRequest?.type as MediaPurpose}
          />
          <Button
            label={"Update"}
            fullWidth
            slim
            loader={isLoading || loading ? "right" : undefined}
            disabled={isLoading || loading}
            onClick={handleSubmit}
          />
          <Button
            label={"Cancel"}
            fullWidth
            secondary
            onClick={() => {
              setIsOpen(false);
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default VendorDocumentRequestRow;
